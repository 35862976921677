import { useGetFetchDemo } from 'api/hooks/useGetFetchDemo';
import { usePostMutationDemo } from 'api/hooks/usePostMutationDemo';
import { ComponentWrapper } from 'app/features/ComponentWrapper/ComponentWrapper';
import { Button } from 'app/ui';
import React from 'react';

export const DemosFetchData = () => {
	const { data, isLoading } = useGetFetchDemo({
		delay: 1500,
	});

	const { mutate } = usePostMutationDemo();

	return (
		<ComponentWrapper isLoading={isLoading}>
			<h2 className="text-lg pt-4 md:text-xl font-normal mb-4">
				Fetch Demo with ComponentWrapper
			</h2>
			<p className="mb-4">
				ComponentWrapper can be used when when using React Query useQuery
			</p>
			<p className="mb-4">message: {data?.data?.message}</p>
			<Button
				onClick={() =>
					mutate({
						type: 'badType',
					})
				}
				variant="danger"
			>
				Mutation Error Demo
			</Button>{' '}
			<Button
				onClick={() =>
					mutate({
						type: 'valid',
					})
				}
				variant="primary"
			>
				Mutation Success Demo
			</Button>
		</ComponentWrapper>
	);
};

DemosFetchData.displayName = 'DemosFetchData';
