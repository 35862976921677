import React from 'react';
import { Icon, ThemeToggle } from 'ui';
import { useFeature } from 'app/features/FeatureFlags/useFeature';
import { EFeatureFlags } from 'app/features/FeatureFlags/featureFlags';

interface ILayoutHeaderProps {
	title?: string;
	actions?: React.ReactNode;
	icon?: string;
}

const LayoutHeader: React.FC<ILayoutHeaderProps> = ({
	title,
	actions,
	icon,
}) => {
	const { isFeatureEnabled: showThemeToggle } = useFeature(
		EFeatureFlags.EnableDarkMode,
		false,
	);

	const renderPageHeader = () => {
		return (
			<h1 className="text-lg text-primary-badge font-semibold pt-2 pb-4 px-4 rounded-lg flex items-center">
				<Icon name={icon || ''} className="mr-2 fill-primary-badge" />
				{title}
			</h1>
		);
	};

	return (
		<div className="flex justify-between items-center mb-2 px-2">
			{renderPageHeader()}

			<div className="flex items-center space-x-4">
				{showThemeToggle && (
					<div className="md:block">
						<ThemeToggle />
					</div>
				)}
				{actions}
			</div>
		</div>
	);
};

export { LayoutHeader };
