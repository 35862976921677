import React from 'react';
import { Icon } from 'ui';
import { motion } from 'framer-motion';

export const Forbidden = () => {
	return (
		<div className="bg-background flex justify-center items-center lg:items-center min-h-screen rounded-lg">
			<motion.div
				initial={{ opacity: 0, scale: 0.8 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{ duration: 0.5 }}
				className="bg-background"
			>
				<div className="gap-1 flex flex-col items-center text-center">
					<motion.div
						initial={{ y: -30, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ delay: 0.2, duration: 0.5 }}
						className="flex items-center justify-center"
					>
						<motion.div
							animate={{
								rotate: [0, 10, -10, 10, 0],
							}}
							transition={{
								repeat: Infinity,
								repeatType: 'loop',
								duration: 1,
								ease: 'easeInOut',
							}}
						>
							<Icon
								name="hand-raised"
								size="lg"
								className="text-primary mb-2 fill-red-600"
							/>
						</motion.div>
					</motion.div>
					<motion.h4
						initial={{ x: -30, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						transition={{ delay: 0.3, duration: 0.5 }}
						className="text-xl text-text font-dm-sans text-gray-700"
					>
						Forbidden!
					</motion.h4>
					<motion.p
						initial={{ x: 30, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						transition={{ delay: 0.4, duration: 0.5 }}
						className="text-sm text-text text-gray-500"
					>
						Your token may have expired. Please try refreshing the page or
						returning home.
					</motion.p>
				</div>
			</motion.div>
		</div>
	);
};
