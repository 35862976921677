import React from 'react';
import { motion } from 'framer-motion';

interface IImageSkeletonProps {
	width?: number | string;
	height?: number | string;
}

const ImageSkeleton: React.FC<IImageSkeletonProps> = ({
	width = '100%',
	height = 'auto',
}) => {
	return (
		<div className="relative bg-gray-200" style={{ width, height }}>
			<motion.div
				className="absolute inset-0 bg-gray-300"
				initial={{ x: '-100%' }}
				animate={{ x: '100%' }}
				transition={{ duration: 1.5, ease: 'linear', repeat: Infinity }}
			/>
		</div>
	);
};

export { ImageSkeleton };
