import type { ReactNode } from 'react';

export interface IUI {
	className?: string;
	name?: string;
	disabled?: boolean;
	children?: ReactNode;
	id?: string;
	'data-testid'?: string;
}

export type TVariant =
	| 'default'
	| 'primary'
	| 'secondary'
	| 'danger'
	| 'black'
	| 'white'
	| 'ghost'
	| 'link';

export type TSize = 'inherit' | 'xs' | 'sm' | 'md' | 'lg';

export type TRadius =
	| 'xs'
	| 'sm'
	| 'md'
	| 'lg'
	| 'xl'
	| '2xl'
	| '3xl'
	| 'none'
	| 'full';

export enum EStatus {
	NotSet = 'NotSet',
	Rejected = 'Rejected',
	Approved = 'Approved',
	Paid = 'paid',
	Opened = 'opened',
}

export enum EPaymentMethod {
	CreditCard = 'credit card',
	BankTransfer = 'bank transfer',
}

export const statusMap: Record<string, EStatus> = {
	NotSet: EStatus.NotSet,
	Approved: EStatus.Approved,
	Rejected: EStatus.Rejected,
	Paid: EStatus.Paid,
	Opened: EStatus.Opened,
};
