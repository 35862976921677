import { useQuery } from '@tanstack/react-query';
import { getInvoices, type IGetInvoicesParams } from 'api/resources/invoices';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetInvoices = (params: IGetInvoicesParams) => {
	const query = useQuery({
		queryKey: [ECacheKeys.Invoices],
		queryFn: () => getInvoices(params),
	});

	return query;
};
