import { LayoutContext } from 'app/features/Layout/LayoutContext';
import { type TLayoutOptionsHeader } from 'app/features/Layout/LayoutTypes';
import React, { useState, type ReactNode } from 'react';

interface ILayoutProviderProps {
	children: ReactNode;
}

export const LayoutProvider = ({ children }: ILayoutProviderProps) => {
	const [header, setHeader] = useState<TLayoutOptionsHeader | undefined>(
		undefined,
	);

	return (
		<LayoutContext.Provider
			value={{
				header,
				setHeader,
			}}
		>
			{children}
		</LayoutContext.Provider>
	);
};
