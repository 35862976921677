import { apiClient } from 'api/apiClient';
import type { IApiResponse } from 'api/apiTypes';
import { EStatus, statusMap } from 'ui/types/core';

const RESOURCE = '/proposals';

export interface IProposal {
	id: string;
	number: string;
	title: string;
	date: string;
	total: number;
	deposit: number;
	quantity: number;
	status: TProposalStatuses;
	alreadyDeposited: number;
	pdf: string;
	isCompleted: boolean;
}

export type TProposalStatuses = 'NotSet' | 'Approved' | 'Rejected';

export interface IProposalPatchPayload {
	Id: number;
	Status?: TProposalStatuses;
	Signature?: string;
}

export type TGetProposalResponse = IApiResponse<IProposal[]>;

export type TPostProposalResponse = IApiResponse<{
	isOk: boolean;
}>;

export interface IGetProposalParams {
	token: string;
}

export interface IPostProposalParams {
	token: string;
	payload: IProposalPatchPayload[];
}

export const isProposalStatusApproved = (
	status: TProposalStatuses,
): boolean => {
	return statusMap[status] === EStatus.Approved;
};

export const isProposalStatusRejected = (
	status: TProposalStatuses,
): boolean => {
	return statusMap[status] === EStatus.Rejected;
};

export const isProposalStatusNotSet = (status: TProposalStatuses): boolean => {
	return statusMap[status] === EStatus.NotSet;
};

export const getProposals = async (
	params: IGetProposalParams,
): Promise<TGetProposalResponse> => {
	const { token } = params;
	const { data } = await apiClient.get(`${RESOURCE}/${token}`);
	return data;
};

export const useGetProposalFile = (token: string) => {
	const openFile = (fileId: string, maxWidth = 800, maxHeight = 600) => {
		const url = `${process.env.PUBLIC_APP_API_BASE_URL}/file/${token}/${fileId}?maxWidth=${maxWidth}&maxHeight=${maxHeight}`;
		window.open(url, '_blank');
	};

	return { openFile };
};

export const postProposals = async (
	params: IPostProposalParams,
): Promise<TPostProposalResponse> => {
	const { token, payload } = params;
	const { data } = await apiClient.patch(`${RESOURCE}/${token}`, payload);
	return data;
};
