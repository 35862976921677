import React, { memo, useState } from 'react';
import { Card, Badge, Button, Icon, Checkbox } from 'ui';
import { FormatCurrency } from 'utils/features/formatCurrency';

import { formatDate } from 'utils/date/date';
import { type IInvoices } from 'api/resources/invoices';
import { type IPayinObject } from 'api/resources/payin';
import { EPaymentsFormStatus } from 'app/features/Payments/PaymentsModal';
import { PaymentsSpinner } from 'app/features/Payments/partials/PaymentsSpinner';

interface IInvoiceCardProps {
	invoice: IInvoices;
	isSelected: boolean;
	onOpenPdf: (fileId: string) => void;
	onPayNow: (payItems: IPayinObject[]) => void;
	onSelect: (id: string) => void;
	formStatus: EPaymentsFormStatus;
}

const InvoiceCard = memo(
	({
		invoice,
		isSelected,
		onOpenPdf,
		onPayNow,
		onSelect,
		formStatus,
	}: IInvoiceCardProps) => {
		const [activeId, setActiveId] = useState<string | null>(null);
		return (
			<Card
				border="2xl"
				className={`w-full p-4 bg-background text-text shadow-lg ${
					isSelected ? 'border-2 !border-[#3338A1]' : ''
				}`}
			>
				<div className="flex justify-between items-center mb-2">
					<div className="w-full flex items-center justify-between md:w-fit lg:w-fit md:items-center lg:items-center">
						<h3 className="text-lg font-normal text-primary-badge">
							{invoice.title}
						</h3>
						<Button
							variant="ghost"
							size="sm"
							className="text-primary"
							onClick={() => onOpenPdf(invoice.id)}
						>
							<Badge
								size="xs"
								className="rounded-md bg-purple-200 border-[1.5px] border-purple-200 text-purple-800"
							>
								<div className="text-purple-800 flex flex-row items-center gap-1">
									<span>PDF</span>
									<Icon name="arrow-up-right" className="fill-purple-800" />
								</div>
							</Badge>
						</Button>
					</div>
					{invoice.isOpen && (
						<>
							<div className="hidden md-lg:block lg:block h-fit px-2 py-1 bg-background rounded-lg items-center">
								<Checkbox
									label="Select"
									checked={isSelected}
									onChange={() => onSelect(invoice.id)}
								/>
							</div>
							<div className="block h-fit px-2 py-1 bg-background rounded-lg items-center md-lg:hidden lg:hidden">
								<Checkbox
									hideLabel
									checked={isSelected}
									onChange={() => onSelect(invoice.id)}
									aria-label="Select invoice"
								/>
							</div>
						</>
					)}
				</div>
				<p className="text-xs text-gray-600 text-muted-foreground mb-4">
					Date: {formatDate(invoice.date)} | Items: {invoice.itemsQuantity}
				</p>
				<div className="flex flex-col xl:flex-row gap-2 p-3 rounded-lg border-[1.5px] border-backgroundGray shadow-sm bg-gray">
					<div className="grow 2xl:basis-1/2">
						<div className="flex flex-col gap-2 xl:flex-row md:flex-1 grow text-2xl">
							<div className="grow basis-1/2">
								<FormatCurrency
									value={invoice.total}
									className="font-dm-sans mr-1"
								/>
								<span className="text-xs text-gray-500 font-normal text-muted-foreground">
									Invoice Total
								</span>
							</div>
							<div className="grow basis-1/2">
								<FormatCurrency
									value={invoice.totalPayment}
									className="font-dm-sans mr-1"
								/>
								<span className="text-xs text-gray-500 font-normal text-muted-foreground">
									Paid
								</span>
							</div>
							<div className="grow basis-1/2">
								<FormatCurrency
									value={invoice.amountDue}
									className="font-dm-sans mr-1"
								/>
								<span className="text-xs text-gray-500 font-normal text-muted-foreground whitespace-nowrap">
									Total Amount Due
								</span>
							</div>
						</div>
					</div>
					<div className="grow 2xl:basis-1/2">
						<div className="flex h-full justify-center xl:justify-end items-center">
							{invoice.isOpen && (
								<Button
									variant="white"
									radius="full"
									size="sm"
									disabled={invoice.amountDue <= 0}
									onClick={() => {
										setActiveId(invoice.id);

										onPayNow([
											{
												objectId: invoice.id,
												objectType: 'invoice',
											},
										]);
									}}
								>
									<div className="flex flex-row items-center gap-2">
										{formStatus === EPaymentsFormStatus.Loading &&
										activeId === invoice.id ? (
											<PaymentsSpinner />
										) : (
											<Icon
												name="credit-card"
												className="fill-[--secondary-color]"
											/>
										)}
										Pay Now
									</div>
								</Button>
							)}

							{!invoice.isOpen && (
								<span className="text-sm flex flex-row items-center gap-2">
									<div className="text-green-800 flex flex-row items-center gap-1">
										<Icon name="check-circle" className="fill-green-800" />
										<span>Paid</span>
									</div>
								</span>
							)}
						</div>
					</div>
				</div>
			</Card>
		);
	},
);

InvoiceCard.displayName = 'InvoiceCard';

export { InvoiceCard };