import { MutationCache, QueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const defaultQueryConfig = {
	staleTime: 60000,
	refetchOnWindowFocus: false,
};

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: defaultQueryConfig,
	},
	mutationCache: new MutationCache({
		onSuccess: () => {
			toast.success('Success!');
		},
		onError: (error) => {
			toast.error(`API Error: ${error.message}`);
		},
	}),
});
