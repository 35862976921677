import React from 'react';

interface ILayoutContentProps {
	children: React.ReactNode;
	className?: string;
}

const LayoutContent = ({ children, className }: ILayoutContentProps) => {
	return (
		<div
			className={`
					bg-gray
					border-[1.5px]
					border-border
					rounded-3xl
					p-2 
					m-1

					md:rounded-lg 
					md:p-4
					md:h-fit
					
					lg:rounded-lg
					relative
					min-h-[400px]
					${className}`}
		>
			{children}
		</div>
	);
};

LayoutContent.displayName = 'LayoutContent';

export { LayoutContent };