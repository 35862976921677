import React, { type ReactNode } from 'react';
import { motion } from 'framer-motion';

interface ILayoutProps {
	children: ReactNode;
}

const LayoutWrapper = ({ children }: ILayoutProps) => {
	return (
		<div className="flex-1 flex flex-col border md:m-4 md:border md:rounded-2xl border-border bg-background">
			<main className="flex-1 overflow-y-auto md:h-fit pt-2.5 md:p-2.5">
				<motion.div
					initial={{ opacity: 0, y: 20 }} // Initial state
					animate={{ opacity: 1, y: 0 }} // Animation state when entering
					exit={{ opacity: 0, y: 20 }} // Animation state when exiting
					transition={{ duration: 0.5 }} // Duration of the animation
				>
					{children}
				</motion.div>
			</main>
		</div>
	);
};

export { LayoutWrapper };
