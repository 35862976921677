import { apiClient } from 'api/apiClient';
import type { IApiResponse } from 'api/apiTypes';

const RESOURCE = '/auth';

export interface IGetAuth {
	id: string;
	name: string;
	branding: {
		primary: string;
		secondary?: string;
		logo?: string;
	};
}

export type TGetAuthCheckResponse = IApiResponse<IGetAuth>;

export interface IGetAuthParams {
	token: string;
}

export const getAuthCheck = async (
	params: IGetAuthParams,
): Promise<TGetAuthCheckResponse> => {
	const { token } = params;
	const { data } = await apiClient.get(`${RESOURCE}/${token}`);

	return data;
};
