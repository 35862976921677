import React from 'react';
import { useAuth } from 'app/features/auth/useAuth';
import { Forbidden } from 'app/pages/Forbidden';
import { Outlet } from 'react-router-dom';

export const ProtectedRoutes = () => {
	const { isAuthenticated } = useAuth();

	return isAuthenticated ? <Outlet /> : <Forbidden />;
};
