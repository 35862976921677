import React from 'react';
import { Card, Checkbox, Button, ButtonGroup, Icon } from 'ui';
import { FormatCurrency } from 'utils/features/formatCurrency';
import { ItemThumbs } from './ItemThumbs';
import type { TItemStatuses } from 'api/resources/items';
import { type IItem } from 'api/resources/items';
import { EStatus } from 'app/ui/types/core';

interface IItemCardProps {
	item: IItem;
	isSelected: boolean;
	onSelect: (id: string) => void;
	onImageClick: (imageIndex: number) => void;
	onButtonGroupChange: (id: string, activeId: TItemStatuses | null) => void;
	onUndo: (id: string) => void;
	itemsApproval: boolean | undefined;
}

export const ItemCard = ({
	item,
	isSelected,
	onSelect,
	onImageClick,
	onButtonGroupChange,
	onUndo,
	itemsApproval,
}: IItemCardProps) => {
	const currentActiveId =
		item.status === EStatus.Approved
			? EStatus.Approved
			: item.status === EStatus.Rejected
				? EStatus.Rejected
				: null;

	return (
		<Card
			elevation={2}
			className={`overflow-hidden rounded-xl bg-background ${
				isSelected ? 'border-2 !border-[#3338A1]' : ''
			}`}
			border="lg"
		>
			<div className="flex flex-col md-lg:flex-col lg:flex-row p-4">
				<div className="w-full md:w-full md-lg:w-full lg:w-6/12 3xl:w-5/12">
					<ItemThumbs images={item.images} onImageClick={onImageClick} />
				</div>
				<div className="w-full p-4 flex flex-col justify-between md:w-full md-lg:w-full lg:w-6/12 2xl:w-7/12">
					<div>
						<div className="flex justify-between items-start">
							<div className="flex w-full flex-row justify-between">
								<div className="flex flex-col">
									<h3 className="text-lg text-primary-badge font-semibold mb-2">
										{item.title}
									</h3>
									<p className="text-sm text-gray-600 mb-2">
										Quantity: {item.quantity} | Unit Price: $
										{item.unitPrice ? item.unitPrice.toFixed(2) : '0.00'}
									</p>
								</div>

								{itemsApproval && (
									<>
										<div className="hidden md-lg:block lg:block h-fit px-2 py-1 text-text bg-background rounded-lg items-center">
											<Checkbox
												checked={isSelected}
												onChange={() => onSelect(item.id)}
												label="Select"
											/>
										</div>
										<div className="block h-fit px-2 py-1 bg-background text-text rounded-lg items-center md-lg:hidden lg:hidden">
											<Checkbox
												checked={isSelected}
												onChange={() => onSelect(item.id)}
												hideLabel
												aria-label="Select item"
											/>
										</div>
									</>
								)}
							</div>
						</div>
						<p className="text-sm text-gray-600 mb-2 whitespace-pre-wrap">
							{item.description}
						</p>
					</div>

					<div className="flex flex-col w-full">
						<hr className="border-border" />
						<div className="w-full flex flex-col mt-4 2xl:flex-row lg:justify-between gap-2">
							<p className="flex items-baseline gap-2 mb-2 lg:mb-0">
								<FormatCurrency
									value={item.unitPrice * (item.quantity || 1)}
									className="font-dm-sans text-2xl"
								/>
								<span className="text-xs text-gray-500 font-light">
									Total Price
								</span>
							</p>

							{itemsApproval && (
								<div className="flex items-center space-x-2">
									{currentActiveId && (
										<Button
											variant="ghost"
											className="text-text text-sm font-normal"
											size="xs"
											onClick={() => onUndo(item.id)}
										>
											Undo
										</Button>
									)}
									<ButtonGroup
										toggle
										activeId={currentActiveId}
										onActiveChange={(activeId) =>
											onButtonGroupChange(
												item.id,
												activeId as TItemStatuses | null,
											)
										}
										className="mx-auto w-full"
										aria-label={`Approve or decline ${item.title}`}
										variant="white"
									>
										<Button
											id="rejected"
											className={`flex-1 ${
												currentActiveId === EStatus.Rejected
													? '!bg-[--secondary-color] text-white'
													: ''
											}`}
										>
											<span className="flex items-center">
												<Icon
													name="x-circle"
													size="xs"
													className={`mr-2 ${
														currentActiveId === EStatus.Rejected
															? 'fill-white'
															: 'fill-[--secondary-color]'
													}`}
												/>
												Decline{currentActiveId === EStatus.Rejected && 'd'}
											</span>
										</Button>
										<Button
											id="approved"
											className={`flex-1 ${
												currentActiveId === EStatus.Approved
													? '!bg-[--secondary-color] text-white'
													: ''
											}`}
										>
											<span className="flex items-center">
												<Icon
													name="check-circle"
													size="xs"
													className={`mr-2 ${
														currentActiveId === EStatus.Approved
															? 'fill-white'
															: 'fill-[--secondary-color]'
													}`}
												/>
												Approve{currentActiveId === EStatus.Approved && 'd'}
											</span>
										</Button>
									</ButtonGroup>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Card>
	);
};
