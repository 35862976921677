import React from 'react';

interface IFormatCurrencyProps {
	value: number;
	className?: string;
	currency?: string;
}

export const FormatCurrency = ({
	value,
	className = '',
	currency = 'USD',
}: IFormatCurrencyProps) => {
	// Check if the value has cents
	const hasCents = value % 1 !== 0;

	// Format the value, remove the dollar sign, and remove cents if they are `.00`
	const formattedValue = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency,
		minimumFractionDigits: hasCents ? 2 : 0, // Only show cents if they are non-zero
	})
		.format(value)
		.replace('$', '');

	return (
		<span className={className}>
			<sup>$</sup>
			{formattedValue}
		</span>
	);
};
