import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import cn from 'classnames';
import { Icon } from 'ui';
import type { IUI } from 'ui/types/core';

const loaderVariants = cva(
	'inline-block animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]',
	{
		variants: {
			size: {
				sm: 'h-4 w-4',
				md: 'h-8 w-8',
				lg: 'h-12 w-12',
			},
		},
		defaultVariants: {
			size: 'md',
		},
	},
);

export interface ILoaderProps
	extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color'>,
		VariantProps<typeof loaderVariants>,
		IUI {
	label?: string;
	ariaLabel?: string;
	useIcon?: string;
}

export const Loader: React.FC<ILoaderProps> = ({
	className,
	id = 'loader',
	size = 'md',
	'data-testid': dataTestId = 'loader',
	label,
	role = 'status',
	ariaLabel = label || 'Loading',
	useIcon,
}) => {
	return (
		<div className="flex flex-col items-center justify-center w-full h-full">
			{useIcon ? (
				<div
					className={cn(
						'animate-spin inline-flex justify-center items-center',
						className,
					)}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...(id ? { id } : {})}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...(dataTestId ? { 'data-testid': dataTestId } : {})}
				>
					<Icon name={useIcon} className={className} ariaLabel={ariaLabel} />
				</div>
			) : (
				<div
					className={cn(loaderVariants({ size }), className)}
					role={role}
					aria-label={ariaLabel}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...(id ? { id } : {})}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...(dataTestId ? { 'data-testid': dataTestId } : {})}
				>
					<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
						Loading...
					</span>
				</div>
			)}
			{label && <span className="mt-2 text-sm text-gray-500">{label}</span>}
		</div>
	);
};
