import React, { useRef, useState, useEffect, useCallback } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Icon, Input } from 'ui';

export interface ISignatureWidgetProps {
	onChange: (signature: string | null) => void;
	useManualSignature?: boolean;
	textColor?: string;
	className?: string;
	disabled?: boolean;
}

const CANVAS_PADDING = 40;
const DEFAULT_FONT_SIZE = 32;
const CANVAS_WIDTH = 300;
const CANVAS_HEIGHT = 150;

const fontStyles = [
	{ name: 'signature', value: '"Dancing Script", cursive' },
	{ name: 'ornate', value: '"Georgia"' },
	{ name: 'stylish', value: '"Brush Script MT", cursive' },
];

const generateBase64Image = (
	name: string,
	font: string,
	color: string,
	width: number,
	height: number,
): string => {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');
	if (!ctx) return '';
	canvas.width = width;
	canvas.height = height;
	ctx.font = `${DEFAULT_FONT_SIZE}px ${font}`;
	ctx.fillStyle = color;
	ctx.textAlign = 'center';
	ctx.textBaseline = 'middle';
	ctx.fillText(name, canvas.width / 2, canvas.height / 2);
	return canvas.toDataURL();
};

const SignatureWidget = ({
	onChange,
	useManualSignature = false,
	textColor = 'black',
	className = '',
	disabled = false,
}: ISignatureWidgetProps) => {
	const [currentView, setCurrentView] = useState<'add' | 'manual' | 'drawing'>(
		'add',
	);
	const [name, setName] = useState('');
	const [selectedFont, setSelectedFont] = useState(fontStyles[0]);
	const [isGenerating, setIsGenerating] = useState(false);
	const signaturePadRef = useRef<SignatureCanvas | null>(null);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
	const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const updateCanvasSize = () => {
			if (containerRef.current) {
				const { width, height } = containerRef.current.getBoundingClientRect();
				setCanvasSize({
					width: width - CANVAS_PADDING,
					height: height - CANVAS_PADDING,
				});
			}
		};

		updateCanvasSize();
		window.addEventListener('resize', updateCanvasSize);

		return () => {
			window.removeEventListener('resize', updateCanvasSize);
		};
	}, []);

	const drawPreview = useCallback(async () => {
		if (previewCanvasRef.current) {
			const ctx = previewCanvasRef.current.getContext('2d');
			if (ctx) {
				ctx.clearRect(
					0,
					0,
					previewCanvasRef.current.width,
					previewCanvasRef.current.height,
				);
				if (name) {
					const font = selectedFont.value;
					ctx.font = `${DEFAULT_FONT_SIZE}px ${font}`;
					ctx.fillStyle = textColor;
					ctx.textAlign = 'center';
					ctx.textBaseline = 'middle';

					await document.fonts.load(ctx.font);
					ctx.fillText(
						name,
						previewCanvasRef.current.width / 2,
						previewCanvasRef.current.height / 2,
					);
				}
			}
		}
	}, [name, selectedFont.value, textColor]);

	useEffect(() => {
		drawPreview();
	}, [drawPreview]);

	const clearSignature = () => {
		if (signaturePadRef.current) {
			signaturePadRef.current.clear();
			onChange(null);
		}
	};

	const handleSignatureEnd = () => {
		if (signaturePadRef.current) {
			const signatureData = signaturePadRef.current.toDataURL();
			onChange(signatureData);
		}
	};

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
	};

	const handleFontChange = (font: (typeof fontStyles)[0]) => {
		setSelectedFont(font);
	};

	const handleSignatureView = () => {
		setCurrentView('drawing');
	};

	const handleGenerateSignature = async () => {
		setIsGenerating(true);
		const base64Image = generateBase64Image(
			name,
			selectedFont.value,
			textColor,
			canvasSize.width,
			canvasSize.height,
		);
		onChange(base64Image);
		setTimeout(() => {
			setIsGenerating(false);
		}, 1000);
	};

	return (
		<div
			ref={containerRef}
			className={`bg-gray-100 rounded-lg flex flex-col items-center justify-center relative w-full h-full p-4 ${className}`}
		>
			{currentView === 'add' && (
				<div className="flex flex-col">
					<Button
						className="!bg-[#0c0e33] text-white hover:bg-primary/90 disabled:cursor-not-allowed"
						onClick={handleSignatureView}
						disabled={disabled}
						size="sm"
					>
						<Icon name="pencil-square" className="mr-2 fill-white" />
						Add Signature
					</Button>
					{useManualSignature && (
						<Button
							onClick={() => setCurrentView('manual')}
							variant="link"
							size="xs"
							className="w-full !text-blue-500 whitespace-nowrap !p-0"
						>
							<span className="absolute flex items-center justify-center bottom-5">
								Use Text Signature
							</span>
						</Button>
					)}
				</div>
			)}

			{currentView === 'manual' && (
				<>
					<div className="w-full max-w-md space-y-4">
						<Input
							placeholder="Enter your name"
							value={name}
							onChange={handleNameChange}
							aria-label="Enter your name"
						/>
						<div className="flex justify-between space-x-2">
							{fontStyles.map((font) => (
								<Button
									key={font.name}
									onClick={() => handleFontChange(font)}
									variant={
										selectedFont.name === font.name ? 'primary' : 'white'
									}
									className={`flex-1 font-${font.name}`}
								>
									{font.name}
								</Button>
							))}
						</div>
						<div className={`border rounded-lg p-4 bg-background`}>
							<canvas
								ref={previewCanvasRef}
								width={CANVAS_WIDTH}
								height={CANVAS_HEIGHT}
								className="w-full h-auto text-[24px]"
							/>
						</div>
					</div>
					<div className="w-full h-full flex flex-col items-center justify-center pb-4">
						<Button
							onClick={handleGenerateSignature}
							disabled={disabled || !name || isGenerating}
							size="xs"
							variant="link"
						>
							{isGenerating ? 'Generating...' : 'Generate Signature'}
						</Button>
						<Button
							variant="ghost"
							size="xs"
							onClick={() => {
								setCurrentView('drawing');
								setName('');
							}}
							className="w-full !text-blue-500 whitespace-nowrap !p-0"
						>
							<span className="absolute flex items-center justify-center bottom-5">
								Use Drawing Signature
							</span>
						</Button>
					</div>
				</>
			)}

			{currentView === 'drawing' && (
				<div className="flex flex-col w-full h-full">
					<SignatureCanvas
						ref={signaturePadRef}
						penColor={textColor}
						onEnd={handleSignatureEnd}
						canvasProps={{
							width: canvasSize.width,
							height: canvasSize.height,
							className: `sigCanvas border rounded-lg border-gray-300 bg-background w-full h-full ${className}`,
						}}
					/>
					<div className="absolute top-5 right-5 z-10">
						<Button variant="secondary" size="xs" onClick={clearSignature}>
							Clear
						</Button>
					</div>
					{useManualSignature && (
						<Button
							onClick={() => setCurrentView('manual')}
							variant="link"
							size="xs"
							className="w-full !text-blue-500 whitespace-nowrap !p-0"
						>
							<span className="absolute flex items-center justify-center bottom-5">
								Use Text Signature
							</span>
						</Button>
					)}
				</div>
			)}
		</div>
	);
};

export { SignatureWidget };
