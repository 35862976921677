import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';
import { getAuthCheck } from 'api/resources/auth';
import type { IGetAuthParams, TGetAuthCheckResponse } from 'api/resources/auth';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetAuthCheck = (
	params: IGetAuthParams,
	options?: UseQueryOptions<TGetAuthCheckResponse>,
) => {
	// Error can be checked by doing:
	// (query?.error as AxiosError)?.response?.status === 401
	const query = useQuery<TGetAuthCheckResponse>({
		queryKey: [ECacheKeys.Authentication],
		queryFn: () => getAuthCheck(params),
		...{
			...options,
			retry: false,
		},
	});

	return query;
};
