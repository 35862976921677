import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postItemsStatus } from 'api/resources/items';
import { ECacheKeys } from 'cache/CacheKeys';

export const usePostItemsStatus = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: postItemsStatus,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [ECacheKeys.Overviews],
			});
		},
	});
};
