import React, { forwardRef } from 'react';
import cn from 'classnames';
import type { IUI } from 'ui/types/core';

export interface ICheckboxProps extends IUI {
	label?: string;
	checked?: boolean;
	defaultChecked?: boolean;
	onChange?: (checked: boolean) => void;
	value?: string;
	required?: boolean;
	hideLabel?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(
	(
		{
			label,
			className,
			checked,
			defaultChecked,
			onChange,
			disabled,
			id,
			'data-testid': dataTestId,
			value,
			required,
			hideLabel,
		},
		ref,
	) => {
		// Check for controlled vs. uncontrolled props conflict
		if (defaultChecked !== undefined && value !== undefined) {
			// eslint-disable-next-line no-console
			console.warn(
				'Warning: You are trying to use both `defaultChecked` and `value` props together. This results in a conflict between controlled and uncontrolled checkbox behavior. Please use either one, not both.',
			);
		}

		const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			const isChecked = event.target.checked; // Get the boolean value
			if (onChange) {
				onChange(isChecked); // Call custom onChange with boolean
			}
		};

		const isChecked = checked !== undefined ? checked : defaultChecked;

		return (
			<label
				className={cn(
					'flex items-center cursor-pointer',
					{ 'opacity-50 cursor-not-allowed': disabled },
					className,
				)}
			>
				<div className="relative">
					<input
						type="checkbox"
						className="sr-only"
						checked={isChecked}
						defaultChecked={defaultChecked}
						onChange={handleChange} // Use the custom handleChange
						disabled={disabled}
						ref={ref}
						id={id}
						data-testid={dataTestId}
						value={value}
						required={required}
					/>
					<div
						className={cn(
							'w-5 h-5 border-2 rounded-md transition-all flex items-center justify-center',
							{
								'bg-[#3338A1] border-[#3338A1]': isChecked,
								'bg-white border-gray-300': !isChecked,
								'border-red-500': required && !isChecked,
								'hover:border-[#3338A1]': !disabled && !required,
							},
						)}
					>
						{isChecked && (
							<svg
								className="w-3 h-3 text-white"
								viewBox="0 0 12 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10 3L4.5 8.5L2 6"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						)}
					</div>
				</div>

				{label && !hideLabel && (
					<span
						className={cn(
							'block ml-2 text-sm font-medium text-gray-700',
							className,
							{
								'cursor-not-allowed': disabled,
							},
						)}
					>
						{label}
					</span>
				)}
			</label>
		);
	},
);

Checkbox.displayName = 'Checkbox';

export { Checkbox };
