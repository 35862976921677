import React from 'react';

export const Invoices = () => {
	return (
		<>
			<h1>Invoices!</h1>
			<p>Invoices list page.</p>
		</>
	);
};
