import React, { useCallback, useMemo, useState } from 'react';
import { Button, Card, Icon } from 'ui';
import { SummaryCard, ActionItems } from 'features/Layout/PageActions';
import { useGetInvoiceFile, type IInvoices } from 'api/resources/invoices';
import { motion } from 'framer-motion';

import { useStackAnimation } from 'app/hooks/useStackAnimation';
import { InvoiceCard } from './partials/InvoiceCard';
import { useSearchParams } from 'react-router-dom';
import { useGetInvoices } from 'api/hooks/useGetInvoices';
import { usePayments } from 'app/features/Payments/usePayments';
import { type IPayinObject } from 'api/resources/payin';
import { EPaymentsFormStatus } from 'app/features/Payments/PaymentsModal';
import { PaymentsSpinner } from 'app/features/Payments/partials/PaymentsSpinner';
import { useAuth } from 'app/features/auth/useAuth';
import { useLayout } from 'app/features/Layout/hooks/useLayout';

export const Invoices = () => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token') || '';
	const { org } = useAuth();
	const { data, isLoading, error } = useGetInvoices({ token });
	const { openFile } = useGetInvoiceFile(token);
	const [selectedInvoices, setSelectedInvoices] = useState<IInvoices[]>([]);

	const invoices = useMemo<IInvoices[]>(() => data?.data || [], [data]);

	const handleSelectAll = () =>
		setSelectedInvoices(invoices.filter((invoice) => invoice.isOpen));

	const { paymentsModal, loadPaymentModal, paymentsFormStatus } = usePayments();

	const motionProps = useStackAnimation(0.1, !isLoading);

	const toggleSelection = useCallback(
		(id: string) => {
			setSelectedInvoices((prev) => {
				const invoice = invoices.find((invoice) => invoice.id === id);
				if (invoice) {
					const isSelected = prev.some((i) => i.id === id);

					if (isSelected) {
						const newSelection = prev.filter((i) => i.id !== id);

						return newSelection;
					} else {
						const newSelection = [...prev, invoice];

						return newSelection;
					}
				}

				return prev;
			});
		},
		[invoices, setSelectedInvoices],
	);

	const headerActions = [];

	if (invoices.length > 0) {
		headerActions.push(
			<Button key="btn-select-all" size="sm" onClick={handleSelectAll}>
				Select All
			</Button>,
		);
	}

	headerActions.push(
		<Button
			key="btn-pay-selected"
			variant="primary"
			size="sm"
			className="flex gap-2"
			onClick={() => {
				handleBatchPay(
					selectedInvoices.map((invoice) => ({
						objectId: invoice.id,
						objectType: 'invoice',
					})),
				);
			}}
			disabled={selectedInvoices.length === 0}
		>
			{paymentsFormStatus === EPaymentsFormStatus.Loading ? (
				<PaymentsSpinner />
			) : (
				<Icon name="credit-card" className="fill-white" />
			)}
			Pay Selected
		</Button>,
	);

	useLayout(
		{
			header: {
				title: `Invoices ${org?.project?.name ? `for ${org?.project?.name}` : ''}`,
				icon: 'inbox-arrow-down',
				actions: headerActions,
			},
		},
		useMemo(
			() => [
				org?.project?.name,
				paymentsFormStatus,
				selectedInvoices.length,
				invoices,
			],
			[
				org?.project?.name,
				paymentsFormStatus,
				selectedInvoices.length,
				invoices,
			],
		),
	);

	if (error) return <div>Error loading invoices: {error.message}</div>;

	const combinedTotal = invoices.reduce(
		(sum, invoice) => sum + invoice.total,
		0,
	);
	const previousPayments = invoices.reduce(
		(sum, invoice) => sum + invoice.totalPayment,
		0,
	);
	const balanceDue = combinedTotal - previousPayments;

	const summaryItems = [
		{ label: 'Total Invoices Due', amount: combinedTotal },
		{ label: 'Previous Payments', amount: previousPayments },
		{ label: 'Balance Due', amount: balanceDue },
	];

	const handleBatchPay = (payItems: IPayinObject[]) => {
		loadPaymentModal(payItems);
	};

	return (
		<>
			{invoices && invoices.length > 0 ? (
				<>
					<SummaryCard items={summaryItems} isDataReady={!isLoading} />
					<ActionItems title="Invoices Due">
						{invoices.map((invoice, index) => (
							<motion.div
								key={index}
								initial={motionProps.initial}
								animate={motionProps.animate}
								custom={index}
								className="w-full"
							>
								<InvoiceCard
									invoice={invoice}
									isSelected={selectedInvoices.some((i) => i.id === invoice.id)}
									onSelect={toggleSelection}
									onOpenPdf={() => openFile(invoice.pdf)}
									onPayNow={handleBatchPay}
									formStatus={paymentsFormStatus}
								/>
							</motion.div>
						))}
					</ActionItems>
				</>
			) : !isLoading ? (
				<Card className="h-full flex flex-col rounded-xl p-4 bg-background w-fit md:rounded-3xl lg:rounded-3xl md:p-5 lg:p-5">
					<div className="h-full gap-1 flex flex-col items-center text-center">
						<Icon
							name="check-circle"
							size="lg"
							className="text-primary mb-4 fill-primary-badge"
						/>

						<h3 className="text-lg font-semibold text-text">
							No Invoices To Display
						</h3>
						<p className="text-sm text-gray-500">
							Check back later for new updates or tasks to complete.
						</p>
					</div>
				</Card>
			) : null}
			{paymentsModal}
		</>
	);
};

Invoices.displayName = 'Invoices';
