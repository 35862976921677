import { useMutation, useQueryClient } from '@tanstack/react-query';
import type {
	TPostProposalResponse,
	IProposalPatchPayload,
} from 'api/resources/proposals';
import { postProposals } from 'api/resources/proposals';
import { ECacheKeys } from 'cache/CacheKeys';

export const usePostProposals = (token: string) => {
	const queryClient = useQueryClient();

	return useMutation<TPostProposalResponse, Error, IProposalPatchPayload[]>({
		mutationFn: (payload) => postProposals({ token, payload }),
		onSuccess: async (response) => {
			if (response.isOk) {
				await queryClient.invalidateQueries({
					queryKey: [ECacheKeys.Proposals],
				});
			} else {
				// eslint-disable-next-line no-console
				console.warn('Mutation returned isOk: false', response); // Debug log
			}
		},
		onError: (error) => {
			// eslint-disable-next-line no-console
			console.error('Failed to update proposals:', error);
		},
	});
};
