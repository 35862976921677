import { useSuspenseQuery } from '@tanstack/react-query';
import { getItems, type IGetItemsParams } from 'api/resources/items';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetItems = (params: IGetItemsParams) => {
	const query = useSuspenseQuery({
		queryKey: [ECacheKeys.Items],
		queryFn: () => getItems(params),
	});

	return query;
};
