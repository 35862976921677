import React, { useState, useCallback, useMemo } from 'react';
import { Modal, Card, Carousel, Loader } from 'ui';
import { LayoutWrapper, LayoutHeader, LayoutContent } from 'features/Layout';
import { useGetOverviews } from 'api/hooks/useGetOverviews';
import { usePostItemsStatus } from 'api/hooks/usePostItemsStatus';
import { type IItems, type TItemStatuses } from 'api/resources/overviews';
import { motion, AnimatePresence } from 'framer-motion';
import { ItemsTop, ItemCard, ItemActionHeader } from './partials';
import { useStackAnimation } from 'hooks/useStackAnimation';

export const CustomModalTitle = ({ title }: { title: string }) => (
	<h2 className="flex text-sm font-sans font-semibold text-left py-[10px]">
		{title}
	</h2>
);

export interface IItemsToUpdate {
	id: string;
	status: TItemStatuses;
}

export const Items: React.FC = () => {
	const [selectedItem, setSelectedItem] = useState<IItems | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedItems, setSelectedItems] = useState<string[]>([]);
	const [filter, setFilter] = useState<'all' | 'approved' | 'declined'>('all');
	const [bulkAction, showBulkAction] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);

	const { data, isLoading, error } = useGetOverviews();
	const { mutate: postItemsStatus } = usePostItemsStatus();

	const isDataReady = !!data?.data?.items;
	const { initial, animate, custom } = useStackAnimation(0.3, isDataReady);

	const filteredItems = useMemo(() => {
		if (!data?.data?.items) return [];

		return data.data.items.filter((item) => {
			const matchesFilter =
				filter === 'all' ||
				(filter === 'approved' && item.status === 'approved') ||
				(filter === 'declined' && item.status === 'declined');

			const matchesSearch =
				item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
				item.description.toLowerCase().includes(searchTerm.toLowerCase());

			return matchesFilter && matchesSearch;
		});
	}, [data, filter, searchTerm]);

	const openModal = useCallback((item: IItems, imageIndex: number = 0) => {
		setSelectedItem(item);
		setSelectedImageIndex(imageIndex);
		setIsModalOpen(true);
	}, []);

	const closeModal = useCallback(() => {
		setIsModalOpen(false);
		setSelectedItem(null);
		setSelectedImageIndex(0);
	}, []);

	const toggleItemSelection = useCallback((itemId: string) => {
		setSelectedItems((prev) =>
			prev.includes(itemId)
				? prev.filter((id) => id !== itemId)
				: [...prev, itemId],
		);
	}, []);

	const handleButtonGroupChange = useCallback(
		(itemId: string, action: 'approved' | 'declined' | null) => {
			if (action) {
				postItemsStatus([{ id: itemId, status: action }]);
			}
		},
		[postItemsStatus],
	);

	const handleUndo = useCallback(
		(itemId: string) => {
			postItemsStatus([{ id: itemId, status: 'pending' }]);
		},
		[postItemsStatus],
	);

	const handleApproveAll = useCallback(() => {
		if (selectedItems.length === 0) return;

		const itemsToUpdate: IItemsToUpdate[] = selectedItems.map((itemId) => ({
			id: itemId,
			status: 'approved' as TItemStatuses,
		}));

		postItemsStatus(itemsToUpdate, {
			onSuccess: () => {
				setSelectedItems([]);
			},
		});
	}, [selectedItems, postItemsStatus]);

	const handleDeclineAll = useCallback(() => {
		if (selectedItems.length === 0) return;

		const itemsToUpdate: IItemsToUpdate[] = selectedItems.map((itemId) => ({
			id: itemId,
			status: 'declined' as TItemStatuses,
		}));

		postItemsStatus(itemsToUpdate, {
			onSuccess: () => {
				setSelectedItems([]);
			},
		});
	}, [selectedItems, postItemsStatus]);

	if (isLoading) return <Loader aria-label="Loading items" />;
	if (error)
		return <div role="alert">Error loading items: {error.message}</div>;

	return (
		<LayoutWrapper>
			<LayoutHeader
				title="Items"
				icon="queue-list"
				actions={
					<ItemActionHeader
						items={data?.data?.items || []}
						filter={filter}
						setFilter={setFilter}
						isFilterDropdownOpen={isFilterDropdownOpen}
						setIsFilterDropdownOpen={setIsFilterDropdownOpen}
						handleBulkAction={showBulkAction}
						bulkAction={bulkAction}
					/>
				}
			/>
			<LayoutContent className="px-4 lg:px-28 pt-6 lg:pt-25">
				<Card className="bg-background mx-auto px-4 lg:px-10 py-4 lg:py-6 rounded-3xl">
					<ItemsTop
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
						itemCount={filteredItems.length}
						selectedItemsCount={selectedItems.length}
						onDeclineAll={handleDeclineAll}
						onApproveAll={handleApproveAll}
						isOpen={bulkAction}
					/>

					<AnimatePresence>
						<motion.div className="space-y-4 text-fix">
							{filteredItems.map((item, index) => (
								<motion.div
									key={item.id}
									initial={initial}
									animate={animate}
									custom={custom(index)}
									layout
								>
									<ItemCard
										item={item}
										isSelected={selectedItems.includes(item.id)}
										onSelect={toggleItemSelection}
										onImageClick={(imageIndex) => openModal(item, imageIndex)}
										onButtonGroupChange={handleButtonGroupChange}
										onUndo={handleUndo}
									/>
								</motion.div>
							))}
						</motion.div>
					</AnimatePresence>
				</Card>

				{selectedItem && (
					<Modal
						isOpen={isModalOpen}
						onClose={closeModal}
						title={selectedItem.title}
						overrides={{
							Title: CustomModalTitle,
						}}
					>
						<Carousel
							displayRest="images"
							images={selectedItem.images}
							initialSlide={selectedImageIndex}
						/>
					</Modal>
				)}
			</LayoutContent>
		</LayoutWrapper>
	);
};
