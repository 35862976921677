import React from 'react';
import { Icon, Button, Badge } from 'ui';
import { ActionTitle } from 'app/features/Layout/PageActions';
import { motion, AnimatePresence } from 'framer-motion';

interface IItemsTopProps {
	searchTerm: string;
	setSearchTerm: (term: string) => void;
	itemCount: number;
	selectedItemsCount: number;
	onDeclineAll: () => void;
	onApproveAll: () => void;
	showSelectAll: boolean;
	handleSelectAll: () => void;
	isOpen: boolean;
}

export const ItemsTop = ({
	searchTerm,
	setSearchTerm,
	handleSelectAll,
	showSelectAll,
	itemCount,
	selectedItemsCount,
	onDeclineAll,
	onApproveAll,
	isOpen = false,
}: IItemsTopProps) => {
	const menuVariants = {
		hidden: { scale: 0, opacity: 0 },
		visible: {
			scale: 1,
			opacity: 1,
			transition: { type: 'spring', stiffness: 300, damping: 25 },
		},
	};

	return (
		<div className="w-full h-full flex flex-col gap-3 mb-4 md-lg:flex-col lg:flex-row lg:items-center">
			<div className="w-full flex justify-center gap-2 lg:w-fit lg:text-2xl">
				<ActionTitle
					title={
						<>
							<span className="text-primary-badge">{itemCount}</span> Items to
							Review
						</>
					}
					className="!mb-0"
				/>
			</div>

			<div className="flex mx-auto items-center">
				{(isOpen || selectedItemsCount > 0) && (
					<AnimatePresence>
						<motion.div
							initial="hidden"
							animate="visible"
							exit="hidden"
							variants={menuVariants}
							className="flex items-center gap-2"
						>
							<Button
								variant="primary"
								onClick={onDeclineAll}
								size="sm"
								className=" rounded-lg shadow-lg inline-flex"
								disabled={selectedItemsCount === 0}
							>
								<Icon name="x-circle" className="mr-1 fill-white" />
								Decline Selected
							</Button>
							<Button
								variant="primary"
								onClick={onApproveAll}
								size="sm"
								className="rounded-lg shadow-lg inline-flex"
								disabled={selectedItemsCount === 0}
							>
								<Icon name="check-circle" className="mr-1 fill-white" />
								Approve Selected
							</Button>

							{selectedItemsCount > 0 && (
								<sup>
									<Badge size="xs" className="bg-primary-badge">
										{selectedItemsCount}
									</Badge>
								</sup>
							)}
						</motion.div>
					</AnimatePresence>
				)}
			</div>
			{showSelectAll && (
				<Button
					size="sm"
					onClick={handleSelectAll}
					variant={`${itemCount === selectedItemsCount ? 'default' : 'secondary'}`}
					disabled={itemCount === selectedItemsCount}
				>
					Select All
				</Button>
			)}
			<div className="flex items-center w-full border border-border rounded-full px-4 py-2 focus-within:ring-2 focus-within:ring-primary-badge md:w-full md-lg:w-full lg:w-auto">
				<Icon name="magnifying-glass" className="fill-fill mr-2" />
				<input
					type="text"
					placeholder="Search"
					className="w-full bg-background border-none focus:outline-none"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					aria-label="Search items"
				/>
			</div>
		</div>
	);
};
