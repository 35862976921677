import React from 'react';
export interface IModalTitleProps {
	title: string;
	subtitle?: string;
}

export const ModalTitle = ({ title, subtitle }: IModalTitleProps) => (
	<div>
		<h2 className="text-xl font-semibold text-center">{title}</h2>
		{subtitle && <span className="text-xs text-gray-500">{subtitle}</span>}
	</div>
);
