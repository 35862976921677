import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';

export const useStackAnimation = (delay = 0.1, isDataReady = false) => {
	const controls = useAnimation();

	useEffect(() => {
		// Only start animation when data is ready
		if (isDataReady) {
			controls.start((i) => ({
				opacity: 1,
				y: 0,
				transition: { delay: i * delay, type: 'spring', stiffness: 100 },
			}));
		}
	}, [controls, delay, isDataReady]);

	const motionProps = {
		initial: { opacity: 0, y: -10 },
		animate: controls,
		custom: (i: number) => i,
	};

	return motionProps;
};
