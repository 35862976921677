import React from 'react';
import { usePostPayin } from 'api/hooks/usePostPayin';
import type { IPayin, IPayinObject } from 'api/resources/payin';
import {
	PaymentsModal as PaymentModalComponent,
	EPaymentsFormStatus,
} from 'app/features/Payments/PaymentsModal';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePayments = () => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token') || '';
	const [isOpen, setIsOpen] = useState(false);
	const { mutateAsync: mutatePayin } = usePostPayin();
	const [payinInfo, setPayinInfo] = useState<null | IPayin>(null);
	const [paymentsFormStatus, setPaymentsFormStatus] = useState(
		EPaymentsFormStatus.Idle,
	);
	const [payinItems, setPayinItems] = useState<IPayinObject[]>([]);

	const loadPaymentModal = async (paymentItems: IPayinObject[]) => {
		setPaymentsFormStatus(EPaymentsFormStatus.Loading);
		setPayinItems(paymentItems);

		const response = await mutatePayin({ token, payload: paymentItems });

		setPayinInfo(response.data);
		setIsOpen(true);
		setPaymentsFormStatus(EPaymentsFormStatus.Idle);
	};

	const onClose = () => {
		setIsOpen(!isOpen);
	};

	// Do not render modal till payinInfo is available
	// to minimize the gymnastics of conditionally rendering
	// the non-React Rainforest widget and setting its on events
	const paymentsModal =
		// check for isOpen to trigger recalculation of the subtotal, fees & total
		payinInfo && isOpen ? (
			<PaymentModalComponent
				payinInfo={payinInfo}
				isOpen={isOpen}
				onClose={onClose}
				paymentsFormStatus={paymentsFormStatus}
				onStatusChange={setPaymentsFormStatus}
				payinItems={payinItems}
			/>
		) : null;

	return { loadPaymentModal, paymentsModal, paymentsFormStatus };
};
