import React, { forwardRef, useState } from 'react';
import cn from 'classnames';
import type { IUI } from 'ui/types/core';

export interface ICheckboxProps extends IUI {
	label?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	required?: boolean;
	hideLabel?: boolean;
	value?: string;
	checked?: boolean;
	defaultChecked?: boolean;
	name?: string;
}

const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(
	(
		{
			id,
			'data-testid': dataTestId,
			label,
			name,
			onChange,
			onBlur,
			disabled,
			required,
			className,
			hideLabel = false,
			value,
			checked,
			defaultChecked,
		},
		ref,
	) => {
		const isControlled = checked !== undefined;
		const [isChecked, setIsChecked] = useState(defaultChecked || false);

		if (isControlled && defaultChecked !== undefined) {
			// eslint-disable-next-line no-console
			console.warn(
				'Warning: You are using `Checkbox` as both a controlled and uncontrolled component. ' +
					'Decide between using a controlled component (`checked`) or an uncontrolled component (`defaultChecked`). ' +
					'More info: https://reactjs.org/docs/forms.html#controlled-components',
			);
		}

		const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			if (!isControlled) {
				setIsChecked(event.target.checked);
			}
			if (onChange) {
				onChange(event);
			}
		};

		const currentChecked = isControlled ? checked : isChecked;

		return (
			<label
				className={cn(
					'flex items-center cursor-pointer',
					{ 'opacity-50 cursor-not-allowed': disabled },
					className,
				)}
			>
				<div className="relative">
					<input
						type="checkbox"
						className="sr-only"
						ref={ref}
						disabled={disabled}
						required={required}
						onChange={handleChange}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...(id ? { id } : {})}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...(dataTestId ? { 'data-testid': dataTestId } : {})}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...(name ? { name } : {})}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...(onBlur ? { onBlur } : {})}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...(value !== undefined ? { value } : {})}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...(checked !== undefined ? { checked } : {})}
					/>
					<div
						className={cn(
							'w-5 h-5 border-2 rounded-md transition-all flex items-center justify-center',
							{
								'bg-[#3338A1] border-[#3338A1]': currentChecked,
								'bg-white border-gray-300': !currentChecked,
								'border-red-500': required && !currentChecked,
								'hover:border-[#3338A1]': !disabled && !required,
							},
						)}
					>
						{currentChecked && (
							<svg
								className="w-3 h-3 text-white"
								viewBox="0 0 12 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10 3L4.5 8.5L2 6"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						)}
					</div>
				</div>
				{label && !hideLabel && (
					<span
						className={cn(
							'block ml-2 text-sm font-medium text-text',
							className,
							{
								'cursor-not-allowed': disabled,
							},
						)}
					>
						{label}
					</span>
				)}
			</label>
		);
	},
);

Checkbox.displayName = 'Checkbox';

export { Checkbox };
