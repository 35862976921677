import { useQuery } from '@tanstack/react-query';
import type {
	IGetProposalParams,
	TGetProposalResponse,
} from 'api/resources/proposals';
import { getProposals } from 'api/resources/proposals';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetProposals = (params: IGetProposalParams) => {
	return useQuery<TGetProposalResponse>({
		queryKey: [ECacheKeys.Proposals],
		queryFn: () => getProposals(params),
		retry: 1,
		refetchOnWindowFocus: false,
	});
};
