import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Image, Button, Icon } from 'ui';

interface IItemThumbsProps {
	images: string[];
	onImageClick: (imageIndex: number) => void;
	mainImageIndex?: number;
}

export const ItemThumbs = ({
	images,
	onImageClick,
	mainImageIndex = 0,
}: IItemThumbsProps) => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token') || '';
	const placeholder = new URL('/assets/image-placeholder.png', import.meta.url)
		.href;

	const mainImageCSS = `lg:w-${images.length > 1 ? '4/5' : 'full'}`;

	const MAIN_THUMB_MAX_WIDTH = 600;
	const SMALL_THUMB_MAX_WIDTH = 500;

	return (
		<div className="flex flex-col xl:flex-row gap-2">
			{/* Main Image Section */}
			<div className={`pr-0 mb-2 w-full ${mainImageCSS} lg:mb-0`}>
				<div className="relative">
					{/* Main Image */}
					<Image
						src={
							images[0]
								? `${process.env.PUBLIC_APP_API_BASE_URL}/file/${token}/${images[0]}?maxWidth=${MAIN_THUMB_MAX_WIDTH}`
								: placeholder
						}
						alt="Main Item Image"
						className="rounded-md object-center m-auto"
						style={{
							maxHeight: '600px',
						}}
						onClick={() => onImageClick(mainImageIndex)}
						disabled={images.length === 0}
					/>
					{images.length > 0 && (
						<Button
							variant="ghost"
							size="sm"
							className="!p-0 absolute top-2 left-2"
							onClick={() => onImageClick(mainImageIndex)}
							aria-label="View larger image"
						>
							<div className="bg-background rounded-md w-6 h-6 flex items-center justify-center">
								<Icon
									name="magnifying-glass-plus"
									size="xs"
									className="fill-black"
								/>
							</div>
						</Button>
					)}
				</div>
			</div>

			{/* Thumbnails Section */}
			{images.length > 1 && (
				<div
					className={`flex flex-row items-center gap-2 space-x-2 xl:w-1/5 xl:flex-col lg:space-x-0`}
				>
					{images.slice(1).map((image, index) => (
						<div
							key={index}
							className="cursor-pointer xs:grow"
							onClick={() => onImageClick(index + 1)}
						>
							<Image
								src={`${process.env.PUBLIC_APP_API_BASE_URL}/file/${token}/${image}?maxWidth=${SMALL_THUMB_MAX_WIDTH}`}
								alt={`Thumbnail Image ${index + 2}`}
								className="rounded-md aspect-w-1 aspect-h-1 object-cover w-full"
							/>
						</div>
					))}
				</div>
			)}
		</div>
	);
};
