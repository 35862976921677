import { apiClient } from 'api/apiClient';
import type { IApiResponse } from 'api/apiTypes';

const RESOURCE = '/overviews';

export interface IImageObject {
	src: string;
	alt: string;
	title?: string;
}
export type TItemStatuses = 'opened' | 'pending' | 'approved' | 'declined';

export interface IItems {
	id: string;
	quantity: number;
	title: string;
	description: string;
	unitPrice: number;
	total: number;
	status: TItemStatuses;
	images: IImageObject[];
}

export interface IProposals {
	title: string;
	pdf: string;
	date: string;
	quantity: number;
	id: string;
	total: number;
	deposit: number;
	status: 'opened' | 'pending' | 'approved' | 'signed' | 'paid' | 'declined';
}

export interface IInvoices {
	id: string;
	title: string;
	pdf: string;
	total: number;
	status: 'opened' | 'pending' | 'paid';
	date: string;
	itemsQuantity: number;
}

export interface IGetOverviews {
	budget: {
		total: number;
	};
	retainerFee: {
		total: number;
	};
	items: IItems[];
	proposals: IProposals[];
	invoices: IInvoices[];
}

export type TGetOverviewsResponse = IApiResponse<IGetOverviews>;

export const getOverviews = async (): Promise<TGetOverviewsResponse> => {
	const { data } = await apiClient.get(`${RESOURCE}`);

	return data;
};
