import { apiClient } from 'api/apiClient';
import type { IPayinObject } from 'api/resources/payin';

const RESOURCE = '/transaction';

export enum ETransactionType {
	CreditCard = 'CreditCard',
	ACH = 'ACH',
}

export enum ETransactionProcessor {
	Rainforest = 'Rainforest',
}

export const DEFAULT_PAYMENT_PROCESSOR = ETransactionProcessor.Rainforest;

export interface ITransactionsPayload {
	processor: 'Rainforest';
	type: ETransactionType;
	batchToken: string;
	processorTransactionId: string;
	totalToPay: number;
	untaggedObjects?: IPayinObject[];
}

interface ITransactionsParams {
	token: string;
	payload: ITransactionsPayload;
}

export const postTransactions = async ({
	token,
	payload,
}: ITransactionsParams) => {
	const omitPayload = {
		...payload,
	};

	// need untaggedObjects for an optimistic update; but not for the BE
	delete omitPayload.untaggedObjects;

	const { data } = await apiClient.post(`${RESOURCE}/${token}`, omitPayload);

	return data;
};
