import React from 'react';

export interface IModalContentProps {
	children: React.ReactNode;
	className?: string;
}

const ModalContent: React.FC<IModalContentProps> = ({
	children,
	className = '',
}) => {
	return (
		<div className={`flex-1 overflow-y-auto ${className}`}>{children}</div>
	);
};

export { ModalContent };
