import { apiClient } from 'api/apiClient';
import type { IApiResponse } from 'api/apiTypes';
import type { TPaymentMethods } from 'api/resources/dashboard';

const RESOURCE = '/invoices';

export interface IGetInvoicesParams {
	token: string;
}

export type TInvoiceStatuses = 'opened' | 'pending' | 'paid' | 'declined';

export interface IInvoices {
	id: string;
	title: string;
	pdf: string;
	total: number;
	totalPayment: number;
	amountDue: number;
	isOpen: boolean;
	paymentMethod: TPaymentMethods;
	date: string;
	itemsQuantity: number;
}

export interface IInvoiceStatusPayload {
	id: string;
	status: TInvoiceStatuses;
	paymentMethod?: TPaymentMethods;
}

export type TPostInvoiceStatusResponse = IApiResponse<Record<string, string>>;

export type TGetInvoicesResponse = IApiResponse<IInvoices[]>;

export const postInvoiceStatus = async (payload: IInvoiceStatusPayload[]) => {
	const { data } = await apiClient.patch(RESOURCE, {
		payload,
	});

	return data;
};

export const getInvoices = async (
	params: IGetInvoicesParams,
): Promise<TGetInvoicesResponse> => {
	const { token } = params;
	const { data } = await apiClient.get(`${RESOURCE}/${token}`);

	return data;
};

export const useGetInvoiceFile = (token: string) => {
	const openFile = (fileId: string, maxWidth = 800, maxHeight = 600) => {
		const url = `${process.env.PUBLIC_APP_API_BASE_URL}/file/${token}/${fileId}?maxWidth=${maxWidth}&maxHeight=${maxHeight}`;
		window.open(url, '_blank');
	};

	return { openFile };
};
