import React, { useEffect, useState } from 'react';
import { Link, Outlet, useSearchParams, useLocation } from 'react-router-dom';
import { Icon } from 'ui';
import 'app/App.css';
import 'react-toastify/dist/ReactToastify.css';
import { routes } from 'app/features/Routing/Routes';
import { useTheme } from 'contexts/ThemeContext';
import 'app/styles/fonts.css';
import { useAuth } from 'app/features/auth/useAuth';
import { motion } from 'framer-motion';
import { ToastContainer } from 'react-toastify';

interface INavItem {
	name: string;
	icon: string | undefined;
	link: string;
}

export const Layout = () => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token') || '';
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const location = useLocation();
	const { org } = useAuth();

	useEffect(() => {
		const primaryColor = org?.branding?.primary || '#f3f4f6';
		const secondaryColor = org?.branding?.secondary || '#111827';

		document.documentElement.style.setProperty('--primary-color', primaryColor);
		document.documentElement.style.setProperty(
			'--secondary-color',
			secondaryColor,
		);
	}, [org?.branding]);

	const { theme } = useTheme();

	const navItems: INavItem[] = [
		{
			name: routes.dashboard.name,
			icon: routes.dashboard.icon,
			link: `${routes.dashboard.path}?token=${token}`,
		},
		{
			name: routes.items.name,
			icon: routes.items.icon,
			link: `${routes.items.path}?token=${token}`,
		},
		{
			name: routes.proposals.name,
			icon: routes.proposals.icon,
			link: `${routes.proposals.path}?token=${token}`,
		},
		{
			name: routes.invoices.name,
			icon: routes.invoices.icon,
			link: `${routes.invoices.path}?token=${token}`,
		},
		{
			name: routes.forbidden.name,
			icon: routes.forbidden.icon,
			link: `${routes.forbidden.path}?token=${token}`,
		},
	];

	const navItemVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0 },
	};

	const defaultIcon = 'face-frown';

	const renderNavItem = (item: INavItem, index: number) => {
		const isActive =
			location.pathname.split('/')[1] === item.link.split('?')[0];

		return (
			<motion.div
				key={item.name}
				variants={navItemVariants}
				initial="hidden"
				animate="visible"
				transition={{ duration: 0.3, delay: index * 0.1 }}
			>
				<Link to={item.link} onClick={() => setIsMobileMenuOpen(false)}>
					<div
						className={`flex items-center w-full p-3 rounded-lg ${
							isActive ? 'bg-gray text-primary-badge' : 'hover:bg-gray-100'
						}`}
					>
						<Icon
							name={item.icon || defaultIcon}
							className="mr-4 fill-primary-badge"
						/>
						<span>{item.name}</span>
					</div>
				</Link>
			</motion.div>
		);
	};

	return (
		<div
			className={`${theme === 'dark' ? 'dark' : ''} flex flex-col h-screen md:h-fit lg:h-fit bg-background text-text`}
		>
			{/* Mobile Header */}
			<header className="md:hidden border-b border-gray-400 shadow-lg rounded-lg p-4 flex justify-between items-center sticky top-0 bg-white z-50">
				<div className="flex flex-row gap-2 items-center md:items-center">
					<div className="w-10 h-10 bg-primary-badge rounded-lg cursor-pointer">
						<img src={org?.branding?.logo} alt={org?.name} />
					</div>
					<span className="font-semibold text-primary-badge">{org?.name}</span>
				</div>
				<button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
					<Icon name="bars-2" className="text-gray-600" />
				</button>
			</header>

			{/* Mobile Menu */}
			{isMobileMenuOpen && (
				<div className="h-[550px] md:hidden fixed shadow-lg rounded-b-2xl inset-0 z-50 bg-background">
					<div className="flex flex-col h-full">
						<div className="flex justify-between items-center p-4 border-b border-gray-200">
							<div className="flex items-center space-x-2">
								<div className="w-10 h-10 bg-primary-badge rounded-lg flex items-center justify-center">
									<img src={org?.branding?.logo} alt={org?.name} />
								</div>
								<span className="font-semibold text-primary-badge">
									{org?.name}
								</span>
							</div>
							<button onClick={() => setIsMobileMenuOpen(false)}>
								<Icon name="x-mark" className="fill-primary-badge" />
							</button>
						</div>
						<nav className="flex-1 overflow-y-auto p-4">
							<div className="bg-gray-100 rounded-lg p-4 mb-4">
								<Link
									to={`${routes.dashboard.path}?token=${token}`}
									className="flex items-center text-primary-badge"
									onClick={() => setIsMobileMenuOpen(false)}
								>
									<Icon
										name={routes.dashboard.icon || defaultIcon}
										className="mr-4 fill-primary-badge"
										size="sm"
									/>
									<span>{routes.dashboard.name}</span>
								</Link>
							</div>
							<hr className="border-gray-200 my-2" />
							{navItems.slice(1).map(renderNavItem)}
						</nav>
						<div className="flex flex-row-reverse px-6 py-6 items-end border-t border-gray-200">
							<span className="text-xs text-gray-500">Version 1.0</span>
						</div>
					</div>
				</div>
			)}

			<div className="flex flex-1">
				{/* Desktop Sidebar */}
				<aside className="hidden md:flex md:overflow-hidden flex-col w-64 p-6 gap-8">
					<div className="flex items-center gap-4">
						<div className="flex items-center gap-2">
							<div className="w-10 h-10 bg-primary-badge rounded-lg cursor-pointer">
								<img src={org?.branding?.logo} alt={org?.name} />
							</div>
							<span className="font-semibold text-primary-badge">
								{org?.name}
							</span>
						</div>
					</div>
					<nav className="space-y-4">
						<hr />
						<div className="py-0.25">
							{navItems.map((item, index) => renderNavItem(item, index))}
						</div>
						<hr />
						<div className="flex flex-row py-6">
							<span className="text-xs text-gray-500">Version 1.0</span>
						</div>
					</nav>
				</aside>

				{/* Main Content */}
				<div className="w-full">
					<Outlet />
					<ToastContainer position="bottom-right" />
				</div>
			</div>
		</div>
	);
};
