import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from 'ui';
import { motion } from 'framer-motion';
import type { INavItem } from '../Layout';
import { routes } from 'app/features/Routing/Routes';

interface INavItemsWrapperProps {
	navItems: INavItem[];
	setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const hrTag = <hr className="border-border my-2" />;

const NavItemsWrapper = ({
	navItems,
	setIsMobileMenuOpen,
}: INavItemsWrapperProps) => {
	const navItemVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0 },
	};

	const defaultIcon = 'face-frown';
	const location = useLocation();

	const renderNavItem = (item: INavItem, index: number) => {
		const isActive =
			location.pathname?.split('/')[1] === item.link?.split('?')[0];

		const isDashboard = item.name === routes.dashboard.name;
		const itemClasses = `flex items-center w-full p-4 rounded-lg ${
			isActive
				? 'bg-backgroundGray text-primary-badge'
				: 'hover:bg-backgroundGray'
		} ${isDashboard ? 'py-4 my-4' : ''}`;

		return (
			<motion.div
				key={item.name}
				variants={navItemVariants}
				initial="hidden"
				animate="visible"
				transition={{ duration: 0.3, delay: index * 0.1 }}
			>
				{isDashboard && hrTag}
				<Link to={item.link} onClick={() => setIsMobileMenuOpen(false)}>
					<div className={itemClasses}>
						<Icon
							name={item.icon || defaultIcon}
							className="mr-4 fill-primary-badge"
						/>
						<span>{item.name}</span>
					</div>
				</Link>
				{isDashboard && hrTag}
			</motion.div>
		);
	};

	return (
		<nav>
			{navItems.map((item, index) => renderNavItem(item, index))}
			{hrTag}
			<div className="flex flex-row py-6">
				<span className="text-xs text-text p-4">Version 1.0</span>
			</div>
		</nav>
	);
};

export { NavItemsWrapper };
