import { useGetSuspenseDemo } from 'api/hooks/useGetSuspenseDemo';
import React from 'react';

export const DemosSuspense = () => {
	const { data } = useGetSuspenseDemo({
		delay: 3500,
	});

	return (
		<>
			<h2 className="text-lg pt-4 md:text-xl font-normal mb-4">
				Fetch Demo with SuspenseWrapper
			</h2>
			<p className="mb-4">
				SuspenseWrapper can be used with useSuspenseQuery. Make sure to have at
				least one fetch request using useSuspenseQuery for it to work.
			</p>
			<p>message: {data?.data?.message}</p>
		</>
	);
};

DemosSuspense.displayName = 'DemosSuspense';
