import { type IUI } from 'app/ui/types/core';
import React from 'react';
import { Button, Icon } from 'ui';

export interface IModalCloseButtonProps extends IUI {
	onClick: () => void;
	'aria-label'?: string;
}

const ModalCloseButton: React.FC<IModalCloseButtonProps> = ({
	onClick,
	'aria-label': ariaLabel = 'Close modal',
	className = '',
}) => {
	return (
		<Button
			onClick={onClick}
			variant="ghost"
			className={`!p-0 hover:bg-gray-200 transition-colors hover:cursor-pointer ${className}`}
			aria-label={ariaLabel}
		>
			<Icon name="x-mark" aria-hidden="true" className="fill-primary-badge" />
		</Button>
	);
};

export { ModalCloseButton };
