import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'api/queryClient';
import { type TGetDashboardResponse } from 'api/resources/dashboard';
import type { TGetInvoicesResponse } from 'api/resources/invoices';
import { type TGetProposalResponse } from 'api/resources/proposals';
import type { ITransactionsPayload } from 'api/resources/transactions';
import { postTransactions } from 'api/resources/transactions';
import { ECacheKeys } from 'cache/CacheKeys';
import { useLocation } from 'react-router-dom';

export const usePostTransactions = (token: string) => {
	const location = useLocation();
	const { pathname } = location;

	return useMutation({
		mutationFn: (payload: ITransactionsPayload) =>
			postTransactions({ token, payload }),
		onMutate: async (payload) => {
			// Cancel any outgoing refetches
			// (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries({ queryKey: [ECacheKeys.Proposals] });

			try {
				// Optimistically update to the new value on /proposals only
				if (pathname === '/proposals') {
					queryClient.setQueryData(
						[ECacheKeys.Proposals],
						(proposalsPayload: TGetProposalResponse) => {
							const optimisticProposals = proposalsPayload?.data?.map(
								(proposal) => {
									const ids =
										payload?.untaggedObjects?.map(
											(untaggedObject) => untaggedObject.objectId,
										) || [];

									return {
										...proposal,
										...(ids?.indexOf(proposal.id) !== -1 && {
											alreadyDeposited: proposal.deposit,
											isCompleted: true,
										}),
									};
								},
							);

							return {
								...proposalsPayload,
								data: optimisticProposals,
							};
						},
					);
				}

				if (pathname === '/invoices') {
					queryClient.setQueryData(
						[ECacheKeys.Invoices],
						(invoicesPayload: TGetInvoicesResponse) => {
							// We optimistically update the invoice to show it as Paid

							const updatedInvoices = invoicesPayload.data?.map((invoice) => {
								const isInvoiceBeingPaid = payload?.untaggedObjects?.find(
									(uo) => uo.objectId === invoice.id,
								);

								if (isInvoiceBeingPaid) {
									return {
										...invoice,
										isOpen: false,
										amountDue: 0,
										totalPayment: invoice.total,
									};
								}
								return invoice;
							});

							return {
								...invoicesPayload,
								data: updatedInvoices,
							};
						},
					);
				}

				if (pathname === '/dashboard') {
					queryClient.setQueryData(
						[ECacheKeys.Dashboard],
						(oldData: TGetDashboardResponse) => {
							return {
								...oldData,
								data: {
									...oldData?.data,
									overview: {
										...oldData.data?.overview,
										retainer: 0,
									},
								},
							};
						},
					);
				}
			} catch (err) {
				// Catching errors on a log to help debug live
				// as these errors were hard to trace
				// eslint-disable-next-line no-console
				console.log('Error:', err);
			}

			// Return a context object with the snapshotted value
			return payload;
		},

		// Always refetch after error or success:
		onSettled: () => {
			// It takes a while for the transaction to be processed. We do
			// an optimistic update and then wait a few seconds before
			// invalidating and refetching again.
			setTimeout(async () => {
				// I can't figure out how to invalidate all three keys
				// in one single call, so I have to call separately
				await queryClient.invalidateQueries({
					queryKey: [ECacheKeys.Invoices],
				});
				await queryClient.invalidateQueries({
					queryKey: [ECacheKeys.Dashboard],
				});
				await queryClient.invalidateQueries({
					queryKey: [ECacheKeys.Proposals],
				});
			}, 20000);
		},
	});
};
