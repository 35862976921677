import React, { useState, useEffect, forwardRef } from 'react';
import cn from 'classnames';
import type { IUI } from 'ui/types/core';
import { ImageSkeleton } from './ImagePartials/ImageSkeleton';

export interface IImageProps extends IUI {
	src: string;
	alt: string;
	width?: number | string;
	height?: number | string;
	objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
	lazy?: boolean;
	fallbackSrc?: string;
	onLoad?: () => void;
	onError?: () => void;
	onClick?: () => void;
}

const Image = forwardRef<HTMLImageElement, IImageProps>(
	(
		{
			src,
			alt,
			width,
			height,
			objectFit = 'cover',
			lazy = true,
			fallbackSrc,
			onLoad,
			onError,
			onClick,
			className,
			id,
			'data-testid': dataTestId,
		},
		ref,
	) => {
		const [imgSrc, setImgSrc] = useState(src);
		const [isLoading, setIsLoading] = useState(true);
		const [hasError, setHasError] = useState(false);

		useEffect(() => {
			setImgSrc(src);
			setIsLoading(true);
			setHasError(false);
		}, [src]);

		const handleLoad = () => {
			setIsLoading(false);
			onLoad?.();
		};

		const handleError = () => {
			setHasError(true);
			setIsLoading(false);
			if (fallbackSrc && imgSrc !== fallbackSrc) {
				setImgSrc(fallbackSrc);
			} else {
				onError?.();
			}
		};

		return (
			<div
				className={cn('relative overflow-hidden', className)}
				style={width || height ? { width, height } : undefined}
			>
				{isLoading && <ImageSkeleton width={width} height={height} />}
				<img
					ref={ref}
					src={imgSrc}
					alt={alt}
					width={width || undefined}
					height={height || undefined}
					loading={lazy ? 'lazy' : undefined}
					onLoad={handleLoad}
					onError={handleError}
					onClick={onClick}
					className={cn(
						'w-full h-full transition-opacity duration-300',
						{
							'opacity-0': isLoading,
							'opacity-100': !isLoading,
						},
						objectFit && `object-${objectFit}`,
						className,
					)}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...(id ? { id } : {})}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...(dataTestId ? { 'data-testid': dataTestId } : {})}
				/>
				{hasError && (
					<div
						className="absolute inset-0 flex items-center justify-center bg-red-200"
						style={{ width, height }}
					>
						<p>Error loading image</p>
					</div>
				)}
			</div>
		);
	},
);

Image.displayName = 'Image';

export { Image };
