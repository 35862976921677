import React, {
	createContext,
	useContext,
	useState,
	useLayoutEffect,
} from 'react';

export type TTheme = 'light' | 'dark' | '';

interface IThemeContext {
	theme: TTheme;
	primaryColor: string;
	secondaryColor: string;
	toggleTheme: () => void;
	setColors: (primaryColor: string, secondaryColor: string) => void;
}

export const ThemeContext = createContext<IThemeContext | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [theme, setTheme] = useState<TTheme>('');
	const [primaryColor, setPrimaryColor] = useState('#1E9773');
	const [secondaryColor, setSecondaryColor] = useState('#3338A1');

	// Load theme and colors from localStorage using useLayoutEffect to avoid UI flash
	useLayoutEffect(() => {
		const savedTheme = localStorage.getItem('theme') as TTheme | null;
		if (savedTheme) setTheme(savedTheme);

		const savedPrimaryColor = localStorage.getItem('primary-color');
		const savedSecondaryColor = localStorage.getItem('secondary-color');

		if (savedPrimaryColor) setPrimaryColor(savedPrimaryColor);
		if (savedSecondaryColor) setSecondaryColor(savedSecondaryColor);
	}, []);

	// Store theme in localStorage whenever it changes
	useLayoutEffect(() => {
		localStorage.setItem('theme', theme);
	}, [theme]);

	const toggleTheme = () => {
		setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
	};

	const setColors = (newPrimaryColor: string, newSecondaryColor: string) => {
		setPrimaryColor(newPrimaryColor);
		setSecondaryColor(newSecondaryColor);
		localStorage.setItem('primary-color', newPrimaryColor);
		localStorage.setItem('secondary-color', newSecondaryColor);
	};

	return (
		<ThemeContext.Provider
			value={{
				theme,
				toggleTheme,
				primaryColor,
				secondaryColor,
				setColors,
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
};

// Custom hook to access theme context
export const useTheme = () => {
	const context = useContext(ThemeContext);
	if (context === undefined) {
		throw new Error('useTheme must be used within a ThemeProvider');
	}
	return context;
};
