import { apiClient } from 'api/apiClient';
import type { IApiResponse } from 'api/apiTypes';

const RESOURCE = '/auth';

export interface IProject {
	clientName: string;
	code: string;
	id: number;
	name: string;
}

export interface IOrg {
	name: string;
	id: string;
	branding?: {
		primary: string;
		secondary?: string;
		logo?: string;
	};
	checkPaymentInfo: {
		name: string;
		address1: string;
		address2: string;
		address3: string;
		city: string;
		state: string;
		zipCode: string;
		hasCheckInformation: boolean;
		designerCode: string;
	};
	itemsApproval: boolean;
	messageRecipient: string;
	proposalsApproval: boolean;
	proposalsRequireSignature: boolean;
	showProjectBudget: boolean;
	project: IProject;
}

export interface IGetAuth {
	id: string;
	name: string;
	branding: {
		primary: string;
		secondary?: string;
		logo?: string;
	};
	checkPaymentInfo: {
		name: string;
		address1: string;
		address2: string;
		address3: string;
		city: string;
		state: string;
		zipCode: string;
		hasCheckInformation: boolean;
		designerCode: string;
	};
}

export type TGetAuthCheckResponse = IApiResponse<IOrg>;

export interface IGetAuthParams {
	token: string;
}

export const getAuthCheck = async (
	params: IGetAuthParams,
): Promise<TGetAuthCheckResponse> => {
	const { token } = params;
	const { data } = await apiClient.get(`${RESOURCE}/${token}`);

	return data;
};
