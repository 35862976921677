import type { MutableRefObject } from 'react';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useOverrides } from 'app/hooks/useOverrides';
import {
	ModalCloseButton,
	type IModalCloseButtonProps,
} from './ModalPartials/ModalCloseButton';
import {
	ModalContent,
	type IModalContentProps,
} from './ModalPartials/ModalContent';
import { ModalTitle, type IModalTitleProps } from './ModalPartials/ModalTitle';
import type { IUI } from 'ui/types/core';
import { useOutsideClick } from 'app/hooks/useOutsideClick';

type TModalPartials = {
	CloseButton: React.FC<IModalCloseButtonProps>;
	Content: React.FC<IModalContentProps>;
	Title: React.FC<IModalTitleProps>;
};

interface IModalOverrides {
	CloseButton?: React.FC<IModalCloseButtonProps>;
	Content?: React.FC<IModalContentProps>;
	Title?: React.FC<IModalTitleProps>;
}

export interface IModalProps extends IUI {
	isOpen: boolean;
	onClose?: () => void;
	title?: string;
	subtitle?: string;
	overrides?: IModalOverrides;
	showCloseButton?: boolean;
}

const Modal = ({
	isOpen,
	onClose,
	title,
	subtitle,
	children,
	overrides,
	showCloseButton = true,
	className,
	'data-testid': dataTestId,
}: IModalProps) => {
	const modalContainerRef = useRef<HTMLDivElement>(null);
	const modalRef = useOutsideClick(() => {
		if (isOpen) onClose?.();
	}) as MutableRefObject<HTMLDivElement | null>;

	const defaultModalPartials: TModalPartials = {
		CloseButton: ModalCloseButton,
		Content: ModalContent,
		Title: ModalTitle,
	};

	const partials = useOverrides<TModalPartials>(
		defaultModalPartials,
		overrides,
	);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape' && isOpen) {
				onClose?.();
			}
		};

		const modalElement = modalContainerRef.current;

		modalElement?.addEventListener('keydown', handleKeyDown);

		if (isOpen) {
			modalElement?.focus();
		}

		return () => {
			modalElement?.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen, onClose]);

	if (!isOpen) return null;

	const CloseButtonComponent = partials.CloseButton;
	const ContentComponent = partials.Content;
	const TitleComponent = partials.Title;

	return ReactDOM.createPortal(
		<div
			ref={modalContainerRef}
			tabIndex={-1}
			className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50"
		>
			<div
				ref={modalRef}
				className={`dmui-modal bg-background rounded-xl shadow-lg p-4 pt-2 relative border border-gray w-full  max-w-xl max-h-[95vh] flex flex-col overflow-hidden ${className || ''}`}
				onClick={(e) => e.stopPropagation()}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(dataTestId ? { 'data-testid': dataTestId } : {})}
			>
				<div className="relative mb-4 flex items-center justify-center">
					{title && <TitleComponent title={title} subtitle={subtitle} />}
					<div className="absolute top-0 right-0">
						{showCloseButton && (
							<CloseButtonComponent
								onClick={() => onClose?.()}
								aria-label="Close modal"
							/>
						)}
					</div>
				</div>

				<ContentComponent>{children}</ContentComponent>
			</div>
		</div>,
		document.body,
	);
};

export { Modal };