import { useSuspenseQuery } from '@tanstack/react-query';
import type {
	TGetDashboardResponse,
	IGetDashboardParams,
} from 'api/resources/dashboard';
import { getDashboard } from 'api/resources/dashboard';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetDashboard = (params: IGetDashboardParams) => {
	return useSuspenseQuery<TGetDashboardResponse>({
		queryKey: [ECacheKeys.Dashboard],
		queryFn: () => getDashboard(params),
	});
};
