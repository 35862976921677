import React, { forwardRef } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import cn from 'classnames';
import type { IUI, TSize } from 'ui/types/core';

export const badgeVariants = cva(
	'inline-flex items-center justify-center rounded-full font-semibold text-white ',
	{
		variants: {
			variant: {
				default: 'bg-gray-500',
				primary: 'bg-blue-500',
				secondary: 'bg-green-500',
				danger: 'bg-red-500',
				warning: 'bg-yellow-500',
				info: 'bg-teal-500',
				outline: 'bg-transparent border border-gray-500',
				ghost: 'text-black',
			},
			size: {
				xs: 'px-.5 py-1 text-xs',
				sm: 'px-.5 py-1 text-sm',
				md: 'px-1 py-1 text-md',
				lg: 'px-2 py-1 text-lg',
				inherit: 'px-2 py-1',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'inherit',
		},
	},
);

export type TBadgeVariant =
	| 'default'
	| 'primary'
	| 'secondary'
	| 'danger'
	| 'warning'
	| 'info'
	| 'outline'
	| 'ghost';

interface IBadgeProps
	extends React.HTMLAttributes<HTMLSpanElement>,
		VariantProps<typeof badgeVariants>,
		IUI {
	variant?: TBadgeVariant;
	size?: TSize;
	'data-testid'?: string;
}

const Badge = forwardRef<HTMLSpanElement, IBadgeProps>(
	(
		{
			className,
			variant = 'default',
			size = 'inherit',
			children,
			'data-testid': dataTestId,
		},
		ref,
	) => {
		return (
			<span
				ref={ref}
				className={cn(
					'dmui-badge',
					badgeVariants({ variant, size }),
					className,
				)}
				data-testid={dataTestId}
			>
				<span className="px-2">{children}</span>
			</span>
		);
	},
);

Badge.displayName = 'Badge';

export { Badge };
export { type IBadgeProps };
