import { apiClient } from 'api/apiClient';
import type { IApiResponse } from 'api/apiTypes';

const RESOURCE = '/payin';

export interface IPayinObject {
	objectId: number | string;
	objectType: 'proposal' | 'invoice' | 'projectRetainer';
}

interface IPayinParams {
	token: string;
	payload: IPayinObject[];
}

export type TPayinTransactionType = 'CreditCard' | 'ACH' | 'VALIDATED_ACH';

interface IPayinEstimate {
	feeAdditionalFixedAmount: number;
	feeAmountTotal: number;
	feeBeforeTaxes: number;
	feePercentage: number;
	feeSalesTax: number;
	subtotal: number;
	total: number;
	transactionType: TPayinTransactionType;
}

export interface IPayinOptions {
	batchToken: string;
	passFeesToClient: boolean;
	rainforestPayinConfigId: string;
	estimate: IPayinEstimate;
}

export interface IPayin {
	batchToken: string;
	payinOptions: IPayinOptions[];
	rainforestSessionKey: string;
	total: number;
	usePlaid: boolean;
}

export type TPostPayinResponse = IApiResponse<IPayin>;

export const postPayin = async ({
	token,
	payload,
}: IPayinParams): Promise<TPostPayinResponse> => {
	const { data } = await apiClient.post(`${RESOURCE}/${token}`, payload);

	return data;
};
