import React from 'react';

export const Forbidden = () => {
	return (
		<div>
			<h1 style={{ color: 'red' }}>Forbidden!</h1>
			<p>Your token may have expired.</p>
		</div>
	);
};
