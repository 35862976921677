import Axios from 'axios';
import type { AxiosRequestConfig } from 'axios';
import { getCookie } from 'utils/cookies/cookies';
import { v4 } from 'uuid';

// Declare the baseURL once for our API and set it in Axios
export const axios = Axios.create({
	baseURL: `${process.env.PUBLIC_APP_API_BASE_URL}`,
});

// Set auth headers once for all requests
axios.interceptors.request.use(async (config) => {
	const token = getCookie('dmAuthToken');
	const instanceId = getCookie('dmInstanceId') || '';

	config.headers.Accept = 'application/json';
	config.headers['X-InstanceId'] = instanceId;
	config.headers.Authorization = `Bearer ${token}`;
	config.headers['X-Correlation-Id'] = v4();
	// config.headers['X-AppVersion'] = process.env.REACT_APP_VERSION || 'not set';
	config.headers['X-ApiClientId'] = '42919f39-4705-447e-b93f-de5373c57b62';

	return config;
});

// The purpose of this object is to abstract away our fetching functions
// and make it easy in the future to swap out Axios for another library
// if we ever need it to. Keep in mind that we intentionally do not
// pass the `config` object directly to the Axios functions, as it may  not
// be compatible with other libraries.
export const apiClient = {
	get: (route: string, config?: AxiosRequestConfig) =>
		axios.get(route, {
			signal: config?.signal,
			params: config?.params,
		}),
	post: (route: string, data: unknown, config?: AxiosRequestConfig) =>
		axios.post(route, data, {
			signal: config?.signal,
		}),
	patch: (route: string, data: unknown, config?: AxiosRequestConfig) =>
		axios.patch(route, data, {
			signal: config?.signal,
		}),
	put: (route: string, data: unknown, config?: AxiosRequestConfig) =>
		axios.put(route, data, {
			signal: config?.signal,
		}),
	delete: (route: string, config?: AxiosRequestConfig) =>
		axios.delete(route, {
			signal: config?.signal,
		}),
};
