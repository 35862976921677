import { useRef, useLayoutEffect, useEffect } from 'react';

export const useOutsideClick = (cb: (e?: Event) => void) => {
	const ref = useRef<HTMLElement | null>(null);
	const refCb = useRef(cb);

	useLayoutEffect(() => {
		refCb.current = cb;
	});

	useEffect(() => {
		const handler = (e: Event) => {
			const element = ref.current;

			if (!element?.contains(e.target as Node)) {
				refCb.current(e);
			}
		};

		document.addEventListener('mousedown', handler);
		document.addEventListener('touchstart', handler);

		return () => {
			document.removeEventListener('mousedown', handler);
			document.removeEventListener('touchstart', handler);
		};
	}, []);

	return ref;
};
