import React, { useState } from 'react';
import type { IPayinObject, TPayinTransactionType } from 'api/resources/payin';
import { type IPayin } from 'api/resources/payin';
import { PaymentsApproved } from 'app/features/Payments/partials/PaymentsApproved';
import { PaymentsMailCheck } from 'app/features/Payments/partials/PaymentsMailCheck';
import { PaymentsModalTitle } from 'app/features/Payments/partials/PaymentsModalTitle';
import { PaymentsRainforest } from 'app/features/Payments/partials/PaymentsRainforest';
import { Modal } from 'app/ui';
import { usePostTransactions } from 'api/hooks/usePostTransactions';
import { useSearchParams } from 'react-router-dom';
import type { ETransactionType } from 'api/resources/transactions';
import { DEFAULT_PAYMENT_PROCESSOR } from 'api/resources/transactions';

export enum EPaymentsFormStatus {
	Loading,
	Idle,
	Submitting,
	Submitted,
	Failure,
	MailCheck,
}
interface IPaymentsModal {
	isOpen: boolean;
	onClose: () => void;
	onStatusChange: (status: EPaymentsFormStatus) => void;
	payinInfo: IPayin;
	paymentsFormStatus: EPaymentsFormStatus;
	payinItems: IPayinObject[];
}

export const PaymentsModal = ({
	isOpen,
	onClose,
	onStatusChange,
	payinInfo,
	paymentsFormStatus,
	payinItems,
}: IPaymentsModal) => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token') || '';
	const [confirmationId, setConfirmationId] = useState('');
	const [totalPaid, setTotalPaid] = useState(0);
	const [checkTotal, setCheckTotal] = useState(0);
	const { mutate: postTransactions } = usePostTransactions(token);

	const onPayOnline = () => {
		onStatusChange(EPaymentsFormStatus.Idle);
	};

	const onMailCheck = (amountToPay: number) => {
		setCheckTotal(amountToPay);
		onStatusChange(EPaymentsFormStatus.MailCheck);
	};

	const onSubmit = async () => {
		onStatusChange(EPaymentsFormStatus.Submitting);
	};

	const onSuccess = async (
		confirmationId: string,
		methodType: TPayinTransactionType,
	) => {
		const paymentMethodUsed = payinInfo.payinOptions.find((opts) => {
			return opts.estimate.transactionType === methodType;
		});

		await postTransactions({
			processor: DEFAULT_PAYMENT_PROCESSOR,
			type: paymentMethodUsed?.estimate.transactionType as ETransactionType,
			batchToken: payinInfo.batchToken,
			processorTransactionId: confirmationId,
			// Per the BE, we use `payinInfo.total` in `totalToPay` (not amountPaid)
			// https://designmanager.atlassian.net/browse/WEBPRO-5820?focusedCommentId=26695
			// However, we use `amountPaid` in `setTotalPaid` for the UI for the confirmation message
			totalToPay: payinInfo.total,
			untaggedObjects: payinItems,
		});

		setConfirmationId(confirmationId);
		setTotalPaid(paymentMethodUsed?.estimate.total || 0);
		onStatusChange(EPaymentsFormStatus.Submitted);
	};

	const onInvalid = async () => {
		onStatusChange(EPaymentsFormStatus.Idle);
	};

	const onDeclined = async () => {
		onStatusChange(EPaymentsFormStatus.Idle);
	};

	return (
		<Modal
			isOpen={isOpen}
			overrides={{
				Title: PaymentsModalTitle,
			}}
			onClose={onClose}
			showCloseButton={true}
		>
			{(paymentsFormStatus === EPaymentsFormStatus.Idle ||
				paymentsFormStatus === EPaymentsFormStatus.Submitting) && (
				<PaymentsRainforest
					payinInfo={payinInfo}
					onSubmit={onSubmit}
					onSuccess={onSuccess}
					onInvalid={onInvalid}
					onDeclined={onDeclined}
					onMailCheck={onMailCheck}
					formStatus={paymentsFormStatus}
				/>
			)}

			{paymentsFormStatus === EPaymentsFormStatus.Submitted && (
				<PaymentsApproved
					totalPaid={totalPaid}
					confirmationId={confirmationId}
				/>
			)}

			{paymentsFormStatus === EPaymentsFormStatus.MailCheck && (
				<PaymentsMailCheck onPayOnline={onPayOnline} total={checkTotal} />
			)}
		</Modal>
	);
};
