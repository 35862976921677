import React from 'react';
import { useTheme } from 'contexts/ThemeContext';
import { Button, Icon } from 'ui';

export const ThemeToggle = () => {
	const { theme, toggleTheme } = useTheme();

	return (
		<Button variant="white" onClick={toggleTheme}>
			<Icon
				name={theme === 'light' ? 'moon' : 'sun'}
				size="sm"
				className="fill-fill !fill-[--primary-color]"
				title={`Toggle ${theme === 'light' ? 'Dark' : 'Light'} Mode`}
			/>
		</Button>
	);
};
