import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'app/features/Routing/Routes';

export const Proposals = () => {
	return (
		<>
			<h1>Proposals!</h1>
			<p>Proposals list page.</p>
			<Link to={routes.proposalDetail.path}>Go to Proposal Detail</Link>
		</>
	);
};

Proposals.displayName = 'Proposals';
