import { Loader } from 'app/ui';
import type { ReactNode, FC } from 'react';
import React from 'react';
import { Suspense } from 'react';

interface ISuspenseWrapper {
	children: ReactNode;
	fallback?: ReactNode;
}

const DefaultFallback: FC = () => (
	<div className="flex min-h-12 relative justify-center items-center">
		<Loader />
	</div>
);

export const SuspenseWrapper = ({
	children,
	fallback = <DefaultFallback />,
}: ISuspenseWrapper) => {
	return (
		<Suspense fallback={fallback}>
			<div className="animate-fade-in">{children}</div>
		</Suspense>
	);
};
