import React from 'react';
import {
	isRouteErrorResponse,
	Link,
	useRouteError,
	useSearchParams,
} from 'react-router-dom';
import { Icon, Button } from 'ui';
import { motion } from 'framer-motion';
import { routes } from 'app/features/Routing/Routes';

export const NotFound = () => {
	const error = useRouteError();
	let errorMessage: string;

	const [searchParams] = useSearchParams();
	const token = searchParams.get('token') || '';

	const tokenExists = !!token;

	if (isRouteErrorResponse(error)) {
		// error is type `ErrorResponse`
		errorMessage = error.data || error.statusText;
	} else if (error instanceof Error) {
		errorMessage = error.message;
	} else if (typeof error === 'string') {
		errorMessage = error;
	} else {
		errorMessage = 'Unknown error';
	}

	return (
		<div
			id="error-page"
			className="bg-background flex justify-center items-center lg:items-center min-h-screen rounded-lg"
		>
			<motion.div
				initial={{ opacity: 0, scale: 0.8 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{ duration: 0.5 }}
				className="bg-background"
			>
				<div className="gap-1 flex flex-col items-center text-center">
					<motion.div
						initial={{ y: -30, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ delay: 0.2, duration: 0.5 }}
						className="flex items-center justify-center"
					>
						<motion.div
							animate={{
								rotate: [0, 10, -10, 10, 0],
							}}
							transition={{
								repeat: Infinity,
								repeatType: 'loop',
								duration: 1,
								ease: 'easeInOut',
							}}
						>
							<Icon
								name="hand-raised"
								size="lg"
								className="text-primary mb-2 fill-red-600"
							/>
						</motion.div>
					</motion.div>
					<motion.h4
						initial={{ x: -30, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						transition={{ delay: 0.3, duration: 0.5 }}
						className="text-xl text-text font-dm-sans text-gray-700"
					>
						Page Not Found
					</motion.h4>
					<motion.p
						initial={{ x: 30, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						transition={{ delay: 0.4, duration: 0.5 }}
						className="text-sm text-text text-gray-500"
					>
						{errorMessage}
					</motion.p>
					{tokenExists && (
						<motion.div
							initial={{ y: 30, opacity: 0 }}
							animate={{ y: 0, opacity: 1 }}
							transition={{ delay: 0.5, duration: 0.5 }}
						>
							<Button
								className="mt-2"
								variant="primary"
								onClick={() => window.location.reload()}
							>
								<Link to={`${routes.dashboard.path}?token=${token}`}>
									Go Home
								</Link>
							</Button>
						</motion.div>
					)}
				</div>
			</motion.div>
		</div>
	);
};
