import { apiClient } from 'api/apiClient';
import type { IApiResponse } from 'api/apiTypes';

const RESOURCE = '/demos';

export type TPaymentProcessor = 'rainforest' | 'stripe';
export type TPaymentMethods = 'CARD' | 'ACH';

export interface IMetaRainforest {
	sessionKey: string;
	payinConfigIds: {
		ACH: string;
		CARD: string;
	};
}

export interface IPayments {
	processor: TPaymentProcessor;
	methods: TPaymentMethods[];
	meta: {
		rainforest?: IMetaRainforest;
		stripe?: object;
	};
}

export interface IDemos {
	message: string;
	payments: IPayments;
}

export interface IGetDemosParams {
	delay: number;
}

export interface IPostMutationDemoPayload {
	type: string;
}

export type TGetDemosResponse = IApiResponse<IDemos>;
export type TPostMutationDemoResponse = IApiResponse<Record<string, string>>;

export const getDemos = async (
	params: IGetDemosParams,
): Promise<TGetDemosResponse> => {
	const { data } = await apiClient.get(`${RESOURCE}`, {
		params,
	});

	return data;
};

export const postMutationDemo = async (
	payload: IPostMutationDemoPayload,
): Promise<TPostMutationDemoResponse> => {
	const { data } = await apiClient.post(`${RESOURCE}`, {
		payload,
	});

	return data;
};
