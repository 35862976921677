import { Loader } from 'app/ui';
import type { ReactNode } from 'react';
import React from 'react';
import { Suspense } from 'react';

interface ISuspenseWrapper {
	children: ReactNode;
	fallback?: ReactNode;
}

interface IDefaultFallbackProps {
	className?: string;
}

export const DefaultFallback = ({ className }: IDefaultFallbackProps) => (
	<span
		className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${className}`}
	>
		<Loader />
	</span>
);

export const SuspenseWrapper = ({
	children,
	fallback = <DefaultFallback />,
}: ISuspenseWrapper) => {
	return (
		<Suspense fallback={fallback}>
			<div className="animate-fade-in">{children}</div>
		</Suspense>
	);
};

SuspenseWrapper.displayName = 'SuspenseWrapper';
