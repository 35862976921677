import React from 'react';
import { Button, ButtonGroup, Icon } from 'ui';
import { motion, AnimatePresence } from 'framer-motion';
import type { TItemStatuses } from 'api/resources/items';
import { type IItem } from 'api/resources/items';
import { EStatus } from 'app/ui/types/core';

interface IItemActionHeaderProps {
	items: IItem[];
	filter: TItemStatuses | 'all';
	setFilter: (filter: TItemStatuses | 'all') => void;
	isFilterDropdownOpen: boolean;
	setIsFilterDropdownOpen: (isOpen: boolean) => void;
}

export const ItemActionHeader = ({
	items,
	filter,
	setFilter,
	isFilterDropdownOpen,
	setIsFilterDropdownOpen,
}: IItemActionHeaderProps) => {
	const filterDropdownVariants = {
		hidden: { opacity: 0, y: -10 },
		visible: { opacity: 1, y: 0, transition: { duration: 0.2 } },
	};

	const approvedCount = items.filter(
		(item) => item.status === EStatus.Approved,
	).length;
	const declinedCount = items.filter(
		(item) => item.status === EStatus.Rejected,
	).length;

	const pendingCount = items.filter(
		(item) => item.status === EStatus.NotSet,
	).length;

	return (
		<div className="flex items-center space-x-4">
			<div className="hidden lg:block">
				<ButtonGroup
					toggle
					activeId={filter}
					onActiveChange={(activeId) => setFilter(activeId as TItemStatuses)}
					aria-label="Filter items"
					variant="white"
				>
					<Button id="all" className="rounded-l-lg rounded-r-none" size="sm">
						<span className="text-gray-900 mr-2">All</span>
						<div className="rounded-full text-xs bg-[--primary-color] text-white px-1 py-3 shrink-0 grow-0 leading-[.1] h-[24px] w-[24px]">
							{items.length}
						</div>
					</Button>
					<Button id={EStatus.Approved} className="rounded-none" size="sm">
						<span className="text-gray-900 mr-2">Approved</span>
						<div className="rounded-full text-xs bg-[--primary-color] text-white px-1 py-3 shrink-0 grow-0 leading-[.1] h-[24px] w-[24px]">
							{approvedCount}
						</div>
					</Button>
					<Button id={EStatus.NotSet} className="rounded-none" size="sm">
						<span className="text-gray-900 mr-2">Pending</span>
						<div className="rounded-full text-xs bg-[--primary-color] text-white px-1 py-3 shrink-0 grow-0 leading-[.1] h-[24px] w-[24px]">
							{pendingCount}
						</div>
					</Button>
					<Button
						id={EStatus.Rejected}
						className="rounded-r-lg rounded-l-none"
						size="sm"
					>
						<span className="text-gray-900 mr-2">Declined</span>
						<div className="rounded-full text-xs bg-[--primary-color] text-white px-1 py-3 shrink-0 grow-0 leading-[.1] h-[24px] w-[24px]">
							{declinedCount}
						</div>
					</Button>
				</ButtonGroup>
			</div>
			<div className="flex items-center space-x-2">
				<div className="relative">
					<div className="lg:hidden">
						<Button
							variant="ghost"
							size="sm"
							onClick={() => setIsFilterDropdownOpen(!isFilterDropdownOpen)}
							aria-label="Open filter options"
						>
							<Icon
								name="adjustments-horizontal"
								size="sm"
								className="fill-primary-badge"
							/>
						</Button>
					</div>
					<AnimatePresence>
						{isFilterDropdownOpen && (
							<motion.div
								initial="hidden"
								animate="visible"
								exit="hidden"
								variants={filterDropdownVariants}
								className="absolute right-0 border border-gray-300 mt-2 w-56 bg-white rounded-md shadow-lg z-10"
							>
								<div className="">
									<Button
										variant={filter === null ? 'primary' : 'ghost'}
										className="w-full rounded-none rounded-t-md text-left py-2"
										onClick={() => {
											setFilter('all');
											setIsFilterDropdownOpen(false);
										}}
									>
										All ({items.length})
									</Button>
									<hr className="border-t border-gray-300" />
									<Button
										variant={filter === EStatus.Approved ? 'primary' : 'ghost'}
										className="w-full rounded-none text-left px-4 py-2"
										onClick={() => {
											setFilter(EStatus.Approved);
											setIsFilterDropdownOpen(false);
										}}
									>
										Approved ({approvedCount})
									</Button>
									<Button
										variant={filter === EStatus.NotSet ? 'primary' : 'ghost'}
										className="w-full rounded-none text-left px-4 py-2"
										onClick={() => {
											setFilter(EStatus.NotSet);
											setIsFilterDropdownOpen(false);
										}}
									>
										Pending ({pendingCount})
									</Button>
									<hr className="border-t border-gray-300" />
									<Button
										variant={filter === EStatus.Rejected ? 'primary' : 'ghost'}
										className="w-full rounded-none rounded-b-md text-left px-4 py-2"
										onClick={() => {
											setFilter(EStatus.Rejected);
											setIsFilterDropdownOpen(false);
										}}
									>
										Declined ({declinedCount})
									</Button>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			</div>
		</div>
	);
};