import { Loader } from 'app/ui';
import type { ReactNode } from 'react';
import React from 'react';
import { motion } from 'framer-motion';

interface IComponentWrapper {
	children: ReactNode;
	isLoading?: boolean;
}

const fadeInVariants = {
	hidden: { opacity: 0 },
	visible: { opacity: 1, transition: { duration: 0.5, ease: 'easeInOut' } },
};

export const ComponentWrapper = ({
	children,
	isLoading,
}: IComponentWrapper) => {
	return isLoading ? (
		<div className="flex min-h-12 relative">
			<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
				<Loader />
			</div>
		</div>
	) : (
		<motion.div initial="hidden" animate="visible" variants={fadeInVariants}>
			{children}
		</motion.div>
	);
};
