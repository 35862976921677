import React, { forwardRef } from 'react';
import { cva } from 'class-variance-authority';
import cn from 'classnames';
import type { IUI } from 'ui/types/core';
import { Button, type IButtonProps } from 'ui';

const buttonGroupVariants = cva('inline-flex rounded-full shadow-sm');

interface IButtonGroupProps extends React.HTMLAttributes<HTMLDivElement>, IUI {
	label?: string;
	toggle?: boolean;
	activeId?: string | null;
	onActiveChange: (id: string | null) => void;
}

const ButtonGroup = forwardRef<HTMLDivElement, IButtonGroupProps>(
	(
		{
			className,
			children,
			'data-testid': dataTestId,
			label,
			toggle = false,
			activeId = null,
			onActiveChange,
			id,
		},
		ref,
	) => {
		return (
			<div
				ref={ref}
				className={cn('flex flex-col items-start', className)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(id ? { id } : {})}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(dataTestId ? { 'data-testid': dataTestId } : {})}
			>
				{label && <span className="text-xs text-gray-500 mb-1">{label}</span>}
				<div className={cn(buttonGroupVariants(), className)}>
					{React.Children.map(children, (child, index) => {
						if (
							React.isValidElement<IButtonProps>(child) &&
							child.type === Button
						) {
							const isActive = child.props.id === activeId;
							const isFirst = index === 0;
							const isLast = index === React.Children.count(children) - 1;

							const buttonClassNames = cn(
								child.props.className,
								'px-4 py-2 text-sm font-medium',
								{
									'hover:bg-opacity-90': isActive,
									'rounded-l-full': isFirst,
									'rounded-r-full': isLast,
									'-ml-px': !isFirst,
								},
							);

							return React.cloneElement(child, {
								...child.props,
								className: buttonClassNames,
								onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
									child.props.onClick?.(e);
									onActiveChange(
										toggle && isActive ? null : (child.props.id ?? null),
									);
								},
								variant: isActive
									? 'primary'
									: child.props.variant || 'outline',
							});
						}
						return child;
					})}
				</div>
			</div>
		);
	},
);

ButtonGroup.displayName = 'ButtonGroup';

export { ButtonGroup };
export type { IButtonGroupProps };
