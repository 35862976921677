import { Card, Icon } from 'ui';
import React from 'react';

interface IPaymentsApprovedProps {
	confirmationId: string;
	totalPaid: number;
}

export const PaymentsApproved = ({
	confirmationId,
	totalPaid,
}: IPaymentsApprovedProps) => {
	return (
		<Card
			border="none"
			className="w-full max-w-md mx-auto bg-background p-6 space-y-8"
		>
			{/* Header Section */}
			<div className="space-y-4 text-center">
				<div className="mx-auto h-12 w-12 rounded-full bg-emerald-50 flex items-center justify-center">
					<Icon name="check-circle" className="h-6 w-6 fill-emerald-600" />
				</div>
				<h2 className="text-2xl font-semibold tracking-tight">
					Payment Successful
				</h2>
			</div>

			{/* Content Section */}
			<div className="space-y-6">
				<div className="space-y-2 text-center">
					<p className="text-sm text-gray-500">Confirmation #</p>
					<p className="font-mono text-lg">{confirmationId}</p>
				</div>

				<div className="text-center">
					<p className="text-sm text-gray-500 mb-1">Amount Paid</p>
					<p className="text-4xl font-semibold tracking-tight">
						${totalPaid.toLocaleString('en-US', { minimumFractionDigits: 2 })}
					</p>
				</div>

				<div className="rounded-lg bg-gray-50 p-4">
					<p className="text-sm text-center text-gray-600">
						Payment is processing and may take a few hours to reflect on your
						account. Please print this page for your records.
					</p>
				</div>
			</div>
		</Card>
	);
};

PaymentsApproved.displayName = 'PaymentsApproved';
