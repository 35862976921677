import type { IPayin, TPayinTransactionType } from 'api/resources/payin';
import { PaymentsSpinner } from 'app/features/Payments/partials/PaymentsSpinner';
import { EPaymentsFormStatus } from 'app/features/Payments/PaymentsModal';
import { Button, Icon } from 'app/ui';
import React, {
	type LegacyRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { FormatCurrency } from 'utils/features/formatCurrency';

interface IPaymentsRainforestProps {
	payinInfo: IPayin;
	onSubmit: () => void;
	onSuccess: (
		confirmationId: string,
		methodType: TPayinTransactionType,
	) => void;
	onInvalid: () => void;
	onDeclined: () => void;
	onMailCheck: (amountToPay: number) => void;
	formStatus: EPaymentsFormStatus;
}

export const PaymentsRainforest = ({
	payinInfo,
	onSubmit,
	onSuccess,
	onInvalid,
	onDeclined,
	onMailCheck,
	formStatus,
}: IPaymentsRainforestProps) => {
	const availablePaymentMethods = payinInfo.payinOptions.map(
		(opts) => opts.estimate.transactionType,
	);

	const defaultPaymentMethod: TPayinTransactionType =
		availablePaymentMethods.indexOf('CreditCard') > -1 ? 'CreditCard' : 'ACH';

	const [selectedPaymentMethod, setSelectedPaymentMethod] =
		useState<TPayinTransactionType>(defaultPaymentMethod);

	const [rainforestWidgetEventsSet, setRainforestWidgetEventsSet] =
		useState(false);
	const [disabled, setDisabled] = useState(true);

	const rainforestWidgetRef =
		useRef() as React.MutableRefObject<HTMLFormElement | null>;

	const rainforestWidgetRefMemo = useCallback((e: HTMLFormElement) => {
		rainforestWidgetRef.current = e;
	}, []);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const handleMethodUpdated = (e: any) => {
			const newMethodType = e.detail[0] as string;

			const newSelectedMethod = payinInfo.payinOptions.find((opts) => {
				const newMethodTypeMap =
					newMethodType === 'CARD' ? 'CreditCard' : 'ACH';

				return opts.estimate.transactionType === newMethodTypeMap;
			});

			setSelectedPaymentMethod(newMethodType === 'CARD' ? 'CreditCard' : 'ACH');

			rainforestWidgetRef.current?.setAttribute(
				'payin-config-id',
				newSelectedMethod?.rainforestPayinConfigId || '',
			);
		};

		const handleValid = () => {
			setDisabled(false);
		};

		const handleInvalid = () => {
			setDisabled(true);
			onInvalid();
		};

		const handleDeclined = () => {
			setDisabled(true);
			onDeclined();
		};

		const handleCardBrandUpdated = () => {
			setDisabled(false);
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const handleApproved = (e: any) => {
			const newConfirmationId = e?.detail[0].data?.payin_id;
			const methodType =
				e?.detail[0].data?.method_type === 'CARD' ? 'CreditCard' : 'ACH';

			onSuccess(newConfirmationId, methodType);
		};

		const observerRainforestWidget = new MutationObserver(() => {
			if (rainforestWidgetEventsSet) {
				// Once the observer is set' we never run the logic below again
				return;
			}

			if (rainforestWidgetRef.current && !rainforestWidgetEventsSet) {
				// Disconnect once the ref is ready. Otherwise we get ghost events causing duplicate POSTs.
				observerRainforestWidget.disconnect();

				setRainforestWidgetEventsSet(true);

				rainforestWidgetRef.current?.addEventListener(
					'method-updated',
					handleMethodUpdated,
				);

				rainforestWidgetRef.current?.addEventListener('valid', handleValid);
				rainforestWidgetRef.current?.addEventListener('invalid', handleInvalid);
				rainforestWidgetRef.current?.addEventListener(
					'declined',
					handleDeclined,
				);
				rainforestWidgetRef.current?.addEventListener(
					'card-brand-updated',
					handleCardBrandUpdated,
				);
				rainforestWidgetRef.current?.addEventListener(
					'approved',
					handleApproved,
				);
			}
		});

		observerRainforestWidget.observe(document.body, {
			attributes: true,
			childList: true,
			subtree: true,
		});

		return () => {
			// Disconnect observer or we get ghost event listener subscriptions
			// causing double events
			observerRainforestWidget.disconnect();
		};
	}, [
		rainforestWidgetEventsSet,
		onSuccess,
		payinInfo.payinOptions,
		selectedPaymentMethod,
		onDeclined,
		onInvalid,
	]);

	const handleSubmit = () => {
		onSubmit();
		rainforestWidgetRef?.current?.submit();
	};

	const selectedPaymentMethodObject = useMemo(() => {
		return payinInfo.payinOptions.filter((opts) => {
			return opts.estimate.transactionType === selectedPaymentMethod;
		})[0];
	}, [payinInfo, selectedPaymentMethod]);

	const additionalFee =
		selectedPaymentMethodObject.estimate.feeAdditionalFixedAmount > 0
			? ` + $${selectedPaymentMethodObject.estimate.feeAdditionalFixedAmount.toLocaleString('en', { minimumFractionDigits: 2 })}`
			: ``;

	const salesTax =
		selectedPaymentMethodObject.estimate.feeSalesTax > 0
			? ` + $${selectedPaymentMethodObject.estimate.feeSalesTax} sales tax`
			: '';

	const allowedMethods: ('CARD' | 'ACH' | 'VALIDATED_ACH')[] = payinInfo.payinOptions
	.filter((opts) => {
		return (
			opts.estimate.transactionType === 'ACH' ||
			opts.estimate.transactionType === 'CreditCard'
		);
	})
	.map((opts) =>
		opts.estimate.transactionType === 'CreditCard' ? 'CARD' : 'ACH',
	)
	// Sort so that CARD is first so it's the default selection
	.sort((opts) => (opts === 'CARD' ? -1 : 1))

	if (payinInfo.usePlaid) {
		allowedMethods.push('VALIDATED_ACH')
	}

	return (
		<>
			<h2 className="text-2xl font-bold text-center mb-4">
				Select a Payment Method
			</h2>

			{payinInfo.rainforestSessionKey &&
				selectedPaymentMethodObject.rainforestPayinConfigId && (
					<rainforest-payment
						session-key={payinInfo.rainforestSessionKey}
						payin-config-id={
							selectedPaymentMethodObject.rainforestPayinConfigId
						}
						allowed-methods={allowedMethods.join(',')}
						hide-button={true}
						ref={rainforestWidgetRefMemo as LegacyRef<HTMLElement>}
					></rainforest-payment>
				)}

			<div className="my-4 text-center">
				<Button
					onClick={() =>
						onMailCheck(selectedPaymentMethodObject.estimate.total)
					}
					className="text-blue-400 underline"
					variant="ghost"
				>
					Want to mail a check? Click here
				</Button>
			</div>
			{formStatus === EPaymentsFormStatus.Submitting && (
				<div className="p-4 warn mb-8">
					<p className="warn">
						Processing payment, please do not close or refresh the page.
					</p>
				</div>
			)}
			<hr className="mx-4" />

			<div className="mt-6 space-y-2 px-4 text-sm">
				{selectedPaymentMethodObject.estimate.feeAmountTotal > 0 && (
					<>
						<div className="flex justify-between">
							<span>Amount</span>
							<span>
								<FormatCurrency
									value={selectedPaymentMethodObject.estimate.subtotal}
								/>
							</span>
						</div>
						<div className="flex justify-between">
							<span>
								Convenience Fee (
								{selectedPaymentMethodObject.estimate.feePercentage}%)
								<br />
								<span className="text-xs text-gray-700">
									({selectedPaymentMethodObject.estimate.feePercentage}%
									{additionalFee}
									{salesTax})
								</span>
							</span>
							<span>
								<FormatCurrency
									value={selectedPaymentMethodObject.estimate.feeAmountTotal}
								/>
							</span>
						</div>
					</>
				)}
				<div className="flex justify-between">
					<span>Payment Total</span>
					<span className="font-bold">
						<FormatCurrency
							value={selectedPaymentMethodObject.estimate.total}
						/>
					</span>
				</div>
			</div>
			<div className="w-full flex gap-1 justify-between mt-6 px-4 pb-4">
				<Button
					variant="primary"
					disabled={disabled}
					onClick={handleSubmit}
					className="w-full"
				>
					<div className="flex items-center gap-1">
						{formStatus === EPaymentsFormStatus.Submitting ? (
							<PaymentsSpinner />
						) : (
							<Icon name="credit-card" className="fill-white" />
						)}
						Pay Now
					</div>
				</Button>
			</div>
		</>
	);
};

PaymentsRainforest.displayName = 'PaymentsRainforest';
