import React from 'react';
import { SignatureWidget } from 'ui';
import { Controller, useFormContext } from 'react-hook-form';

export const ActionModalContent = () => {
	const { control } = useFormContext();

	return (
		<div className="w-[100%]">
			<Controller
				name="signature"
				control={control}
				rules={{ required: true }}
				render={({ field }) => (
					<SignatureWidget
						onChange={(signature) => field.onChange(signature)}
						useManualSignature
						className="min-h-40"
					/>
				)}
			/>
		</div>
	);
};
