import React, { type ReactNode } from 'react';
import { LayoutWrapper } from 'features/Layout';

interface IFloatLayoutProps {
	children?: ReactNode;
}

export const FloatLayout = ({ children }: IFloatLayoutProps) => {
	return <LayoutWrapper>{children}</LayoutWrapper>;
};
