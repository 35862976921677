import React from 'react';
import type { ReactNode } from 'react';
import { generatePath } from 'react-router-dom';
import { Dashboard } from 'pages/Dashboard';
import { Items } from 'pages/Items';
import { Proposals } from 'pages/Proposals';
import { Invoices } from 'pages/Invoices';
import { Demos } from 'pages/Demos';
import { type TLayouts } from 'app/features/Layout/LayoutTypes';

interface IRouteParam {
	[key: string]: string | number;
}

interface IRoute {
	path: string;
	element: ReactNode;
	protected: boolean;
	getRoute: (params: IRouteParam) => string;
	layout?: TLayouts;
	icon?: string;
	name: string;
}

interface IRoutes {
	[key: string]: IRoute;
}

type TBaseRoutes = {
	[key: string]: Omit<IRoute, 'getRoute'>;
};

//   Note: If a route doesn't have a DefaultLayout, then it will require a manual layout within the page component.
const baseRoutes: TBaseRoutes = {
	dashboard: {
		path: 'dashboard',
		element: <Dashboard />,
		protected: true,
		layout: 'default',
		icon: 'rectangle-group',
		name: 'Dashboard',
	},
	demo: {
		path: 'demos',
		element: <Demos />,
		protected: true,
		icon: 'rectangle-group',
		name: 'Demos',
	},
	items: {
		path: 'items',
		element: <Items />,
		protected: true,
		icon: 'queue-list',
		name: 'Items',
	},
	proposals: {
		path: 'proposals',
		layout: 'default',
		element: <Proposals />,
		protected: true,
		icon: 'document-duplicate',
		name: 'Proposals',
	},
	invoices: {
		path: 'invoices',
		element: <Invoices />,
		protected: true,
		icon: 'inbox-arrow-down',
		name: 'Invoices',
	},
};

export const routes: IRoutes = {} as IRoutes;

Object.keys(baseRoutes).forEach((routeName) => {
	routes[routeName] = {
		...baseRoutes[routeName],
		getRoute: (params: IRouteParam) => {
			const path = generatePath(baseRoutes[routeName].path, params);
			return path;
		},
	};
});