import { useGetOverviews } from 'api/hooks/useGetOverviews';
import React from 'react';
import { Card, Badge, Button, Icon, Loader } from 'ui';
import { motion } from 'framer-motion';
import { useStackAnimation } from 'hooks/useStackAnimation';
import { FormatCurrency } from 'utils/features/formatCurrency';

export const Dashboard = () => {
	const { data, isLoading } = useGetOverviews();
	const { budget, invoices, proposals, items, retainerFee } = data?.data || {};

	const openProposals =
		proposals?.filter((proposal) => proposal.status === 'opened') || [];
	const openProposalsTotal = openProposals.reduce(
		(sum, proposal) => sum + proposal.total,
		0,
	);

	const openInvoices =
		invoices?.filter(
			(invoice) => invoice.status === 'opened' || invoice.status === 'pending',
		) || [];
	const openInvoicesTotal = openInvoices.reduce(
		(sum, invoice) => sum + invoice.total,
		0,
	);

	const itemsToReview =
		items?.filter((item) => item.status === 'pending') || [];
	const itemsToReviewCount = itemsToReview.length;
	const itemsToReviewTotal = itemsToReview.reduce(
		(sum, item) => sum + item.total,
		0,
	);

	const retainerFeeTotal = retainerFee?.total || 0;

	const motionProps = useStackAnimation(0.1, !isLoading);

	if (isLoading) {
		return (
			<div className="flex justify-center items-center h-screen md:h-[85vh]">
				<Loader />
			</div>
		);
	}
	return (
		<div className="md-lg:px-5 lg:px-20">
			<Card
				border="lg"
				className="mb-6 p-4 bg-background rounded-xl md:rounded-3xl lg:rounded-3xl md:p-5"
			>
				<Card
					border="lg"
					className="h-[375px] rounded-xl md:rounded-3xl lg:rounded-3xl p-2 sm:h-60 md:h-[140px] lg:h-25"
				>
					<div className="h-full md:w-full grid sm:grid-cols-2 md:grid-cols-3">
						{[
							{ label: 'Budget', amount: budget?.total || 0 },
							{
								label: 'Total Paid',
								amount:
									invoices
										?.filter((invoice) => invoice.status === 'paid')
										.reduce((sum, invoice) => sum + invoice.total, 0) || 0,
							},
							{ label: 'Total Due', amount: openInvoicesTotal },
						].map((item, index) => (
							<div
								key={item.label}
								className={`flex flex-col gap-2 justify-between md:items-start h-full ${
									index < 2 ? 'md:border-r border-gray-300' : ''
								} ${index === 2 ? 'pb-4 md:p-0' : ''}`}
							>
								<div className="flex flex-col pt-1 gap-4 justify-between h-full w-full md:p-3 text-sm md:text-base font-semibold">
									<Badge
										size="xs"
										variant="secondary"
										className="mb-2 bg-primary-badge w-fit"
									>
										{item.label}
									</Badge>

									<FormatCurrency
										value={item.amount}
										className="text-4xl font-dm-sans font-light lg:text-5xl"
									/>
								</div>
								{index < 2 && <hr className="border-gray-300 my-2 md:hidden" />}
							</div>
						))}
					</div>
				</Card>
			</Card>

			<Card
				border="lg"
				className="h-fit flex flex-col rounded-xl p-4 bg-background gap-2 w-fit md:rounded-3xl lg:rounded-3xl md:p-5 lg:p-5"
			>
				<h2 className="text-lg pt-4 md:text-xl font-normal mb-4">
					Action Items
				</h2>
				{[
					{
						icon: 'document-duplicate',
						title: 'Open Proposals',
						count: openProposals.length,
						amount: openProposalsTotal,
						action: 'Approve Now',
					},
					{
						icon: 'inbox-arrow-down',
						title: 'Open Invoices',
						count: openInvoices.filter((invoice) => invoice.status === 'opened')
							.length,
						amount: openInvoicesTotal,
						action: 'Pay Now',
					},
					{
						icon: 'clipboard-document-check',
						title: 'Items to Review',
						count: itemsToReviewCount,
						amount: itemsToReviewTotal,
						action: 'Approve Now',
					},
					{
						icon: 'wallet',
						title: 'Retainer Fee',
						amount: retainerFeeTotal,
						action: 'Pay Now',
					},
				].map((item, index) => (
					<motion.div
						key={index}
						initial={motionProps.initial}
						animate={motionProps.animate}
						custom={index}
						className="flex flex-col gap-6 p-3 bg-gray border border-gray-200 rounded-lg md:flex-row lg:flex-row justify-between md:p-4 lg:p-4"
					>
						<div className="flex flex-row justify-between md:items-center space-x-3">
							<div className="flex gap-2">
								<Icon
									size="sm"
									name={item.icon}
									className="fill-primary-badge"
								/>
								<span className="font-medium whitespace-nowrap">
									{item.title}
								</span>
							</div>
							{item.count !== undefined && item.count > 0 && (
								<Badge
									size="sm"
									className="bg-primary-badge text-xs font-semibold"
								>
									{item.count}
								</Badge>
							)}
						</div>
						<div className="flex flex-row justify-between items-end md:items-center lg:items-center space-x-4">
							<span>
								<FormatCurrency
									value={item.amount}
									className="text-4xl md:text-2xl"
								/>
							</span>
							<Button size="sm" variant="primary" className="mt-2 md:mt-0 w-32">
								{item.action}
							</Button>
						</div>
					</motion.div>
				))}
			</Card>
		</div>
	);
};
