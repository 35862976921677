import type { IOrg } from 'api/resources/auth';
import { createContext } from 'react';

export interface IAuthContextType {
	isAuthenticated: boolean;
	org?: IOrg | null;
}

export const AuthContext = createContext<IAuthContextType>({
	isAuthenticated: false,
	org: null,
});
