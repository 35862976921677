import React from 'react';
import type { ReactNode } from 'react';
import { generatePath } from 'react-router-dom';
import { Dashboard } from 'pages/Dashboard';
import { Forbidden } from 'app/pages/Forbidden';
import { Items } from 'app/pages/Items';
import { Proposals } from 'app/pages/Proposals';
import { ProposalDetail } from 'app/pages/ProposalDetail';
import { Invoices } from 'app/pages/Invoices';
import { DefaultLayout } from 'app/features/Layout/DefaultLayout/DefaultLayout';
import { Demos } from 'app/pages/Demos';

interface IRouteParam {
	[key: string]: string | number;
}

interface IRoute {
	path: string;
	element: ReactNode;
	protected: boolean;
	getRoute: (params: IRouteParam) => string;
	layout?: ReactNode;
	icon?: string;
	name: string;
}

interface IRoutes {
	[key: string]: IRoute;
}

type TBaseRoutes = {
	[key: string]: Omit<IRoute, 'getRoute'>;
};

//   Note: If a route doesn't have a DefaultLayout, then it will require a manual layout within the page component.
const baseRoutes: TBaseRoutes = {
	forbidden: {
		path: 'forbidden',
		element: <Forbidden />,
		protected: false,
		layout: (
			<DefaultLayout>
				<Forbidden />
			</DefaultLayout>
		),
		icon: 'power',
		name: 'Log Out',
	},
	dashboard: {
		path: 'dashboard',
		element: <Dashboard />,
		protected: true,
		layout: (
			<DefaultLayout>
				<Dashboard />
			</DefaultLayout>
		),
		icon: 'rectangle-group',
		name: 'Dashboard',
	},
	demo: {
		path: 'demos',
		element: <Demos />,
		protected: true,
		layout: (
			<DefaultLayout>
				<Demos />
			</DefaultLayout>
		),
		icon: 'rectangle-group',
		name: 'Demos',
	},
	items: {
		path: 'items',
		element: <Items />,
		protected: true,
		icon: 'queue-list',
		name: 'Items',
	},
	proposals: {
		path: 'proposals',
		element: <Proposals />,
		protected: true,
		layout: (
			<DefaultLayout>
				<Proposals />
			</DefaultLayout>
		),
		icon: 'document-duplicate',
		name: 'Proposals',
	},
	invoices: {
		path: 'invoices',
		element: <Invoices />,
		protected: true,
		layout: (
			<DefaultLayout>
				<Invoices />
			</DefaultLayout>
		),
		icon: 'inbox-arrow-down',
		name: 'Invoices',
	},
	proposalDetail: {
		path: 'proposals/:id',
		element: <ProposalDetail />,
		protected: true,
		layout: (
			<DefaultLayout>
				<ProposalDetail />
			</DefaultLayout>
		),
		icon: 'document-duplicate',
		name: 'Proposal Detail',
	},
};

export const routes: IRoutes = {} as IRoutes;

Object.keys(baseRoutes).forEach((routeName) => {
	routes[routeName] = {
		...baseRoutes[routeName],
		getRoute: (params: IRouteParam) => {
			const path = generatePath(baseRoutes[routeName].path, params);
			return path;
		},
	};
});
