import React from 'react';
import { Card } from 'ui';
import { ActionTitle } from 'features/Layout/PageActions';

interface IActionItemsProps {
	title: string;
	children: React.ReactNode;
}

export const ActionItems = ({ title, children }: IActionItemsProps) => {
	return (
		<Card
			border="2xl"
			className="bg-background border-[1.5px] p-4 md:p-8 lg:p-8 rounded-3xl"
		>
			<ActionTitle title={title} />
			<div className="flex flex-col gap-2">{children}</div>
		</Card>
	);
};
