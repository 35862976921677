import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Icon, Image } from 'ui';
import 'app/App.css';
import 'react-toastify/dist/ReactToastify.css';
import { routes } from 'app/features/Routing/Routes';
import { useTheme } from 'contexts/ThemeContext';
import 'app/styles/fonts.css';
import { useAuth } from 'app/features/auth/useAuth';
import { ToastContainer } from 'react-toastify';
import { NavItemsWrapper } from './partials/NavItemsWrapper';

export interface INavItem {
	name: string;
	icon: string | undefined;
	link: string;
}

export const Layout = () => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token') || '';
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [imageError, setImageError] = useState(false);
	const { org, isAuthenticated } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		const primaryColor = org?.branding?.primary || '#f3f4f6';
		const secondaryColor = org?.branding?.secondary || '#3338A1';

		document.documentElement.style.setProperty('--primary-color', primaryColor);
		document.documentElement.style.setProperty(
			'--secondary-color',
			secondaryColor,
		);
	}, [org?.branding]);

	const { theme } = useTheme();

	const navItems: INavItem[] = [
		{
			name: routes.dashboard.name,
			icon: routes.dashboard.icon,
			link: `${routes.dashboard.path}?token=${token}`,
		},
		{
			name: routes.items.name,
			icon: routes.items.icon,
			link: `${routes.items.path}?token=${token}`,
		},
		{
			name: routes.proposals.name,
			icon: routes.proposals.icon,
			link: `${routes.proposals.path}?token=${token}`,
		},
		{
			name: routes.invoices.name,
			icon: routes.invoices.icon,
			link: `${routes.invoices.path}?token=${token}`,
		},
	];

	return (
		<div
			className={`${theme === 'dark' ? 'dark' : ''} flex flex-col h-screen md:h-fit lg:h-fit bg-background text-text`}
			data-environment={process.env.PUBLIC_ENVIRONMENT_NAME}
		>
			{isAuthenticated ? (
				<>
					{/* Mobile Header */}
					<header className="md:hidden border-b border-gray-400 shadow-lg rounded-lg p-4 flex justify-between items-center sticky top-0 bg-background z-50">
						<div className="flex flex-row gap-2 items-center md:items-center">
							<div
								className="w-10 h-10 bg-primary-badge rounded-lg cursor-pointer"
								onClick={() => navigate(`/dashboard?token=${token}`)}
							>
								{imageError ? (
									<div />
								) : (
									<Image
										src={org?.branding?.logo || ''}
										alt={org?.name || ''}
										onError={() => setImageError(true)}
									/>
								)}
							</div>
							<span className="font-semibold text-primary-badge">
								{org?.name}
							</span>
						</div>
						<Button
							variant="ghost"
							onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
						>
							<Icon name="bars-2" className="fill-primary-badge" />
						</Button>
					</header>

					{/* Mobile Menu */}
					{isMobileMenuOpen && (
						<div className="h-[550px] md:hidden fixed shadow-lg rounded-b-2xl inset-0 z-50 bg-background">
							<div className="flex flex-col h-full">
								<div className="flex justify-between items-center p-4 border-b border-border">
									<div className="flex items-center space-x-2">
										<div
											className="w-10 h-10 bg-primary-badge rounded-lg cursor-pointer"
											onClick={() => navigate(`/dashboard?token=${token}`)}
										>
											{imageError ? (
												<div />
											) : (
												<Image
													src={org?.branding?.logo || ''}
													alt={org?.name || ''}
													onError={() => setImageError(true)}
												/>
											)}
										</div>
										<span className="font-semibold text-primary-badge">
											{org?.name}
										</span>
									</div>
									<Button
										variant="ghost"
										onClick={() => setIsMobileMenuOpen(false)}
									>
										<Icon name="x-mark" className="fill-primary-badge" />
									</Button>
								</div>
								<NavItemsWrapper
									navItems={navItems}
									setIsMobileMenuOpen={setIsMobileMenuOpen}
								/>
							</div>
						</div>
					)}

					<div className="flex flex-1">
						{/* Desktop Sidebar */}
						<aside className="hidden md:flex sm-md:hidden flex-col w-64 p-6 gap-4">
							<div className="flex items-center space-x-[1rem]">
								<div
									className="w-10 h-10 bg-primary-badge rounded-lg cursor-pointer"
									onClick={() => navigate(`/dashboard?token=${token}`)}
								>
									{imageError ? (
										<div className="w-10 h-10" />
									) : (
										<Image
											src={org?.branding?.logo || ''}
											alt={org?.name || ''}
											onError={() => setImageError(true)}
										/>
									)}
								</div>
								<div className="flex-1">
									<span className="font-semibold text-sm text-primary-badge">
										{org?.name}
									</span>
								</div>
							</div>
							<NavItemsWrapper
								navItems={navItems}
								setIsMobileMenuOpen={setIsMobileMenuOpen}
							/>
						</aside>

						{/* Main Content */}
						<div className="w-full h-full min-h-screen relative">
							<Outlet />
							<ToastContainer position="bottom-right" />
						</div>
					</div>
				</>
			) : (
				<div className="w-full">
					<Outlet />
				</div>
			)}
		</div>
	);
};
