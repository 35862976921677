import { apiClient } from 'api/apiClient';
import type { IApiResponse } from 'api/apiTypes';
import type { IItem } from 'api/resources/items';
import type { IProposal } from 'api/resources/proposals';
import type { IInvoices } from 'api/resources/invoices';

const RESOURCE = '/dashboard';

export interface IGetDashboardParams {
	token: string;
}

export type TPaymentMethods = 'credit card' | 'bank transfer';

export interface IGetDashboard {
	budget: {
		total: number;
	};
	invoices: IInvoices[];
	items: IItem[];
	overview: {
		budget: number;
		retainer: number;
		totalReceived: number;
		totalRemaining: number;
	};
	proposals: IProposal[];
}

export type TGetDashboardResponse = IApiResponse<IGetDashboard>;

export const getDashboard = async (
	params: IGetDashboardParams,
): Promise<TGetDashboardResponse> => {
	const { token } = params;
	const { data } = await apiClient.get(`${RESOURCE}/${token}`);
	return data;
};
