import { useQuery } from '@tanstack/react-query';
import type { TGetOverviewsResponse } from 'api/resources/overviews';
import { getOverviews } from 'api/resources/overviews';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetOverviews = () => {
	const query = useQuery<TGetOverviewsResponse>({
		queryKey: [ECacheKeys.Overviews],
		queryFn: () => getOverviews(),
	});

	return query;
};
