import React, { useContext } from 'react';
import { Icon } from 'ui';
import { DefaultLayoutTopRight } from 'app/features/Layout/DefaultLayout/partials/DefaultLayoutTopRight';
import { LayoutContext } from 'app/features/Layout/LayoutContext';

interface ILayoutHeaderProps {
	actions?: React.ReactNode;
	icon?: string;
}

const LayoutHeader = ({ icon }: ILayoutHeaderProps) => {
	const { header } = useContext(LayoutContext) || {};

	return (
		<div className="flex justify-between items-center mb-2 px-2">
			<h1
				className="text-lg text-primary-badge  font-semibold pt-2 pb-4 px-4 rounded-3xl flex items-center"
				data-testid="page-title"
			>
				<Icon name={icon || ''} className="mr-2 fill-primary-badge" />
				{header?.title}
			</h1>

			<div className="flex items-center space-x-4">
				<DefaultLayoutTopRight />
			</div>
		</div>
	);
};

export { LayoutHeader };
