import React, { type ReactNode } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { routes } from 'features/Routing/Routes';
import { LayoutWrapper, LayoutContent, LayoutHeader } from 'features/Layout';

interface IDefaultLayoutProps {
	children?: ReactNode;
}

export const DefaultLayout: React.FC<IDefaultLayoutProps> = ({ children }) => {
	const location = useLocation();

	const currentRoute = Object.values(routes).find((route) =>
		location.pathname.includes(route.path.split('/')[0]),
	);

	const title = currentRoute?.name || '';

	const icon = currentRoute?.icon || '';

	return (
		<LayoutWrapper>
			<LayoutHeader title={title} icon={icon} />
			<LayoutContent>{children || <Outlet />}</LayoutContent>
		</LayoutWrapper>
	);
};
