import React, { forwardRef } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import cn from 'classnames';
import type { IUI } from 'ui/types/core';

const cardVariants = cva(`rounded-lg shadow-sm overflow-hidden`, {
	variants: {
		border: {
			default: 'rounded border border-gray-200',
			none: 'rounded-none',
			xs: 'rounded-xs border border-gray-200',
			sm: 'rounded-sm border border-gray-200',
			md: 'rounded-md border border-gray-200',
			lg: 'rounded-[16px] border border-gray-200',
		},
	},
	defaultVariants: {
		border: 'default',
	},
});

interface ICardProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof cardVariants>,
		IUI {
	border?: 'default' | 'none' | 'xs' | 'sm' | 'md' | 'lg';
	'data-testid'?: string;
}

const Card = forwardRef<HTMLDivElement, ICardProps>(
	(
		{ id, className, border = 'none', children, 'data-testid': dataTestId },
		ref,
	) => {
		return (
			<div
				ref={ref}
				role="region"
				className={cn(
					'dmui-card',
					cardVariants({ border }),
					'w-full mx-auto',
					className,
				)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(id ? { id } : {})}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(dataTestId ? { 'data-testid': dataTestId } : {})}
			>
				{children}
			</div>
		);
	},
);

Card.displayName = 'Card';

export { Card };

export { type ICardProps };
