import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { FloatLayout, Layout } from 'features/Layout';
import { NotFound } from 'pages/NotFound';
import { routes } from 'features/Routing/Routes';
import { AuthProvider } from 'features/auth/AuthProvider';
import { ProtectedRoutes } from 'features/auth/ProtectedRoute';
import { LayoutList } from 'app/features/Layout/LayoutList';

export const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<AuthProvider>
				<Layout />
			</AuthProvider>
		),
		errorElement: (
			<FloatLayout>
				<NotFound />
			</FloatLayout>
		),
		children: [
			// Public routing
			...Object.keys(routes)
				.filter((routeName: string) => !routes[routeName].protected)
				.map((routeName) => ({
					path: routes[routeName].path,
					element: routes[routeName].layout || routes[routeName].element,
				})),
			// Protected routing
			{
				element: <ProtectedRoutes />,
				children: [
					...Object.keys(routes)
						.filter((routeName) => routes[routeName].protected)
						.map((routeName) => ({
							path: routes[routeName].path,
							element: (
								<LayoutList layoutName={routes[routeName].layout}>
									{routes[routeName].element}
								</LayoutList>
							),
						})),
				],
			},
		],
	},
]);