import React, { type ReactNode } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { routes } from 'features/Routing/Routes';
import { LayoutWrapper, LayoutContent, LayoutHeader } from 'features/Layout';
import { SuspenseWrapper } from 'app/features/SuspenseWrapper/SuspenseWrapper';

interface IDefaultLayoutProps {
	children?: ReactNode;
}

export const DefaultLayout = ({ children }: IDefaultLayoutProps) => {
	const location = useLocation();

	const currentRoute = Object.values(routes).find((route) =>
		location.pathname.includes(route.path.split('/')[0]),
	);

	const icon = currentRoute?.icon || '';

	return (
		<LayoutWrapper>
			<LayoutHeader icon={icon} />
			<LayoutContent className="px-4 lg:px-16 2xl:px-20 pt-6 lg:pt-25">
				<SuspenseWrapper>{children || <Outlet />}</SuspenseWrapper>
			</LayoutContent>
		</LayoutWrapper>
	);
};

DefaultLayout.displayName = 'DefaultLayout';