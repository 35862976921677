import React, { type ReactNode } from 'react';
import { DefaultLayout } from 'app/features/Layout/DefaultLayout/DefaultLayout';
import { FloatLayout } from 'app/features/Layout/FloatLayout/FloatLayout';
import { type TLayouts } from 'app/features/Layout/LayoutTypes';

interface ILayoutProps {
	children: ReactNode;
	layoutName?: TLayouts;
}

export const LayoutList = ({ children, layoutName }: ILayoutProps) => {
	switch (layoutName) {
		case 'default':
			return <DefaultLayout>{children}</DefaultLayout>;
		case 'float':
			return <FloatLayout>{children}</FloatLayout>;
		default:
			return <DefaultLayout>{children}</DefaultLayout>;
	}
};
