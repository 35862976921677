import React from 'react';
import { Card, Badge } from 'ui';
import { FormatCurrency } from 'utils/features/formatCurrency';
import { motion } from 'framer-motion';
import { useStackAnimation } from 'hooks/useStackAnimation';

export interface ISummaryItem {
	label: string;
	amount: number;
}

interface ISummaryCardProps {
	items: ISummaryItem[];
	isDataReady: boolean;
}

export const SummaryCard = ({ items, isDataReady }: ISummaryCardProps) => {
	const getGridClass = (length: number) => {
		switch (length) {
			case 1:
				return 'grid-cols-1';
			case 2:
				return 'grid-cols-1 md:grid-cols-2';
			case 3:
				return 'grid-cols-1 lg:grid-cols-3';
			case 4:
				return 'grid-cols-2 md:grid-cols-4 xs-sm:grid-cols-1';
			default:
				return 'grid-cols-2 md:grid-cols-4';
		}
	};

	const currencyClass =
		'text-4xl leading-[1.2] md-lg:text-[32px] lg:text-5xl xl:text-5xl max-w-full';

	const motionProps = useStackAnimation(0.3, isDataReady);

	return (
		<Card border="2xl" className="w-full mb-5 p-5 !rounded-3xl bg-background ">
			<motion.div
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.4 }}
			>
				<Card
					border="2xl"
					className="bg-background border-[1.5px] border-border rounded-3xl shadow-lg"
				>
					<div className={`grid ${getGridClass(items.length)} gap-4`}>
						{items.map((item, index) => {
							const isLast = index === items.length - 1;
							const isEvenInGrid = index % 2 === 0;
							const showVerticalDivider = !isLast && items.length > 1;
							const showHorizontalDivider = items.length >= 2;

							return (
								<motion.div
									key={item.label}
									initial={motionProps.initial}
									animate={motionProps.animate}
									custom={index}
									className={`
										relative
										flex
										flex-col
										justify-between
										items-center
										xs-sm:items-center
										p-3
										
										md-lg:p-4
										lg:p-6
										${showVerticalDivider ? 'md:border-r md:border-border' : ''}
										${showHorizontalDivider ? 'border-b border-border md:border-b-0' : ''}
										${items.length === 4 && !isEvenInGrid ? 'border-l border-border' : ''}
									`}
								>
									<div
										className={`
												w-full
												h-full
												flex
												flex-col
												justify-between
												gap-4
												items-center

												sm-md:items-start
												md-lg:items-start
												lg:items-${items.length === 1 ? 'center' : 'start'}
											`}
									>
										<Badge
											size="xs"
											variant="secondary"
											className={`bg-primary-badge text-primary-badge-foreground block text-ellipsis overflow-hidden whitespace-nowrap min-w-[50px] max-w-full text-[clamp(0.75rem, 2vw, 1rem)]`}
										>
											{item.label}
										</Badge>

										<FormatCurrency
											value={Math.min(item.amount, 10000000)}
											className={`font-dm-sans font-light ${currencyClass} pt-5`}
										/>
									</div>
								</motion.div>
							);
						})}
					</div>
				</Card>
			</motion.div>
		</Card>
	);
};
