import { useGetFetchDemo } from 'api/hooks/useGetFetchDemo';
import type { IMetaRainforest, TPaymentMethods } from 'api/resources/demos';
import { Button } from 'app/ui';
import type { LegacyRef } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export const DemoRainforest = () => {
	const { data } = useGetFetchDemo({ delay: 100 });
	const payments = data?.data?.payments;

	const [rainforestWidgetEventsSet, setRainforestWidgetEventsSet] =
		useState(false);
	const [payinConfigID, setPayinConfigID] = useState('');
	const [disabled, setDisabled] = useState(true);

	const rainforestWidgetRef =
		useRef() as React.MutableRefObject<HTMLFormElement | null>;

	const rainforestWidgetRefMemo = useCallback((e: HTMLFormElement) => {
		rainforestWidgetRef.current = e;
	}, []);

	useEffect(() => {
		if (payinConfigID === '' && payments?.meta.rainforest) {
			// default to the first payment method in the list
			const defaultMethod = payments?.methods[0];
			const newPayinConfigID =
				payments?.meta.rainforest.payinConfigIds[defaultMethod];

			// set default payinConfigID to the first payment method in the list on page load only
			setPayinConfigID(newPayinConfigID);
		}
	}, [payinConfigID, payments?.meta.rainforest, payments?.methods]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const onMethodUpdated = (e: any) => {
			const methodType = e.detail[0] as string;

			if (payments && payments?.processor) {
				const processor = payments?.processor;
				const newPayinConfigID = (payments?.meta[processor] as IMetaRainforest)
					?.payinConfigIds[methodType as TPaymentMethods];

				setPayinConfigID(newPayinConfigID);

				rainforestWidgetRef.current?.setAttribute(
					'payin-config-id',
					newPayinConfigID,
				);
			}
		};

		const onValid = () => {
			setDisabled(false);
		};

		const onInvalid = () => {
			setDisabled(true);
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const onApproved = (e: any) => {
			const confirmationId = e?.detail[0].data?.payin_id;

			// eslint-disable-next-line no-console
			console.log('transaction approved', confirmationId);
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const handleDeclined = (e: any) => {
			// eslint-disable-next-line no-console
			console.log('transaction declined', e);
		};

		const observerRainforestWidget = new MutationObserver(() => {
			if (rainforestWidgetEventsSet) {
				// Once the observer is set' we never run the logic below again
				return;
			}

			if (rainforestWidgetRef.current && !rainforestWidgetEventsSet) {
				// Disconnect once the ref is ready. Otherwise we get ghost events causing duplicate POSTs.
				observerRainforestWidget.disconnect();

				setRainforestWidgetEventsSet(true);

				rainforestWidgetRef.current?.addEventListener(
					'method-updated',
					onMethodUpdated,
				);

				rainforestWidgetRef.current?.addEventListener('valid', onValid);
				rainforestWidgetRef.current?.addEventListener('invalid', onInvalid);

				rainforestWidgetRef.current?.addEventListener('approved', onApproved);

				rainforestWidgetRef.current?.addEventListener(
					'declined',
					handleDeclined,
				);
			}
		});

		observerRainforestWidget.observe(document.body, {
			attributes: true,
			childList: true,
			subtree: true,
		});

		return () => {
			// Disconnect observer or we get ghost event listener subscriptions
			// causing double events
			observerRainforestWidget.disconnect();
		};
	}, [
		payinConfigID,
		payments,
		payments?.meta,
		payments?.processor,
		rainforestWidgetEventsSet,
	]);

	const handleSubmit = () => {
		rainforestWidgetRef?.current?.submit();
	};

	return (
		<>
			{(payments?.meta?.rainforest as IMetaRainforest)?.sessionKey &&
				payinConfigID !== '' &&
				payments && (
					<rainforest-payment
						session-key={payments?.meta?.rainforest?.sessionKey || ''}
						payin-config-id={payinConfigID}
						allowed-methods={payments?.methods.join(',')}
						hide-button={true}
						ref={rainforestWidgetRefMemo as LegacyRef<HTMLElement>}
					></rainforest-payment>
				)}

			<Button onClick={handleSubmit} disabled={disabled}>
				Pay Now
			</Button>
		</>
	);
};
