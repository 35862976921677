export const formatDate = (date: Date | string): string => {
	let dateObject: Date;

	if (typeof date === 'string') {
		if (date.includes('T')) {
			dateObject = new Date(date);
		} else {
			dateObject = new Date(`${date}T00:00:00Z`);
		}
	} else {
		dateObject = new Date(date.toUTCString());
	}

	return new Intl.DateTimeFormat('en-US', {
		month: 'short',
		day: '2-digit',
		year: 'numeric',
		timeZone: 'UTC',
	}).format(dateObject);
};
