import React, { useState, forwardRef, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { cva, type VariantProps } from 'class-variance-authority';
import cn from 'classnames';
import type { IUI, TSize } from 'ui/types/core';
import { Button, Icon, Image } from 'ui';

type TSizeExcluding = Exclude<TSize, 'inherit' | 'xs'>;

const carouselVariants = cva('relative', {
	variants: {
		size: {
			sm: 'w-64',
			md: 'w-96',
			lg: 'w-full',
		},
	},
	defaultVariants: {
		size: 'md',
	},
});

export interface IImageObject {
	src: string;
	alt?: string;
	title?: string;
}

export interface ICarouselProps {
	images: IImageObject[];
	displayRest?: 'dots' | 'images' | 'none';
	animate?: 'slide' | 'fade' | 'scale';
	size?: TSizeExcluding;
	disabled?: boolean;
	initialSlide?: number;
}

export interface ICarouselProps
	extends IUI,
		VariantProps<typeof carouselVariants> {
	images: IImageObject[];
	displayRest?: 'dots' | 'images' | 'none';
	animate?: 'slide' | 'fade' | 'scale';
}

export const Carousel = forwardRef<HTMLDivElement, ICarouselProps>(
	(
		{
			className,
			size = 'lg',
			images,
			id,
			'data-testid': dataTestId,
			disabled = false,
			children,
			displayRest = 'dots',
			animate = 'slide',
			initialSlide,
		},
		ref,
	) => {
		const [[page, direction], setPage] = useState([initialSlide ?? 0, 0]);

		const handlePrevious = useCallback(() => {
			if (disabled) return;
			setPage([page - 1 < 0 ? images.length - 1 : page - 1, -1]);
		}, [disabled, images.length, page]);

		const handleNext = useCallback(() => {
			if (disabled) return;
			setPage([page + 1 >= images.length ? 0 : page + 1, 1]);
		}, [disabled, images.length, page]);

		const currentIndex =
			((page % images.length) + images.length) % images.length;

		const variants = {
			slide: {
				enter: (direction: number) => ({
					x: direction > 0 ? 1000 : -1000,
					opacity: 0,
					scale: 0.9,
				}),
				center: {
					zIndex: 1,
					x: 0,
					opacity: 1,
					scale: 1,
				},
				exit: (direction: number) => ({
					zIndex: 0,
					x: direction < 0 ? 1000 : -1000,
					opacity: 0,
					scale: 0.9,
				}),
			},
			fade: {
				enter: {
					opacity: 0,
					scale: 1.1,
				},
				center: {
					zIndex: 1,
					opacity: 1,
					scale: 1,
				},
				exit: {
					zIndex: 0,
					opacity: 0,
					scale: 1,
				},
			},
			scale: {
				enter: {
					scale: 0.9,
					opacity: 0,
				},
				center: {
					zIndex: 1,
					scale: 1,
					opacity: 1,
				},
				exit: {
					zIndex: 0,
					scale: 1.1,
					opacity: 0,
				},
			},
		};

		const selectedVariants = variants[animate];

		useEffect(() => {
			const handleKeyDown = (event: KeyboardEvent) => {
				if (event.key === 'ArrowLeft') {
					handlePrevious();
				} else if (event.key === 'ArrowRight') {
					handleNext();
				}
			};

			window.addEventListener('keydown', handleKeyDown);

			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		}, [handleNext, handlePrevious]);

		if (!images || images.length === 0) {
			return <div>No images to display</div>;
		}

		return (
			<div
				ref={ref}
				className={cn(carouselVariants({ size }), className)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(id ? { id } : {})}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(dataTestId ? { 'data-testid': dataTestId } : {})}
			>
				<div className="relative overflow-hidden mx-auto w-fit bg-background">
					<AnimatePresence initial={false} custom={currentIndex} mode="wait">
						<motion.div
							key={page}
							custom={direction}
							variants={selectedVariants}
							initial="enter"
							animate="center"
							exit="exit"
							transition={{
								duration: 0.4,
								ease: 'easeInOut',
							}}
						>
							<Image
								src={images[currentIndex].src}
								alt={images[currentIndex].alt || `Slide ${currentIndex + 1}`}
								className={`w-full h-auto rounded-md ${className || ''}`}
							/>
						</motion.div>
					</AnimatePresence>
					{images.length > 1 && (
						<>
							<Button
								onClick={handlePrevious}
								className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black/50 hover:bg-black/70 text-white rounded-full shadow-sm shadow-gray-400 !p-2"
								disabled={disabled}
								aria-label="Previous slide"
							>
								<Icon name="arrow-left-circle" className="fill-current" />
							</Button>
							<Button
								onClick={handleNext}
								className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black/50 hover:bg-black/70 text-white rounded-full shadow-sm shadow-gray-400 !p-2"
								disabled={disabled}
								aria-label="Next slide"
							>
								<Icon name="arrow-right-circle" className="fill-current" />
							</Button>
						</>
					)}
				</div>
				{displayRest === 'dots' && (
					<div className="flex justify-center space-x-2 mt-4">
						{images.map((_, index) => (
							<div
								key={index}
								onClick={() =>
									!disabled && setPage([index, page > index ? -1 : 1])
								}
								className={cn(
									'w-3 h-3 rounded-full cursor-pointer',
									index === currentIndex ? 'bg-primary-badge' : 'bg-gray-300',
								)}
								aria-label={`Go to slide ${index + 1}`}
							/>
						))}
					</div>
				)}
				{displayRest === 'images' && (
					<div className="flex items-center justify-center space-x-1 mt-4 overflow-x-auto">
						{images.map((image, index) => (
							<Button
								key={index}
								onClick={() =>
									!disabled && setPage([index, page > index ? -1 : 1])
								}
								className={cn(
									'!p-0 rounded-lg w-16 h-16 border-2',
									index === currentIndex
										? 'border-[#3338A1]'
										: 'border-transparent',
								)}
								variant="ghost"
								disabled={disabled}
								aria-label={`Go to slide ${index + 1}`}
							>
								<Image
									src={image.src}
									alt={image.alt || `Slide ${index + 1}`}
									className="w-full h-full object-cover rounded-md"
								/>
							</Button>
						))}
					</div>
				)}
				{displayRest === 'none' && <div className="w-full h-full" />}
				{children}
			</div>
		);
	},
);

Carousel.displayName = 'Carousel';
