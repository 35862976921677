import { useEffect, useState } from 'react';
import { type EFeatureFlags } from './featureFlags';

export const useFeature = (flag: EFeatureFlags, initialValue?: boolean) => {
	const [isFeatureEnabled, setIsFeatureEnabled] = useState<boolean>(false);

	useEffect(() => {
		const storedValue = localStorage.getItem(flag);

		// If there's an initial value provided, use it, otherwise check localStorage
		if (initialValue !== undefined) {
			setIsFeatureEnabled(initialValue);
			localStorage.setItem(flag, String(initialValue)); // Save to localStorage
		} else if (storedValue !== null) {
			setIsFeatureEnabled(storedValue === 'true');
		}
	}, [flag, initialValue]);

	return { isFeatureEnabled };
};
