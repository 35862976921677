import React from 'react';
import { motion } from 'framer-motion';
import { Badge, Button, Icon } from 'ui';
import { FormatCurrency } from 'utils/features/formatCurrency';
import type { MotionProps } from 'framer-motion';

interface IActionItem {
	icon: string;
	subtitle?: string;
	title: string;
	count?: number;
	amount?: number;
	action: string;
	navigateTo: () => void;
	motionProps: MotionProps;
	index: number;
}

export const ActionItemWrapper = ({
	icon,
	subtitle,
	title,
	count,
	amount,
	action,
	navigateTo,
	motionProps,
	index,
}: IActionItem) => (
	<motion.div
		key={index}
		initial={motionProps.initial}
		animate={motionProps.animate}
		custom={index}
		className="flex flex-col shadow-sm gap-6 p-3 bg-gray border border-border rounded-lg md:flex-row lg:flex-row justify-between md:p-4 lg:p-4"
	>
		<div className="flex flex-row justify-between md:items-center space-x-3">
			<div className="flex gap-2">
				<Icon size="sm" name={icon} className="fill-primary-badge" />
				<div className="flex flex-col gap-2">
					<div className="flex gap-2">
						<span className="text-sm text-gray-400">{subtitle}</span>
						{count !== undefined && count > 0 && (
							<Badge
								size="sm"
								className="bg-primary-badge rounded-full text-xs font-semibold"
							>
								{count}
							</Badge>
						)}
					</div>
					<span className="font-medium whitespace-nowrap">{title}</span>
				</div>
			</div>
		</div>

		<div className="flex justify-between items-end space-x-4">
			<div>
				{amount !== undefined && (
					<div className="flex flex-row gap-1 items-baseline">
						<FormatCurrency
							value={amount}
							className="font-dm-sans text-4xl md:text-2xl"
						/>
					</div>
				)}
			</div>

			<div className="min-w-[150px]">
				<Button
					size="sm"
					variant="white"
					outline
					radius="full"
					className="w-full"
					onClick={navigateTo}
				>
					<Icon
						size="sm"
						name={icon}
						className="fill-[--secondary-color] mr-2"
					/>
					{action}
				</Button>
			</div>
		</div>
	</motion.div>
);
