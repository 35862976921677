import { useQuery } from '@tanstack/react-query';
import type { IGetDemosParams, TGetDemosResponse } from 'api/resources/demos';
import { getDemos } from 'api/resources/demos';

export const useGetFetchDemo = (params: IGetDemosParams) => {
	const query = useQuery<TGetDemosResponse>({
		queryKey: ['fetchDemo'],
		queryFn: () => getDemos(params),
	});

	return query;
};
