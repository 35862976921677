import React from 'react';

export const ProposalDetail = () => {
	return (
		<div>
			<h1>Proposal Detail!</h1>
			<p>Proposals detail page.</p>
		</div>
	);
};

ProposalDetail.displayName = 'ProposalDetail';
