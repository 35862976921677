import React from 'react';
import {
	isProposalStatusApproved,
	isProposalStatusNotSet,
	type IProposal,
} from 'api/resources/proposals';
import { type IOrg } from 'api/resources/auth';
import { Icon } from 'ui';
import { EStatus } from 'app/ui/types/core';

interface IProposalStatusProps {
	proposal: IProposal;
	org: IOrg;
}

export const ProposalStatus = ({ proposal, org }: IProposalStatusProps) => {
	const isFullyPaid =
		proposal.deposit > 0 && proposal.deposit - proposal.alreadyDeposited == 0;

	// Case 1: Rejected status takes precedence over all other cases
	if (proposal.status === EStatus.Rejected) {
		return (
			<div className="text-sm flex flex-row items-center gap-2 h-full mr-2">
				<span>Declined</span>
				<Icon name="x-circle" />
			</div>
		);
	}

	// Case 2: NotSet status should show nothing if not fully paid
	if (isProposalStatusNotSet(proposal.status) && !isFullyPaid) {
		return null;
	}

	// Case 3: Org approval true, status not approved, fully paid or not
	if (org.proposalsApproval && isProposalStatusApproved(proposal.status)) {
		return (
			<div className="text-sm flex flex-row items-center gap-2 h-full mr-2">
				<span>{`Approved ${isFullyPaid ? ', Deposit Paid' : ''}`}</span>
				<Icon name="check-circle" />
			</div>
		);
	}

	// Case 4: Org approval false, status fully paid
	if (!org.proposalsApproval && isFullyPaid) {
		return (
			<div className="text-sm flex flex-row items-center gap-2 h-full mr-2">
				<span>Deposit Paid</span>
				<Icon name="check-circle" />
			</div>
		);
	}

	// Default case: don't show anything
	return null;
};

ProposalStatus.displayName = 'ProposalStatus';
