import React from 'react';
import { Image, Button, Icon } from 'ui';

interface IItemThumbsProps {
	images: Array<{ src: string; alt: string }>;
	onImageClick: (imageIndex: number) => void;
	mainImageIndex?: number;
}

export const ItemThumbs: React.FC<IItemThumbsProps> = ({
	images,
	onImageClick,
	mainImageIndex = 0,
}) => {
	return (
		<div className="flex flex-col lg:flex-row">
			<div className="w-full pr-0 mb-2 md:w-full md-lg:w-full lg:w-3/4 lg:pr-2 lg:mb-0">
				<div className="relative">
					<Image
						src={images[mainImageIndex].src}
						alt={images[mainImageIndex].alt || 'Main Item Image'}
						width="100%"
						objectFit="contain"
						className="rounded-md cursor-pointer"
						onClick={() => onImageClick(mainImageIndex)}
					/>
					<Button
						variant="ghost"
						size="sm"
						className="!p-0 absolute top-2 left-2"
						onClick={() => onImageClick(mainImageIndex)}
						aria-label="View larger image"
					>
						<div className="bg-white rounded-md w-6 h-6 flex items-center justify-center">
							<Icon
								name="magnifying-glass-plus"
								size="xs"
								className="fill-black"
							/>
						</div>
					</Button>
				</div>
			</div>
			<div className="w-full flex flex-row space-x-2 lg:w-[22.22%] lg:flex-col lg:space-x-0 lg:space-y-2">
				{images.slice(1, 4).map((image, index) => {
					const isLastVisibleImage = index === 2 && images.length > 4;

					return (
						<div
							key={index}
							className="relative cursor-pointer"
							onClick={() => onImageClick(mainImageIndex)}
						>
							<Image
								src={image.src}
								alt={image.alt || `Item Image ${index + 2}`}
								width="100%"
								objectFit="contain"
								className="rounded-md aspect-w-1 aspect-h-1"
							/>
							{isLastVisibleImage && (
								<div className="absolute inset-0 bg-black bg-opacity-25 flex items-center justify-center rounded-md">
									<span className="text-white font-semibold">
										+{images.length - 3}
									</span>
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};
