import React from 'react';
import { motion } from 'framer-motion';
import { cva } from 'class-variance-authority';
import cn from 'classnames';

const actionTitleVariants = cva(
	'flex items-center mb-5 font-dm-sans justify-between gap-2',
	{
		variants: {
			size: {
				sm: 'text-sm',
				md: 'text-base',
				lg: 'text-lg',
				default: 'text-[18px]',
			},
		},
		defaultVariants: {
			size: 'default',
		},
	},
);

interface IActionTitleProps {
	size?: 'sm' | 'md' | 'lg' | 'default';
	title: React.ReactNode;
	className?: string;
}

export const ActionTitle: React.FC<IActionTitleProps> = ({
	size = 'default',
	title,
	className,
}) => {
	const actionTitleClass = actionTitleVariants({ size });

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			className={cn(actionTitleClass, className)}
		>
			{title}
		</motion.div>
	);
};
