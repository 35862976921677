import { createContext } from 'react';

export interface IOrg {
	name: string;
	id: string;
	branding?: {
		primary: string;
		secondary?: string;
		logo?: string;
	};
}

export interface IAuthContextType {
	isAuthenticated: boolean;
	org?: IOrg | null;
}

export const AuthContext = createContext<IAuthContextType>({
	isAuthenticated: false,
	org: null,
});
