import React, { useState } from 'react';
import { Badge, Button } from 'ui';
import { formatDate } from 'utils/date/date';
interface ICustomModalTitleProps {
	proposals: Array<{
		id: string;
		title: string;
		date: string;
	}>;
	totalItems: number;
}

const truncateTitle = (title: string, maxLength: number) => {
	return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
};

export const CustomModalTitle = ({
	proposals,
	totalItems,
}: ICustomModalTitleProps) => {
	const [showAll, setShowAll] = useState(false);
	const visibleProposals = showAll ? proposals : proposals.slice(0, 4);

	return (
		<div className="space-y-3 px-3">
			<h2 className="text-xl text-center">
				{proposals.length === 0
					? 'No Proposals Selected'
					: proposals.length === 1
						? `Approval for ${proposals[0].title}`
						: `Approval for (${proposals.length}) Proposals`}
			</h2>

			{proposals.length === 1 ? (
				<p className="text-sm text-muted-foreground text-center text-gray-400">
					Date: {formatDate(proposals[0].date)} | Total Items: {totalItems}
				</p>
			) : (
				<p className="text-sm text-muted-foreground text-center text-gray-400">
					Total Items: {totalItems}
				</p>
			)}

			{proposals.length > 1 && (
				<>
					<div
						className={`grid gap-3 ${
							visibleProposals.length > 3
								? 'grid-cols-2 sm:grid-cols-3 lg:grid-cols-4'
								: 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'
						}`}
					>
						{visibleProposals.map((proposal) => (
							<Badge
								key={proposal.id}
								className="text-xs whitespace-nowrap text-center bg-primary-badge"
							>
								<span title={proposal.title}>
									{truncateTitle(proposal.title, 14)}
								</span>
							</Badge>
						))}
					</div>
					{proposals.length > 4 && (
						<div className="w-fit float-right flex flex-row-reverse !mb-2">
							<Button
								variant="link"
								size="xs"
								onClick={() => setShowAll((prev) => !prev)}
							>
								{showAll ? 'Show Less' : 'Show More'}
							</Button>
						</div>
					)}
				</>
			)}
		</div>
	);
};
