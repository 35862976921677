import React from 'react';
import { useAuth } from 'app/features/auth/useAuth';
import { Forbidden } from 'app/pages/Forbidden';
import { Outlet } from 'react-router-dom';
import { FloatLayout } from 'app/features/Layout/FloatLayout/FloatLayout';

export const ProtectedRoutes = () => {
	const { isAuthenticated } = useAuth();

	return isAuthenticated ? (
		<Outlet />
	) : (
		<FloatLayout>
			<Forbidden />
		</FloatLayout>
	);
};
