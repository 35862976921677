import { apiClient } from 'api/apiClient';
import type { IApiResponse } from 'api/apiTypes';
import type { TItemStatuses } from 'api/resources/overviews';

const RESOURCE = '/items';

export interface IPostItemsStatusPayload {
	id: string;
	status: TItemStatuses;
}

export type TPostItemsStatusResponse = IApiResponse<Record<string, string>>;

export const postItemsStatus = async (payload: IPostItemsStatusPayload[]) => {
	const { data } = await apiClient.patch(RESOURCE, {
		payload,
	});

	return data;
};
