import React from 'react';
import { Button, ButtonGroup, Icon } from 'ui';
import { PaymentsSpinner } from 'app/features/Payments/partials/PaymentsSpinner';
import {
	isProposalStatusApproved,
	isProposalStatusNotSet,
	isProposalStatusRejected,
} from 'api/resources/proposals';
import { type IProposal } from 'api/resources/proposals';
import { type IOrg } from 'api/resources/auth';
import { EPaymentsFormStatus } from 'app/features/Payments/PaymentsModal';
import { type IPayinObject } from 'api/resources/payin';

export interface IProposalButtonsProps {
	proposal: IProposal;
	org: IOrg;
	onApprove: (proposal: IProposal) => void;
	onDecline: (proposal: IProposal) => void;
	onUndo: (id: string) => void;
	onPayNow: (payItems: IPayinObject[]) => void;
	formStatus: EPaymentsFormStatus;
	activeId: string | null;
	showUndoButton: boolean;
}

const ProposalButtons = ({
	proposal,
	org,
	onApprove,
	onDecline,
	onUndo,
	onPayNow,
	formStatus,
	activeId,
	showUndoButton,
}: IProposalButtonsProps) => {
	const shouldShowActionButtons = () => {
		/* 
            Case 1: Organization has proposalsApproval enabled
            Logic:
            - If the proposal status is not approved  and not rejected
        */
		return (
			org.proposalsApproval &&
			!org.proposalsRequireSignature &&
			!isProposalStatusApproved(proposal.status) &&
			!isProposalStatusRejected(proposal.status)
		);
	};

	const shouldShowPayDeposit = () => {
		/*
            Case 2: 
            Logic:
			
			- if org.proposalsApproval is not enabled 
				- if the proposal status is not rejected
				- if the proposal is not fully paid
			- if org.proposalsApproval is enabled
				- if the proposal status is approved
				- if the proposal is not fully paid
        */
		const isFullyPaid =
			proposal.deposit > 0 &&
			proposal.deposit - proposal.alreadyDeposited === 0;

		const condition1 =
			!org.proposalsApproval &&
			!isProposalStatusRejected(proposal.status) &&
			!isFullyPaid;

		const condition2 =
			org.proposalsApproval &&
			isProposalStatusApproved(proposal.status) &&
			!isFullyPaid;

		return condition1 || condition2;
	};

	const shouldShowSignatureButtons = () => {
		/* 
            Case 3: Organization has proposalsApproval and proposalsRequireSignature enabled
            Logic:
            - If the proposal has a deposit
            - If the proposal has not already been deposited
            - If the proposal status is not approved
        */

		return (
			org.proposalsApproval &&
			org.proposalsRequireSignature &&
			isProposalStatusNotSet(proposal.status)
		);
	};

	const renderPayDepositButton = () => (
		<Button
			disabled={proposal.deposit === 0}
			variant="secondary"
			radius="full"
			size="xs"
			onClick={() => {
				onPayNow([
					{
						objectId: proposal.id,
						objectType: 'proposal',
					},
				]);
			}}
		>
			{formStatus === EPaymentsFormStatus.Loading &&
			activeId === proposal.id ? (
				<PaymentsSpinner />
			) : (
				<Icon name="credit-card" className="fill-white" />
			)}
			<span className="ml-2">Pay Deposit</span>
		</Button>
	);

	if (shouldShowActionButtons()) {
		return (
			<div className="inline-block">
				<div className="w-full flex flex-col items-center gap-2 md:flex-row lg:flex-row lg:justify-end">
					{showUndoButton && (
						<Button
							size="xs"
							variant="ghost"
							className="text-text text-sm font-normal"
							onClick={() => onUndo(proposal.id)}
						>
							Undo
						</Button>
					)}
					{shouldShowActionButtons() && (
						<>
							<Button
								variant="black"
								radius="full"
								className="w-full rounded-full"
								size="sm"
								onClick={() => onDecline(proposal)}
							>
								<span className="whitespace-nowrap flex text-white items-center">
									<Icon name="x-circle" className="mr-2 fill-white" />
									<span className="text-md md:text-sm lg:text-xs">Decline</span>
								</span>
							</Button>
							<Button
								variant="primary"
								radius="full"
								className="w-full rounded-full"
								size="sm"
								onClick={() => onApprove(proposal)}
							>
								<span className="whitespace-nowrap mx-3 flex items-center">
									<Icon name="check-circle" className="mr-2 fill-white" />
									<span className="text-md md:text-sm lg:text-xs">Approve</span>
								</span>
							</Button>
						</>
					)}
				</div>
			</div>
		);
	}

	if (shouldShowPayDeposit()) {
		return (
			<>
				{showUndoButton && (
					<Button
						size="xs"
						variant="ghost"
						className="text-text text-sm font-normal"
						onClick={() => onUndo(proposal.id)}
					>
						Undo
					</Button>
				)}
				{renderPayDepositButton()}
			</>
		);
	}

	if (shouldShowSignatureButtons()) {
		return (
			<div className="inline-block">
				<div className="w-full flex flex-col items-center gap-2 md:flex-row lg:flex-row lg:justify-end">
					<ButtonGroup variant="white">
						<Button
							className="w-full rounded-full"
							size="sm"
							onClick={() => onDecline(proposal)}
						>
							<span className="whitespace-nowrap flex items-center">
								<Icon
									name="x-circle"
									className="mr-2 fill-[--secondary-color]"
								/>
								<span className="text-md md:text-sm lg:text-xs">Decline</span>
							</span>
						</Button>
						<Button
							className="w-full rounded-full"
							size="sm"
							onClick={() => onApprove(proposal)}
						>
							<span className="whitespace-nowrap mx-3 flex items-center">
								<Icon
									name="check-circle"
									className="mr-2 fill-[--secondary-color]"
								/>
								<span className="text-md md:text-sm lg:text-xs">
									Approve & Sign
								</span>
							</span>
						</Button>
					</ButtonGroup>
				</div>
			</div>
		);
	}

	if (proposal.status === 'Rejected' && showUndoButton) {
		return (
			<Button
				size="xs"
				variant="ghost"
				className="text-text text-sm font-normal"
				onClick={() => onUndo(proposal.id)}
			>
				Undo
			</Button>
		);
	}

	return null;
};

ProposalButtons.displayedName = 'ProposalButtons';

export { ProposalButtons };
