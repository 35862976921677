import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { IPostItemsStatusPayload} from 'api/resources/items';
import { postItemsStatus } from 'api/resources/items';
import { ECacheKeys } from 'cache/CacheKeys';

export const usePostItemsStatus = (token: string) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (payload: IPostItemsStatusPayload[]) =>
			postItemsStatus(token, payload),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [ECacheKeys.Items],
			});
		},
	});
};
