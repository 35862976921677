import React, { forwardRef } from 'react';
import cn from 'classnames';
import selection from './selection.json';
import type { IUI, TSize } from 'ui/types/core';

interface IIconProps extends IUI {
	name: string; // FUTURE NOTE: this should be a union of all icon names for auto-complete
	size?: TSize | 'inherit';
	ariaLabel?: string;
	ariaHidden?: boolean;
	title?: string;
	desc?: string;
	viewBox?: string;
}

const Icon = forwardRef<SVGSVGElement, IIconProps>(
	(
		{
			name,
			className,
			size = 'inherit',
			title,
			desc,
			'data-testid': dataTestId,
			ariaLabel,
			ariaHidden = false,
			viewBox, // Option to provide custom viewBox
		},
		ref,
	) => {
		// Find the icon in the selection.json by its name
		const selectedIcon = selection.icons.find(
			(icon) => icon.properties.name === name,
		);

		if (!selectedIcon) {
			// eslint-disable-next-line no-console
			console.warn(`Icon with name "${name}" not found in selection.json`);
			return null;
		}

		const sizes = {
			xs: '16',
			sm: '20',
			md: '24',
			lg: '24',
		};

		// Use 'inherit' for width/height if the size is 'inherit', otherwise use predefined sizes
		const width = size === 'inherit' ? '1em' : sizes[size];
		const height = size === 'inherit' ? '1em' : sizes[size];

		// Dynamically use the icon's width for viewBox or use a default one if undefined
		const iconWidth = selectedIcon.icon.width || 1024;
		const customViewBox = viewBox || `0 0 ${iconWidth} 1024`;

		return (
			<div className="relative inline-block">
				<svg
					ref={ref}
					role="img"
					className={cn(className)}
					width={width}
					height={height}
					viewBox={customViewBox}
					data-testid={dataTestId}
					aria-label={ariaLabel || title}
					aria-hidden={ariaHidden}
				>
					{title ? <title>{title}</title> : null}
					{desc ? <desc>{desc}</desc> : null}
					{/* Map through the paths of the selected icon */}
					{selectedIcon.icon.paths.map((path, index) => (
						<path key={`${name}-path-${index}`} d={path} />
					))}
				</svg>
			</div>
		);
	},
);

Icon.displayName = 'Icon';

export { Icon };
export type { IIconProps };
