import React from 'react';

interface ILayoutContentProps {
	children: React.ReactNode;
	className?: string;
}

const LayoutContent: React.FC<ILayoutContentProps> = ({
	children,
	className,
}) => {
	return (
		<div
			className={`bg-backgroundGray border rounded-lg p-2 m-2 md:p-4 md:h-fit md:overflow-hidden lg:overflow-hidden  overflow-y-auto ${className}`}
		>
			{children}
		</div>
	);
};

export { LayoutContent };
