import React, { forwardRef, type ButtonHTMLAttributes } from 'react';
import { cva } from 'class-variance-authority';
import { type VariantProps } from 'class-variance-authority';
import cn from 'classnames';
import type { IUI, TVariant, TSize } from 'ui/types/core';

const buttonVariants = cva(
	'px-4 py-2 rounded-lg font-semibold transition-colors flex items-center justify-center',
	{
		variants: {
			variant: {
				primary:
					'bg-[#3338A1] text-white hover:bg-opacity-90 disabled:bg-gray-500 disabled:text-white disabled:opacity-50',
				secondary:
					'bg-gray-200 text-gray-800 hover:bg-opacity-90 disabled:bg-gray-500 disabled:text-white disabled:opacity-50',
				danger:
					'bg-red-600 text-white hover:bg-opacity-90 disabled:bg-red-500/50 disabled:text-white disabled:opacity-50',
				ghost:
					'bg-transparent text-gray-800 hover:bg-opacity-20 disabled:text-white disabled:opacity-50',
				link: 'bg-transparent text-blue-600 hover:text-blue-700 hover:underline disabled:text-gray-500 disabled:opacity-50',
				outline:
					'bg-transparent border border-gray-300 disabled:border-gray-500 disabled:text-gray-500 disabled:opacity-50',
			},
			size: {
				inherit: 'inherit',
				xs: 'text-xs',
				sm: 'text-sm',
				md: 'text-base',
				lg: 'text-lg',
			},
		},
		defaultVariants: {
			variant: 'primary',
			size: 'md',
		},
	},
);

interface IButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants>,
		IUI {
	size?: TSize;
	variant?: TVariant;
	'aria-label'?: string;
	'aria-labelledby'?: string;
	'data-testid'?: string;
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>(
	(
		{
			className,
			size = 'md',
			variant = 'primary',
			disabled = false,
			onClick,
			'data-testid': dataTestId,
			children,
			'aria-label': ariaLabel,
			'aria-labelledby': ariaLabelledBy,
			id,
		},
		ref,
	) => {
		const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			if (disabled) {
				event.preventDefault();
				return;
			}
			onClick?.(event);
		};

		return (
			<button
				ref={ref}
				className={cn(buttonVariants({ size, variant }), className)}
				disabled={disabled}
				onClick={handleClick}
				aria-label={ariaLabel}
				aria-labelledby={ariaLabelledBy}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(id ? { id } : {})}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(dataTestId ? { 'data-testid': dataTestId } : {})}
			>
				{children}
			</button>
		);
	},
);

Button.displayName = 'Button';

export { Button };
export { type IButtonProps };
