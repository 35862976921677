import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetProposals } from 'api/hooks/useGetProposals';
import { usePostProposals } from 'api/hooks/usePostProposals';
import type { IProposal, TProposalStatuses } from 'api/resources/proposals';
import {
	isProposalStatusRejected,
	useGetProposalFile,
	type IProposalPatchPayload,
} from 'api/resources/proposals';
import {
	ActionItems,
	type ISummaryItem,
	SummaryCard,
} from 'features/Layout/PageActions';
import { Modal, Button, Icon, Card } from 'ui';
import { useStackAnimation } from 'app/hooks/useStackAnimation';
import { motion } from 'framer-motion';
import { ProposalCard } from './partials/ProposalCard';
import { ActionModalContent } from './partials/ActionModalContent';
import { CustomModalTitle } from './partials/CustomModalTitle';
import { useForm, FormProvider } from 'react-hook-form';
import { EStatus } from 'app/ui/types/core';
import { usePayments } from 'app/features/Payments/usePayments';
import type { IPayinObject } from 'api/resources/payin';
import { useAuth } from 'app/features/auth/useAuth';
import { EPaymentsFormStatus } from 'app/features/Payments/PaymentsModal';
import { PaymentsSpinner } from 'app/features/Payments/partials/PaymentsSpinner';
import { useLayout } from 'app/features/Layout/hooks/useLayout';

interface IProposalFormData {
	signature: string;
}

export const Proposals = () => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token') || '';
	const { org } = useAuth();
	const { data, isLoading } = useGetProposals({ token });
	const { mutate: postProposalStatus, isPending } = usePostProposals(token);
	const { openFile } = useGetProposalFile(token);
	const [selectedProposals, setSelectedProposals] = useState<IProposal[]>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { paymentsModal, loadPaymentModal, paymentsFormStatus } = usePayments();

	const methods = useForm<IProposalFormData>({
		mode: 'onChange',
		defaultValues: {
			signature: '',
		},
	});

	const handleSelectAll = () =>
		setSelectedProposals(
			proposals?.filter(
				(proposal) =>
					!isProposalStatusRejected(proposal.status) &&
					proposal.deposit - proposal.alreadyDeposited !== 0,
			) ?? [],
		);

	const proposals = data?.data;

	const headerActions = [];

	if (proposals && proposals.length > 0) {
		headerActions.push(
			<Button
				key="btn-select-all"
				variant="white"
				size="sm"
				onClick={handleSelectAll}
			>
				Select All
			</Button>,
		);
	}

	if (org?.proposalsApproval) {
		headerActions.push(
			<Button
				key="btn-approve-sign-all"
				onClick={() => {
					if (org?.proposalsRequireSignature) {
						setIsModalOpen(true);
					} else {
						handleBulkApprovals();
					}
				}}
				disabled={selectedProposals.length === 0}
				size="sm"
				variant="white"
			>
				<Icon name="check-circle" className="mr-2 fill-[--primary-color]" />
				{org.proposalsRequireSignature ? (
					<>Approve & Sign Selected</>
				) : (
					<>Approve Selected</>
				)}
			</Button>,
		);
	} else {
		headerActions.push(
			<Button
				key="btn-pay-all"
				size="sm"
				variant="white"
				disabled={selectedProposals.length === 0}
				onClick={() => {
					handleBatchPay(
						selectedProposals.map((proposal) => ({
							objectId: proposal.id,
							objectType: 'proposal',
						})),
					);
				}}
			>
				{paymentsFormStatus === EPaymentsFormStatus.Loading ? (
					<PaymentsSpinner />
				) : (
					<Icon name="credit-card" className="fill-[--primary-color]" />
				)}
				<span className="ml-2">Pay All</span>
			</Button>,
		);
	}

	useLayout(
		{
			header: {
				title: `Proposals ${org?.project?.name ? `for ${org?.project?.name}` : ''}`,
				icon: 'document-duplicate',
				actions: headerActions,
			},
		},
		useMemo(
			() => [selectedProposals, proposals],
			[selectedProposals, proposals],
		),
	);

	const totalProposed = proposals?.reduce(
		(sum, proposal) => sum + proposal.total,
		0,
	);

	const depositRequested = proposals?.reduce(
		(sum, proposal) => sum + (proposal.deposit - proposal.alreadyDeposited),
		0,
	);

	const items: ISummaryItem[] = [
		{ label: 'Total Proposed Price', amount: totalProposed ?? 0 },
		{ label: 'Total Deposit Due', amount: depositRequested ?? 0 },
	];

	const handleModalApproval = (formData: IProposalFormData) => {
		if (formData.signature) {
			const payloads: IProposalPatchPayload[] = selectedProposals.map(
				(proposal) => ({
					Id: parseInt(proposal.id),
					Status: EStatus.Approved as TProposalStatuses,
					Signature: formData.signature,
				}),
			);
			setIsModalOpen(false);
			postProposalStatus(payloads, {
				onSuccess: () => {
					setSelectedProposals([]);
					methods.reset();
				},
			});
		}
	};

	const handleUndo = (id: string) => {
		setIsModalOpen(false);
		postProposalStatus([
			{
				Id: parseInt(id),
				Status: EStatus.NotSet as TProposalStatuses,
				Signature: '',
			},
		]);
	};

	const handleDecline = (proposal: IProposal) => {
		const payload: IProposalPatchPayload[] = [
			{
				Id: parseInt(proposal.id),
				Status: EStatus.Rejected as TProposalStatuses,
			},
		];
		postProposalStatus(payload);
	};

	const toggleSelection = (proposal: IProposal) => {
		setSelectedProposals((prev) =>
			prev.some((p) => p.id === proposal.id)
				? prev.filter((p) => p.id !== proposal.id)
				: [...prev, proposal],
		);
	};

	const handleApprovals = (proposal: IProposal) => {
		if (org?.proposalsRequireSignature) {
			setSelectedProposals([proposal]);
			setIsModalOpen(true);
		} else {
			setSelectedProposals([]);
			setIsModalOpen(false);
			postProposalStatus(
				[
					{
						Id: parseInt(proposal.id),
						Status: EStatus.Approved as TProposalStatuses,
						Signature: '',
					},
				],
				{
					onSuccess: () => {
						setSelectedProposals([]);
						methods.reset();
					},
				},
			);
		}
	};

	const motionProps = useStackAnimation(0.3, !isLoading);

	const totalItems = selectedProposals.reduce(
		(sum, proposal) => sum + proposal.quantity,
		0,
	);

	const handleBatchPay = (payItems: IPayinObject[]) => {
		loadPaymentModal(payItems);
	};

	const handleBulkApprovals = () => {
		const payloads: IProposalPatchPayload[] = selectedProposals.map(
			(proposal) => ({
				Id: parseInt(proposal.id),
				Status: EStatus.Approved as TProposalStatuses,
				Signature: '',
			}),
		);

		postProposalStatus(payloads, {
			onSuccess: () => {
				setSelectedProposals([]);
				methods.reset();
			},
		});
	};

	return (
		<>
			{proposals && proposals?.length > 0 ? (
				<>
					<SummaryCard items={items} isDataReady={!isLoading} />
					<ActionItems title="Proposals to Review">
						{proposals?.map((proposal, index) => (
							<motion.div
								key={index}
								initial={motionProps.initial}
								animate={motionProps.animate}
								custom={index}
								className="w-full"
							>
								<ProposalCard
									key={proposal.id}
									proposal={proposal}
									isSelected={selectedProposals.some(
										(p) => p.id === proposal.id,
									)}
									onSelect={toggleSelection}
									onOpenPdf={() => openFile(proposal.pdf)}
									onApprove={handleApprovals}
									onDecline={handleDecline}
									onPayNow={handleBatchPay}
									onUndo={handleUndo}
									formStatus={paymentsFormStatus}
								/>
							</motion.div>
						))}
					</ActionItems>
				</>
			) : !isLoading ? (
				<Card className="h-full flex flex-col rounded-xl p-4 bg-background w-fit md:rounded-3xl lg:rounded-3xl md:p-5 lg:p-5">
					<div className="h-full gap-1 flex flex-col items-center text-center">
						<Icon
							name="check-circle"
							size="lg"
							className="text-primary mb-4 fill-primary-badge"
						/>

						<h3 className="text-lg font-semibold text-text">
							No Proposals To Display
						</h3>
						<p className="text-sm text-gray-500">
							Check back later for new updates or tasks to complete.
						</p>
					</div>
				</Card>
			) : null}

			<Modal
				isOpen={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
					setSelectedProposals([]);
					methods.reset();
				}}
				showCloseButton={false}
				className="w-[35%]"
			>
				<CustomModalTitle
					proposals={selectedProposals.map((p) => ({
						id: p.id,
						title: p.title,
						date: p.date,
					}))}
					totalItems={totalItems}
				/>
				<FormProvider
					resetField={methods.resetField}
					getFieldState={methods.getFieldState}
					handleSubmit={methods.handleSubmit}
					watch={methods.watch}
					setError={methods.setError}
					getValues={methods.getValues}
					reset={methods.reset}
					setValue={methods.setValue}
					clearErrors={methods.clearErrors}
					control={methods.control}
					register={methods.register}
					unregister={methods.unregister}
					setFocus={methods.setFocus}
					trigger={methods.trigger}
					formState={methods.formState}
				>
					<form onSubmit={methods.handleSubmit(handleModalApproval)}>
						<div className="p-3">
							<ActionModalContent />
							<div className="w-full flex gap-1 justify-between mt-6">
								<Button
									variant="secondary"
									className="w-1/2"
									onClick={() => {
										setIsModalOpen(false);
										setSelectedProposals([]);
										methods.reset();
									}}
								>
									Cancel
								</Button>
								<Button
									variant="primary"
									className="w-1/2"
									type="submit"
									disabled={!methods.formState.isValid || isPending}
								>
									<Icon name="check-circle" className="mr-2 fill-white" />
									{isPending ? 'Submitting...' : 'Accept'}
								</Button>
							</div>
						</div>
					</form>
				</FormProvider>
			</Modal>

			{paymentsModal}
		</>
	);
};

Proposals.displayName = 'Proposals';
