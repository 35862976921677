import * as React from 'react';
import cn from 'classnames';
import type { IUI } from 'ui/types/core';

export interface IInputProps
	extends React.InputHTMLAttributes<HTMLInputElement>,
		IUI {
	value?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	error?: boolean;
	defaultValue?: string;
}

const Input = React.forwardRef<HTMLInputElement, IInputProps>(
	(
		{
			className,
			type = 'text',
			value,
			defaultValue,
			onChange,
			placeholder,
			error = false,
			id,
			'data-testid': dataTestId,
			disabled,
		},
		ref,
	) => {
		const [internalValue, setInternalValue] = React.useState(
			value !== undefined ? value : defaultValue || '',
		);

		const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			if (disabled) return;

			if (value === undefined) {
				setInternalValue(event.target.value);
			}

			if (onChange) {
				onChange(event);
			}
		};

		return (
			<input
				type={type}
				className={cn(
					'flex h-10 w-full rounded-md border bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
					error
						? 'border-red-500 focus-visible:ring-red-500'
						: 'border-input focus-visible:ring-ring',
					className,
				)}
				ref={ref}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(value !== undefined
					? { value }
					: defaultValue !== undefined
						? { defaultValue: internalValue }
						: {})}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(id ? { id } : {})}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(dataTestId ? { 'data-testid': dataTestId } : {})}
				onChange={handleChange}
				placeholder={placeholder}
				disabled={disabled}
			/>
		);
	},
);

Input.displayName = 'Input';

export { Input };
