import React, { useMemo } from 'react';
import { useGetAuthCheck } from 'api/hooks/useGetAuthCheck';
import { AuthContext } from 'app/features/auth/AuthContext';
import { useSearchParams } from 'react-router-dom';
import { Loader } from 'app/ui';

interface IAuthProvider {
	children: React.ReactNode;
}
export const AuthProvider = ({ children }: IAuthProvider) => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token') || '';

	const { isLoading, isError, data } = useGetAuthCheck({ token });

	const org = data?.data || null;

	const value = useMemo(
		() => ({
			isAuthenticated: !isError,
			org,
		}),
		[isError, org],
	);

	if (isLoading) {
		return (
			<div className="min-h-screen min-w-screen flex items-center justify-center">
				<Loader />
			</div>
		);
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
