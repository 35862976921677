import React from 'react';
import { useTheme } from 'contexts/ThemeContext';
import { Button, Icon } from 'ui';

export const ThemeToggle: React.FC = () => {
	const { theme, toggleTheme } = useTheme();
	return (
		<Button variant="ghost" onClick={toggleTheme}>
			<Icon
				name="moon"
				className="cursor-pointer fill-inherit"
				title={`Toggle ${theme === 'light' ? 'Dark' : 'Light'} Mode`}
			/>
		</Button>
	);
};
