import React, { useState } from 'react';
import { Card, Badge, Button, Icon, Checkbox } from 'ui';
import { FormatCurrency } from 'utils/features/formatCurrency';
import type { IProposal } from 'api/resources/proposals';
import { isProposalStatusRejected } from 'api/resources/proposals';
import { formatDate } from 'utils/date/date';
import type { EPaymentsFormStatus } from 'app/features/Payments/PaymentsModal';
import { type IPayinObject } from 'api/resources/payin';
import { useAuth } from 'app/features/auth/useAuth';
import { useFeature } from 'app/features/FeatureFlags/useFeature';
import { EFeatureFlags } from 'app/features/FeatureFlags/featureFlags';
import { ProposalStatus } from 'app/pages/Proposals/partials/ProposalStatus';
import { ProposalButtons } from 'app/pages/Proposals/partials/ProposalButtons';

interface IProposalCardProps {
	proposal: IProposal;
	isSelected: boolean;
	onSelect: (proposal: IProposal) => void;
	onOpenPdf: () => void;
	onApprove: (proposal: IProposal) => void;
	onDecline: (proposal: IProposal) => void;
	onPayNow: (payItems: IPayinObject[]) => void;
	onUndo: (id: string) => void;
	formStatus: EPaymentsFormStatus;
}

export const ProposalCard = ({
	proposal,
	isSelected,
	onSelect,
	onOpenPdf,
	onApprove,
	onDecline,
	onPayNow,
	onUndo,
	formStatus,
}: IProposalCardProps) => {
	const [activeId] = useState<string | null>(null);
	const { org } = useAuth();
	const { isFeatureEnabled: showUndoButton } = useFeature(
		EFeatureFlags.UndoProposals,
	);

	return (
		<Card border="2xl" className="w-full p-5 bg-background shadow-lg">
			<div className="flex justify-between items-center mb-2">
				<div className="w-full flex items-center justify-between md:w-fit lg:w-fit md:items-center lg:items-center">
					<h3 className="text-lg font-normal text-primary-badge">
						Proposal No. {proposal.number} &mdash; {proposal.title}
					</h3>
					{proposal.pdf && (
						<Button
							variant="ghost"
							size="sm"
							className="text-primary"
							onClick={onOpenPdf}
						>
							<Badge
								size="xs"
								className="rounded-md bg-purple-200 border-[1.5px] border-purple-200 text-purple-800"
							>
								<div className="text-purple-800 flex flex-row items-center gap-1">
									<span>PDF</span>
									<Icon name="arrow-up-right" className="fill-purple-800" />
								</div>
							</Badge>
						</Button>
					)}
				</div>

				{!isProposalStatusRejected(proposal.status) &&
					proposal.deposit - proposal.alreadyDeposited !== 0 && (
						<>
							<div className="hidden md-lg:block lg:block h-fit px-2 py-1 bg-backgroundGray rounded-lg items-center">
								<Checkbox
									label="Select"
									checked={isSelected}
									onChange={() => onSelect(proposal)}
								/>
							</div>
							<div className="block h-fit px-2 py-1 bg-backgroundGray rounded-lg items-center md-lg:hidden lg:hidden">
								<Checkbox
									checked={isSelected}
									onChange={() => onSelect(proposal)}
									hideLabel
									aria-label="Select proposal"
								/>
							</div>
						</>
					)}
			</div>
			<p className="text-xs text-gray-600 text-muted-foreground mb-4">
				Date: {formatDate(proposal.date)} | Items: {proposal.quantity} | Total
				Price: ${proposal.total ? proposal.total.toFixed(2) : '0.00'}
			</p>

			<div className="flex flex-col xl:flex-row gap-2 p-3 rounded-lg border-[1.5px] border-backgroundGray shadow-sm bg-gray">
				<div className="grow 2xl:basis-1/2">
					<div className="flex flex-col gap-2 xl:flex-row md:flex-1 grow text-2xl">
						<div className="grow basis-1/2">
							<FormatCurrency
								value={proposal.deposit}
								className="font-dm-sans mr-1"
							/>
							<span className="text-xs text-gray-500 font-normal text-muted-foreground">
								Requested
							</span>
						</div>
						<div className="grow basis-1/2">
							<FormatCurrency
								value={proposal.alreadyDeposited}
								className="font-dm-sans mr-1"
							/>
							<span className="text-xs text-gray-500 font-normal text-muted-foreground">
								Paid
							</span>
						</div>
						<div className="grow basis-1/2">
							<FormatCurrency
								value={proposal.deposit - proposal.alreadyDeposited}
								className="font-dm-sans mr-1"
							/>
							<span className="text-xs text-gray-500 font-normal text-muted-foreground whitespace-nowrap">
								Due Now
							</span>
						</div>
					</div>
				</div>
				<div className="grow 2xl:basis-1/2">
					<div className="flex h-full justify-center xl:justify-end items-center">
						{org && <ProposalStatus org={org} proposal={proposal} />}

						{org && (
							<ProposalButtons
								proposal={proposal}
								org={org}
								onApprove={onApprove}
								onDecline={onDecline}
								onUndo={onUndo}
								onPayNow={onPayNow}
								formStatus={formStatus}
								activeId={activeId}
								showUndoButton={showUndoButton}
							/>
						)}
					</div>
				</div>
			</div>
		</Card>
	);
};
