import { EFeatureFlags } from 'app/features/FeatureFlags/featureFlags';
import { useFeature } from 'app/features/FeatureFlags/useFeature';
import { LayoutContext } from 'app/features/Layout/LayoutContext';
import { ThemeToggle } from 'app/ui';
import React, { useContext } from 'react';

const initialValueForThemeFlag = localStorage.getItem(
	EFeatureFlags.EnableDarkMode,
);

export const DefaultLayoutTopRight = () => {
	const { header } = useContext(LayoutContext) || {};

	const { isFeatureEnabled: showThemeToggle } = useFeature(
		EFeatureFlags.EnableDarkMode,
		initialValueForThemeFlag === 'true',
	);

	return (
		<div className="flex items-center gap-2">
			{showThemeToggle && <ThemeToggle />}
			{header?.actions}
		</div>
	);
};

DefaultLayoutTopRight.displayName = 'DefaultLayoutTopRight';
