import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Modal, Card, Carousel, Icon } from 'ui';
import { usePostItemsStatus } from 'api/hooks/usePostItemsStatus';
import { type TItemStatuses } from 'api/resources/items';
import { motion, AnimatePresence } from 'framer-motion';
import { ItemsTop, ItemCard, ItemActionHeader } from './partials';
import { useGetItems } from 'api/hooks/useGetItems';
import { useSearchParams } from 'react-router-dom';
import { type IItem } from 'api/resources/items';
import { EStatus } from 'app/ui/types/core';
import { useAuth } from 'app/features/auth/useAuth';
import { useLayout } from 'app/features/Layout/hooks/useLayout';

export const CustomModalTitle = ({ title }: { title: string }) => (
	<h2 className="flex text-sm font-sans font-semibold text-left py-[10px]">
		{title}
	</h2>
);
export interface IItemsToUpdate {
	id: string;
	status: TItemStatuses;
}

export const Items = () => {
	const [selectedItem, setSelectedItem] = useState<IItem | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedItems, setSelectedItems] = useState<string[]>([]);
	const [filter, setFilter] = useState<TItemStatuses | 'all'>('all');
	const [bulkAction] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);

	const [searchParams] = useSearchParams();
	const token = searchParams.get('token') || '';
	const { org } = useAuth();

	const { data } = useGetItems({ token });

	const items = data?.data;

	const { mutate: postItemsStatus } = usePostItemsStatus(token);

	useLayout(
		{
			header: {
				title: `Items ${org?.project?.name ? `for ${org?.project?.name}` : ''}`,
				icon: 'queue-list',
				actions: org?.itemsApproval
					? [
							<ItemActionHeader
								key="item-action-header"
								items={items || []}
								filter={filter}
								setFilter={setFilter}
								isFilterDropdownOpen={isFilterDropdownOpen}
								setIsFilterDropdownOpen={setIsFilterDropdownOpen}
							/>,
						]
					: [],
			},
		},
		useMemo(
			() => [
				org?.project?.name,
				org?.itemsApproval,
				items,
				isFilterDropdownOpen,
				filter,
			],
			[
				org?.project?.name,
				org?.itemsApproval,
				items,
				isFilterDropdownOpen,
				filter,
			],
		),
	);

	const filteredItems = items?.filter((item: IItem) => {
		if (!items) return [];

		const matchesFilter = filter === 'all' || filter === item.status;

		const matchesSearch = searchTerm
			? item?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
				item?.description?.toLowerCase().includes(searchTerm.toLowerCase())
			: true;

		return matchesFilter && matchesSearch;
	});

	useEffect(() => {
		setSelectedItems([]);
	}, [filter, searchTerm]);

	const handleSelectAll = () =>
		setSelectedItems(
			searchTerm || filter
				? filteredItems.map((item: IItem) => item.id)
				: items?.map((item: IItem) => item.id) || [],
		);

	const openModal = useCallback((item: IItem, imageIndex: number = 0) => {
		setSelectedItem(item);
		setSelectedImageIndex(imageIndex);
		setIsModalOpen(true);
	}, []);

	const closeModal = useCallback(() => {
		setIsModalOpen(false);
		setSelectedItem(null);
		setSelectedImageIndex(0);
	}, []);

	const toggleItemSelection = useCallback((itemId: string) => {
		setSelectedItems((prev) =>
			prev.includes(itemId)
				? prev.filter((id) => id !== itemId)
				: [...prev, itemId],
		);
	}, []);

	const handleButtonGroupChange = useCallback(
		(itemId: string, action: TItemStatuses | null) => {
			if (action) {
				postItemsStatus([{ id: itemId, status: action }]);
			}
		},
		[postItemsStatus],
	);

	const handleUndo = useCallback(
		(itemId: string) => {
			postItemsStatus([{ id: itemId, status: EStatus.NotSet }]);
		},
		[postItemsStatus],
	);

	const handleApproveAll = useCallback(() => {
		if (selectedItems.length === 0) return;

		const itemsToUpdate: IItemsToUpdate[] = selectedItems.map((itemId) => ({
			id: itemId,
			status: 'approved' as TItemStatuses,
		}));

		postItemsStatus(itemsToUpdate, {
			onSuccess: () => {
				setSelectedItems([]);
			},
		});
	}, [selectedItems, postItemsStatus]);

	const handleDeclineAll = useCallback(() => {
		if (selectedItems.length === 0) return;

		const itemsToUpdate: IItemsToUpdate[] = selectedItems.map((itemId) => ({
			id: itemId,
			status: 'Rejected' as TItemStatuses,
		}));

		postItemsStatus(itemsToUpdate, {
			onSuccess: () => {
				setSelectedItems([]);
			},
		});
	}, [selectedItems, postItemsStatus]);

	return items?.length > 0 ? (
		<>
			<Card
				border="2xl"
				elevation={2}
				className="bg-background mx-auto px-4 lg:px-10 py-4 lg:py-6"
			>
				<ItemsTop
					showSelectAll={!!org?.itemsApproval && items?.length > 0}
					handleSelectAll={handleSelectAll}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					itemCount={filteredItems.length}
					selectedItemsCount={selectedItems.length}
					onDeclineAll={handleDeclineAll}
					onApproveAll={handleApproveAll}
					isOpen={bulkAction}
				/>

				<AnimatePresence>
					<motion.div className="space-y-4 text-fix">
						{filteredItems.map((item: IItem) => {
							return (
								<ItemCard
									key={item.id}
									item={item}
									isSelected={selectedItems.includes(item.id)}
									onSelect={toggleItemSelection}
									onImageClick={(imageIndex) => openModal(item, imageIndex)}
									onButtonGroupChange={handleButtonGroupChange}
									onUndo={handleUndo}
									itemsApproval={org?.itemsApproval}
								/>
							);
						})}
					</motion.div>
				</AnimatePresence>
			</Card>

			{selectedItem && (
				<Modal
					isOpen={isModalOpen}
					onClose={closeModal}
					title={selectedItem.title}
					overrides={{
						Title: CustomModalTitle,
					}}
				>
					<Carousel
						displayRest={selectedItem.images.length > 1 ? 'images' : 'none'}
						images={selectedItem.images.map((imageId: string) => ({
							src: `${process.env.PUBLIC_APP_API_BASE_URL}/file/${token}/${imageId}?maxHeight=640`,
							alt: '',
						}))}
						initialSlide={selectedImageIndex}
					/>
				</Modal>
			)}
		</>
	) : (
		<Card
			elevation={2}
			className="h-full flex flex-col rounded-xl p-4 bg-background w-fit md:rounded-3xl lg:rounded-3xl md:p-5 lg:p-5"
		>
			<div className="h-full gap-1 flex flex-col items-center text-center">
				<Icon
					name="check-circle"
					size="lg"
					className="text-primary mb-4 fill-primary-badge"
				/>

				<h3 className="text-lg font-semibold text-text">No Items To Display</h3>
				<p className="text-sm text-gray-500">
					Check back later for new updates or tasks to complete.
				</p>
			</div>
		</Card>
	);
};

Items.displayName = 'Items';
