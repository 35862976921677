import { useSuspenseQuery } from '@tanstack/react-query';
import type { IGetDemosParams, TGetDemosResponse } from 'api/resources/demos';
import { getDemos } from 'api/resources/demos';

export const useGetSuspenseDemo = (params: IGetDemosParams) => {
	const query = useSuspenseQuery<TGetDemosResponse>({
		queryKey: ['suspenseDemo'],
		queryFn: () => getDemos(params),
	});

	return query;
};
