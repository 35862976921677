import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useOverrides } from 'app/hooks/useOverrides';
import {
	ModalCloseButton,
	type IModalCloseButtonProps,
} from './ModalPartials/ModalCloseButton';
import {
	ModalContent,
	type IModalContentProps,
} from './ModalPartials/ModalContent';
import { ModalTitle, type IModalTitleProps } from './ModalPartials/ModalTitle';
import type { IUI } from 'ui/types/core';

type TModalPartials = {
	CloseButton: React.FC<IModalCloseButtonProps>;
	Content: React.FC<IModalContentProps>;
	Title: React.FC<IModalTitleProps>;
};

interface IModalOverrides {
	CloseButton?: React.FC<IModalCloseButtonProps>;
	Content?: React.FC<IModalContentProps>;
	Title?: React.FC<IModalTitleProps>;
}

export interface IModalProps extends IUI {
	isOpen: boolean;
	onClose: () => void;
	title?: string;
	overrides?: IModalOverrides;
	showCloseButton?: boolean;
}

const Modal: React.FC<IModalProps> = ({
	isOpen,
	onClose,
	title,
	children,
	overrides,
	showCloseButton = true,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);

	const defaultModalPartials: TModalPartials = {
		CloseButton: ModalCloseButton,
		Content: ModalContent,
		Title: ModalTitle,
	};

	const partials = useOverrides<TModalPartials>(
		defaultModalPartials,
		overrides,
	);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape' && isOpen) {
				onClose();
			}
		};

		const modalElement = modalRef.current;
		modalElement?.addEventListener('keydown', handleKeyDown);

		if (isOpen) {
			modalElement?.focus();
		}

		return () => {
			modalElement?.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen, onClose]);

	if (!isOpen) return null;

	const CloseButtonComponent = partials.CloseButton;
	const ContentComponent = partials.Content;
	const TitleComponent = partials.Title;

	return ReactDOM.createPortal(
		<div
			ref={modalRef}
			tabIndex={-1}
			className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50"
		>
			<div
				className="bg-background rounded-xl shadow-lg p-4 pt-2 relative border border-gray w-full max-w-xl max-h-[80vh] flex flex-col overflow-hidden"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="relative mb-4 flex items-center justify-between">
					{title && <TitleComponent title={title} />}
					<div className="flex-grow flex justify-end">
						{showCloseButton && (
							<CloseButtonComponent
								onClick={onClose}
								aria-label="Close modal"
							/>
						)}
					</div>
				</div>

				<ContentComponent>{children}</ContentComponent>
			</div>
		</div>,
		document.body,
	);
};

export { Modal };
