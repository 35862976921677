import React, { forwardRef, type ButtonHTMLAttributes } from 'react';
import { cva } from 'class-variance-authority';
import { type VariantProps } from 'class-variance-authority';
import cn from 'classnames';
import type { IUI, TVariant, TSize, TRadius } from 'ui/types/core';

export const buttonVariants = cva(
	'px-4 py-2 transition-colors flex items-center justify-center',
	{
		variants: {
			variant: {
				primary:
					'bg-[--primary-color] text-white hover:bg-opacity-90 disabled:bg-gray-400 disabled:text-white disabled:opacity-50',
				secondary:
					'bg-[--secondary-color] text-white hover:bg-opacity-90 disabled:bg-gray-400 disabled:text-white disabled:opacity-50',
				danger:
					'bg-red-600 text-white hover:bg-opacity-90 disabled:bg-red-500/50 disabled:text-white disabled:opacity-50',
				ghost:
					'bg-transparent text-gray-800 hover:bg-opacity-20 disabled:text-white disabled:opacity-50',
				link: 'bg-transparent text-blue-600 hover:text-blue-700 hover:underline disabled:text-gray-500 disabled:opacity-50',
				black:
					'bg-black text-white hover:bg-opacity-90 disabled:bg-gray-400 disabled:text-white disabled:opacity-50',
				default:
					'bg-gray-100 text-gray-700 hover:bg-opacity-90 disabled:text-gray-500 disabled:opacity-50',
				white:
					'bg-white border border-gray-300 text-gray-600 hover:bg-opacity-90 disabled:bg-gray-100',
			},
			outline: {
				false: null,
			},
			radius: {
				xs: 'rounded-xs',
				sm: 'rounded-sm',
				md: 'rounded-md',
				lg: 'rounded-lg',
				xl: 'rounded-xl',
				'2xl': 'rounded-2xl',
				'3xl': 'rounded-3xl',
				none: 'rounded-none',
				full: 'rounded-full',
			},
			size: {
				inherit: 'inherit',
				xs: 'text-xs',
				sm: 'text-sm',
				md: 'text-base',
				lg: 'text-lg',
			},
			disabled: {
				false: null,
				true: ['disabled:opacity-50', '!cursor-default'],
			},
		},
		compoundVariants: [
			{
				outline: true,
				variant: 'primary',
				class:
					'border !bg-transparent border-[--primary-color] !text-[--primary-color]',
			},
			{
				outline: true,
				variant: 'secondary',
				class:
					'border !bg-transparent border-[--secondary-color] !text-[--secondary-color]',
			},
			{
				outline: true,
				variant: 'ghost',
				class: 'border border-transparent text-gray-800',
			},
			{
				outline: true,
				variant: 'link',
				class:
					'border border-transparent text-blue-600 hover:text-blue-700 hover:underline',
			},
			{
				outline: true,
				variant: 'danger',
				class: 'border bg-transparent border-red-500 !text-red-500',
			},
			{
				outline: true,
				variant: 'black',
				class: 'border bg-transparent border-gray-800 !text-black',
			},
			{
				outline: true,
				variant: 'default',
				class: 'border !bg-gray-100 !border-gray-300 !text-gray-600',
			},
			{
				outline: true,
				variant: 'white',
				class: 'border !border-gray-700 !text-gray-700',
			},
		],
		defaultVariants: {
			variant: 'primary',
			size: 'md',
		},
	},
);

interface IButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants>,
		IUI {
	size?: TSize;
	variant?: TVariant;
	radius?: TRadius;
	disabled?: boolean;
	outline?: boolean;
	'aria-label'?: string;
	'aria-labelledby'?: string;
	'data-testid'?: string;
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>(
	(
		{
			className,
			size = 'md',
			variant = 'default',
			radius = 'lg',
			outline = false,
			disabled = false,
			onClick,
			'data-testid': dataTestId,
			children,
			'aria-label': ariaLabel,
			'aria-labelledby': ariaLabelledBy,
			id,
		},
		ref,
	) => {
		const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			if (disabled) {
				event.preventDefault();
				return;
			}
			onClick?.(event);
		};

		return (
			<button
				ref={ref}
				data-variant={variant}
				className={cn(
					'dmui-button',
					buttonVariants({ size, variant, outline, disabled, radius }),
					className,
				)}
				disabled={disabled}
				onClick={handleClick}
				aria-label={ariaLabel}
				aria-labelledby={ariaLabelledBy}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(id ? { id } : {})}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(dataTestId ? { 'data-testid': dataTestId } : {})}
			>
				{children}
			</button>
		);
	},
);

Button.displayName = 'Button';

export { Button };
export { type IButtonProps };
