import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postMutationDemo } from 'api/resources/demos';

export const usePostMutationDemo = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: postMutationDemo,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['demoPost'],
			});
		},
	});
};
