import React from 'react';
import { Card, Checkbox, Button, ButtonGroup, Icon } from 'ui';
import { FormatCurrency } from 'utils/features/formatCurrency';
import { ItemThumbs } from './ItemThumbs';
import { type IItems } from 'api/resources/overviews';

interface IItemCardProps {
	item: IItems;
	isSelected: boolean;
	onSelect: (id: string) => void;
	onImageClick: (imageIndex: number) => void;
	onButtonGroupChange: (
		id: string,
		activeId: 'approved' | 'declined' | null,
	) => void;
	onUndo: (id: string) => void;
}

export const ItemCard: React.FC<IItemCardProps> = ({
	item,
	isSelected,
	onSelect,
	onImageClick,
	onButtonGroupChange,
	onUndo,
}) => {
	const currentActiveId =
		item.status === 'approved'
			? 'approved'
			: item.status === 'declined'
				? 'declined'
				: null;

	return (
		<Card
			className={`overflow-hidden rounded-xl bg-white shadow-xl ${
				isSelected ? 'border-2 !border-[#3338A1]' : ''
			}`}
			border="lg"
		>
			<div className="flex flex-col md-lg:flex-col lg:flex-row">
				<div className="w-full p-4 md:w-full md-lg:w-full lg:w-1/3">
					<ItemThumbs images={item.images} onImageClick={onImageClick} />
				</div>
				<div className="w-full p-4 flex flex-col justify-between md:w-full md-lg:w-full lg:w-2/3">
					<div>
						<div className="flex justify-between items-start">
							<div className="flex w-full flex-row justify-between">
								<div className="flex flex-col">
									<h3 className="text-lg text-primary-badge font-semibold mb-2">
										{item.title}
									</h3>
									<p className="text-sm text-gray-600 mb-2">
										Quantity: {item.quantity} | Unit Price: $
										{item.unitPrice.toFixed(2)}
									</p>
								</div>

								<div className="hidden md-lg:block lg:block h-fit px-2 py-1 bg-gray-200 rounded-lg items-center">
									<Checkbox
										checked={isSelected}
										onChange={() => onSelect(item.id)}
										label="Select"
									/>
								</div>
								<div className="block h-fit px-2 py-1 bg-gray-200 rounded-lg items-center md-lg:hidden lg:hidden">
									<Checkbox
										checked={isSelected}
										onChange={() => onSelect(item.id)}
										hideLabel
										aria-label="Select item"
									/>
								</div>
							</div>
						</div>
						<p className="text-sm text-gray-600 mb-2">{item.description}</p>
					</div>
					<div className="w-full flex flex-col mt-4 lg:flex-row lg:justify-between lg:mt-0">
						<p className="flex items-baseline gap-2 mb-2 lg:mb-0">
							<FormatCurrency value={item.total} className="text-2xl" />
							<span className="text-xs text-gray-500 font-light">
								Total Price
							</span>
						</p>

						<div className="flex items-center space-x-2">
							{currentActiveId && (
								<Button
									variant="link"
									className="text-text underline"
									size="xs"
									onClick={() => onUndo(item.id)}
								>
									Undo
								</Button>
							)}
							<ButtonGroup
								toggle
								activeId={currentActiveId}
								onActiveChange={(activeId) =>
									onButtonGroupChange(
										item.id,
										activeId as 'approved' | 'declined' | null,
									)
								}
								className="mx-auto w-full"
								aria-label={`Approve or decline ${item.title}`}
							>
								<Button
									id="declined"
									variant={
										currentActiveId === 'declined' ? 'primary' : 'outline'
									}
									className="flex-1"
									// onClick={() => handleButtonClick('declined')}
								>
									<span className="flex items-center">
										<Icon
											name="x-circle"
											size="xs"
											className={`mr-2 ${
												currentActiveId === 'declined'
													? 'fill-white'
													: 'fill-primary-badge'
											}`}
										/>
										Decline
									</span>
								</Button>
								<Button
									id="approved"
									variant={
										currentActiveId === 'approved' ? 'primary' : 'outline'
									}
									className="flex-1"
								>
									<span className="flex items-center">
										<Icon
											name="check-circle"
											size="xs"
											className={`mr-2 ${
												currentActiveId === 'approved'
													? 'fill-white'
													: 'fill-primary-badge'
											}`}
										/>
										Approve
									</span>
								</Button>
							</ButtonGroup>
						</div>
					</div>
				</div>
			</div>
		</Card>
	);
};
