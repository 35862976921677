import React, { forwardRef } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import cn from 'classnames';
import type { IUI } from 'ui/types/core';

export const cardVariants = cva(`overflow-hidden`, {
	variants: {
		elevation: {
			0: 'shadow-sm',
			1: 'shadow-elevation-1',
			2: 'shadow-elevation-2',
			3: 'shadow-elevation-3',
		},
		border: {
			default: 'rounded border border-border',
			none: 'rounded-none',
			xs: 'rounded-xs border border-border',
			sm: 'rounded-sm border border-border',
			md: 'rounded-md border border-border',
			lg: 'rounded-[16px] border border-border',
			xl: 'rounded-[24px] border border-border',
			'2xl': 'rounded-3xl border border-border',
		},
	},
	defaultVariants: {
		border: 'default',
	},
});

export type TBorderCardType =
	| 'default'
	| 'sm'
	| 'none'
	| 'xs'
	| 'sm'
	| 'md'
	| 'lg'
	| 'xl'
	| '2xl';

interface ICardProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof cardVariants>,
		IUI {
	border?: TBorderCardType;
	elevation?: 0 | 1 | 2 | 3;
	'data-testid'?: string;
}

const Card = forwardRef<HTMLDivElement, ICardProps>(
	(
		{
			id,
			className,
			border = 'none',
			children,
			'data-testid': dataTestId,
			elevation = 0,
		},
		ref,
	) => {
		return (
			<div
				ref={ref}
				role="region"
				className={cn(
					'dmui-card',
					cardVariants({ border }),
					cardVariants({ elevation }),
					'w-full mx-auto',
					className,
				)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(id ? { id } : {})}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(dataTestId ? { 'data-testid': dataTestId } : {})}
			>
				{children}
			</div>
		);
	},
);

Card.displayName = 'Card';

export { Card };

export { type ICardProps };
