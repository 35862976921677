import { useAuth } from 'app/features/auth/useAuth';
import React from 'react';
import { Button, Card, Icon } from 'ui';
import { FormatCurrency } from 'utils/features/formatCurrency';

interface IPaymentsMailCheckProps {
	onPayOnline: () => void;
	total: number;
}

export const PaymentsMailCheck = ({
	onPayOnline,
	total,
}: IPaymentsMailCheckProps) => {
	const { org } = useAuth();

	return (
		<Card border="none" className="w-full max-w-md mx-auto bg-background">
			{/* Header */}
			<div className="pb-0 text-center space-y-2">
				<div className="mx-auto h-8 w-12 rounded-full bg-blue-50 flex items-center justify-center">
					<Icon name="map-pin" className="h-6 w-6 fill-blue-600" />
				</div>
				<h2 className="text-2xl font-semibold tracking-tight">Mail Check</h2>
			</div>

			{/* Content */}
			<div className="p-6 space-y-6">
				{/* Payment Details */}
				<div className="space-y-6">
					{org?.checkPaymentInfo.hasCheckInformation ? (
						<>
							<div className="space-y-3">
								<p className="text-sm font-medium text-gray-700">
									Please make check payable to:
								</p>
								<div className="pl-4 py-3 bg-gray-50 rounded-lg">
									<p className="text-gray-600">{org?.checkPaymentInfo.name}</p>
								</div>
							</div>

							<div className="space-y-3">
								<p className="text-sm font-medium text-gray-700">
									Send check to:
								</p>
								<div className="pl-4 py-3 bg-gray-50 rounded-lg">
									<p className="text-gray-600">
										{org?.checkPaymentInfo.address1}
										<br />
										{org?.checkPaymentInfo.address2 && (
											<>
												{org?.checkPaymentInfo.address2}
												<br />
											</>
										)}
										{org?.checkPaymentInfo.address3 && (
											<>
												{org?.checkPaymentInfo.address3}
												<br />
											</>
										)}
										{org?.checkPaymentInfo.city}, {org?.checkPaymentInfo.state}{' '}
										{org?.checkPaymentInfo.zipCode}
										<br />
									</p>
								</div>
							</div>
						</>
					) : (
						<p>Contact your Designer for check payment information.</p>
					)}
				</div>

				{/* Online Payment Option */}
				<div className="flex items-center justify-center space-x-2 py-2">
					<Icon name="credit-card" className="h-4 w-4 text-blue-500" />
					<Button
						onClick={onPayOnline}
						variant="link"
						className="text-blue-500 hover:text-blue-600"
					>
						Want to pay online? Click here
					</Button>
				</div>

				{/* Total Amount */}
				<div className="border-t pt-4">
					<div className="flex items-center justify-between">
						<span className="text-sm font-medium text-gray-600">
							Total Amount
						</span>
						<span className="text-2xl font-semibold">
							<FormatCurrency value={total} />
						</span>
					</div>
				</div>
			</div>
		</Card>
	);
};

PaymentsMailCheck.displayName = 'PaymentsMailCheck';
