import React from 'react';
import { SuspenseWrapper } from 'app/features/SuspenseWrapper/SuspenseWrapper';
import { DemosSuspense } from 'app/pages/Demos/partials/DemosSuspense';
import { Button, Card } from 'ui';
import { DemosFetchData } from 'app/pages/Demos/partials/DemosFetchData';
import { useGetOverviews } from 'api/hooks/useGetOverviews';
import { usePostItemsStatus } from 'api/hooks/usePostItemsStatus';

export const Demos = () => {
	const { data } = useGetOverviews();
	const { mutate } = usePostItemsStatus();

	const cardClasses =
		'mb-6 p-4 bg-background rounded-xl md:rounded-3xl lg:rounded-3xl md:p-5';

	const items = data?.data?.items;

	return (
		<div>
			<Card border="lg" className={cardClasses}>
				<SuspenseWrapper>
					<DemosSuspense />
				</SuspenseWrapper>
			</Card>

			<Card border="lg" className={cardClasses}>
				<DemosFetchData />
			</Card>

			<Card border="lg" className={cardClasses}>
				<h2 className="text-lg pt-4 md:text-xl font-normal mb-4">
					Mock Mutation Demo
				</h2>
				{items
					?.filter((item) => item.id === 'ewf24f98f')
					.map((item) => {
						return (
							<div key={item.id}>
								<Button
									onClick={() =>
										mutate([{ id: 'ewf24f98f', status: 'approved' }])
									}
									disabled={item.status === 'approved'}
								>
									Approve
								</Button>{' '}
								<Button
									onClick={() =>
										mutate([{ id: 'ewf24f98f', status: 'declined' }])
									}
									disabled={item.status === 'declined'}
								>
									Decline
								</Button>
							</div>
						);
					})}
			</Card>
		</div>
	);
};

Demos.displayName = 'Demos';
