import { LayoutContext } from 'app/features/Layout/LayoutContext';
import type { TLayoutOptions } from 'app/features/Layout/LayoutTypes';
import { useContext, useEffect } from 'react';

export const useLayout = ({ header }: TLayoutOptions, callback: unknown) => {
	const layoutContext = useContext(LayoutContext);

	useEffect(() => {
		if (layoutContext && header) {
			layoutContext.setHeader(header);
		}

		// Have not found an elegant way to memoize header which changes in every
		// render due to `header?.actions` being an array of React elements with
		// circular references and whatnot. `callback` here acts much like `dependencies`
		// array in `useEffect` hook and it's required in order to properly update the
		// layout if anything changes in the parent page that calls `useLayout` such as
		// a prop or state.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [callback]);
};
