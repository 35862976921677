import { apiClient } from 'api/apiClient';
import type { IApiResponse } from 'api/apiTypes';

const RESOURCE = '/items';

export interface IItem {
	id: string;
	quantity: number;
	title: string;
	description: string;
	unitPrice: number;
	total: number;
	status: TItemStatuses;
	images: string[];
}

export type TItemStatuses = 'NotSet' | 'Approved' | 'Rejected';

export interface IPostItemsStatusPayload {
	id: string;
	status: TItemStatuses;
}

export type TPostItemsStatusResponse = IApiResponse<Record<string, string>>;

export const postItemsStatus = async (
	token: string,
	payload: IPostItemsStatusPayload[],
) => {
	const { data } = await apiClient.patch(`${RESOURCE}/${token}`, payload);

	return data;
};

export interface IGetItemsParams {
	token: string;
}

export const getItems = async (params: IGetItemsParams) => {
	const { token } = params;
	const { data } = await apiClient.get(`${RESOURCE}/${token}`);

	return data;
};
