import { type TLayoutOptionsHeader } from 'app/features/Layout/LayoutTypes';
import { createContext } from 'react';

interface ILayoutContext {
	header?: TLayoutOptionsHeader;
	setHeader: (header: TLayoutOptionsHeader) => void;
}

export const LayoutContext = createContext<ILayoutContext | undefined>(
	undefined,
);
