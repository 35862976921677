import React, { useMemo } from 'react';
import { Card, Icon } from 'ui';
import { useGetDashboard } from 'api/hooks/useGetDashboard';
import type { IGetDashboard } from 'api/resources/dashboard';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useStackAnimation } from 'hooks/useStackAnimation';
import { type ISummaryItem, SummaryCard } from 'features/Layout/PageActions';
import { ActionTitle } from 'app/features/Layout/PageActions';
import { ActionItemWrapper } from './partials/ActionItemWrapper';
import { useAuth } from 'app/features/auth/useAuth';
import { usePayments } from 'app/features/Payments/usePayments';
import { EPaymentsFormStatus } from 'app/features/Payments/PaymentsModal';
import { useLayout } from 'app/features/Layout/hooks/useLayout';

export const Dashboard = (): React.ReactNode => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token') || '';
	const { data, isLoading } = useGetDashboard({ token });
	const navigate = useNavigate();
	const { org } = useAuth();
	const { paymentsModal, loadPaymentModal, paymentsFormStatus } = usePayments();

	const dashboard = data?.data;

	const { overview, items, proposals, invoices } = dashboard as IGetDashboard;

	const openInvoices = invoices?.filter((invoice) => invoice.isOpen) || [];
	const itemsToReview = items?.filter((item) => item.status === 'NotSet') || [];

	const actionItems = [
		{
			icon: 'document-duplicate',
			subtitle: 'Proposals',
			title: 'Total Deposit Due',
			count: proposals.length,
			amount: proposals.reduce(
				(sum, p) => sum + (p.deposit - p.alreadyDeposited),
				0,
			),
			action: 'Review & Pay',
			navigateTo: () => navigate(`/proposals?token=${token}`),
		},
		{
			icon: 'inbox-arrow-down',
			subtitle: 'Invoices',
			title: 'Total Due',
			count: openInvoices.length,
			amount: openInvoices.reduce((sum, i) => sum + i.amountDue, 0),
			action: 'Review & Pay',
			navigateTo: () => navigate(`/invoices?token=${token}`),
		},
		{
			icon: 'clipboard-document-check',
			subtitle: 'Items',
			title: 'Requested to Review',
			count: itemsToReview.length,
			action: org?.itemsApproval ? 'Approve Now' : 'Review Now',
			navigateTo: () => navigate(`/items?token=${token}`),
		},
		{
			icon: 'wallet',
			subtitle: 'Retainer',
			title: 'Total Requested',
			amount: overview?.retainer || 0,
			action:
				paymentsFormStatus === EPaymentsFormStatus.Loading
					? 'Loading...'
					: 'Pay Now',
			navigateTo: async () => {
				loadPaymentModal([
					{
						objectId: org?.project?.id as number,
						objectType: 'projectRetainer',
					},
				]);
			},
		},
	].filter(
		(item) =>
			item.amount !== 0 && (item.count !== undefined ? item.count !== 0 : true),
	);

	const motionProps = useStackAnimation(0.3, !isLoading);

	const totalRemaining = {
		invoices: invoices
			?.filter((i) => i.isOpen)
			?.reduce((sum, invoice) => sum + invoice.amountDue, 0),
		proposals: proposals?.reduce(
			(sum, proposal) => sum + (proposal.deposit - proposal.alreadyDeposited),
			0,
		),
	};

	const summaryItems = [
		...(org?.showProjectBudget && dashboard?.budget?.total
			? [{ label: 'Budget', amount: dashboard?.budget?.total }]
			: []),
		{
			label: 'Total Paid to Date',
			amount: dashboard?.overview.totalReceived,
		},
		{
			label: 'Total Due',
			amount:
				totalRemaining.invoices +
				totalRemaining.proposals +
				(dashboard?.overview.retainer || 0),
		},
	] as ISummaryItem[];

	useLayout(
		{
			header: {
				title: `Dashboard ${org?.project?.name ? `for ${org?.project?.name}` : ''}`,
				icon: 'rectangle-group',
			},
		},
		useMemo(() => [org?.project?.name], [org?.project?.name]),
	);

	return (
		<>
			<SummaryCard items={summaryItems} isDataReady={!isLoading} />

			{actionItems.length > 0 ? (
				<Card className="h-fit flex flex-col rounded-xl p-4 bg-background gap-2 w-fit md:rounded-3xl lg:rounded-3xl md:p-5 lg:p-5">
					<ActionTitle title="Action Items" />
					{actionItems.map((item, index) => (
						<ActionItemWrapper
							key={index}
							icon={item.icon}
							subtitle={item.subtitle}
							title={item.title}
							count={item.count}
							amount={item.amount}
							action={item.action}
							navigateTo={item.navigateTo}
							motionProps={motionProps}
							index={index}
						/>
					))}
				</Card>
			) : (
				<Card className="h-full flex flex-col rounded-xl p-4 bg-background w-fit md:rounded-3xl lg:rounded-3xl md:p-5 lg:p-5">
					<div className="h-full gap-1 flex flex-col items-center text-center">
						<Icon
							name="check-circle"
							size="lg"
							className="text-primary mb-4 fill-primary-badge"
						/>
						<h3 className="text-lg font-semibold text-text">
							You&apos;re all caught up!
						</h3>
						<p className="text-sm text-text">
							All tasks are up to date. Check back later for new updates or
							tasks to complete.
						</p>
					</div>
				</Card>
			)}
			{paymentsModal}
		</>
	);
};

Dashboard.displayName = 'Dashboard';
