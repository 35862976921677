import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Layout } from 'features/Layout';
import { NotFound } from 'pages/NotFound';
import { routes } from 'features/Routing/Routes';
import { AuthProvider } from 'features/auth/AuthProvider';
import { ProtectedRoutes } from 'features/auth/ProtectedRoute';

export const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<AuthProvider>
				<Layout />
			</AuthProvider>
		),
		errorElement: <NotFound />,
		children: [
			// Public routing
			...Object.keys(routes)
				.filter((routeName) => !routes[routeName].protected)
				.map((routeName) => ({
					path: routes[routeName].path,
					element: routes[routeName].layout || routes[routeName].element,
				})),
			// Protected routing
			{
				element: <ProtectedRoutes />,
				children: [
					...Object.keys(routes)
						.filter((routeName) => routes[routeName].protected)
						.map((routeName) => ({
							path: routes[routeName].path,
							element: routes[routeName].layout || routes[routeName].element,
						})),
				],
			},
		],
	},
]);
